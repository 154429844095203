import {ReloadOutlined} from '@ant-design/icons';
import {Button} from "antd";
import {useContext, useEffect, useState} from "react";
import {AppContextContext, PackageServiceContext} from "../Contexts";
import {useIntlMessage} from "../sal-ui/createIntlMessage";
import {useTableHandler} from "../sal-ui/TableHandler";
import {DocumentTitle} from "./DocumentTitle";
import PackageList from "./PackageList";
import PackagesApproveListSearchForm from "./PackagesApproveListSearchForm";

function PackagesApproveList() {
    const appContext = useContext(AppContextContext);
    const packageService = useContext(PackageServiceContext);
    const applicationConfig = appContext.applicationConfig;
    const intlMessage = useIntlMessage("contact-list");
    const tableHandler = useTableHandler("uploaded desc", {reloadFunction: reload, persistentIdent: "PackagesApproveList"});
    const [data, setData] = useState<any>();

    useEffect(() => {
    }, []);

    return (
        <DocumentTitle title={`${applicationConfig!.title}: ${intlMessage('packages-inbox-outbox.approve-list-title')}`}>
            <div>
                <h1>{intlMessage("packages-inbox-outbox.approve-list-title")}</h1>

                <PackagesApproveListSearchForm onSearch={tableHandler.onSearchSubmit}/>

                <div className={"actions"}>
                    <Button type="text" icon={<ReloadOutlined/>} onClick={reload}/>
                </div>

                <PackageList tableHandler={tableHandler} data={data} reload={reload} tableType={'APPROVE_LIST'}/>
            </div>
        </DocumentTitle>
    );

    function reload() {
        return packageService.getApproveList(tableHandler.queryOptions)
            .then((value: any) => {
                    tableHandler.updateTotal(value.total);
                    setData(value.data);
                }
            )
    }

}

export default PackagesApproveList;
