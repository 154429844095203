import {Form, Input, InputRef, message} from "antd";
import {useForm} from "antd/lib/form/Form";
import {FolderServiceContext} from "../../../Contexts";
import {useIntlMessage} from "../../../sal-ui/createIntlMessage";
import {FormModal, FormModalDelegate, FormModalProps} from "../../../sal-ui/FormModal";
import {ServerConstraintViolationsHolder} from "../../../sal-ui/ServerConstraintViolations";
import {useContext, useEffect, useRef} from "react";
import {Folder} from "../../../domain/Folder";

interface IProps extends FormModalProps {
    folder?: Folder;
}

const serverViolationsHolder = new ServerConstraintViolationsHolder();

function RenameFolderModal(props: IProps) {
    const folderService = useContext(FolderServiceContext);
    const [form] = useForm();
    const intlMessage = useIntlMessage('package-folder-rename');
    const inputRef = useRef<InputRef>(null);
    const formModalRef = useRef<FormModalDelegate>(null);

    useEffect(() => {
        if (props.visible) {
            setTimeout(() => {
                inputRef.current?.focus();
            }, 100)
        }
    }, [props.visible]);

    useEffect(() => {
        form.setFieldValue('name', props.folder?.name);
    }, [props.folder?.name]);

    return (
        <FormModal
            ref={formModalRef}
            form={form}
            visible={props.visible}
            title={intlMessage("title")}
            editMode={false}
            okText={intlMessage("rename")}
            cancelText={intlMessage("common.cancel")}
            onCancel={() => {
                form.resetFields();

                props.onCancel && props.onCancel();
            }}
            addItem={onRename}
            violationsHolder={serverViolationsHolder}>

            <Form form={form} layout={"vertical"} onFinish={() => formModalRef.current?.onSubmit()}>
                <Form.Item name={"name"}
                           initialValue={props.folder?.name}
                           label={intlMessage("package-detail.name")}
                           rules={[
                               {required: true, message: intlMessage("required.name")},
                               {validator: serverViolationsHolder.createServerValidator('UNIQUE', 'idx_folder_name', () => intlMessage("package-folder-create.already-exists"))},
                               {validator: serverViolationsHolder.createServerValidator('NOT_VALID_PATH_NAME', 'name'), message: intlMessage("package-detail.not-valid-folder-name")},
                           ]}>
                    <Input ref={inputRef} maxLength={255}/>
                </Form.Item>
            </Form>

        </FormModal>
    );

    function onRename(values: any): Promise<any> {
        return folderService.rename(props.folder!.id!, values)
            .then(() => {
                message.success(intlMessage("renamed", {name: values.name, originalName: props.folder?.name}))

                form.resetFields();

                props.onOk && props.onOk();
            })
            .catch(reason => {
                    if (reason.response.status === 304) {
                        message.success(intlMessage("renamed", {name: values.name, originalName: props.folder?.name}))

                        form.resetFields();

                        props.onOk && props.onOk();
                    } else if (reason.response.status === 400) {
                        serverViolationsHolder.handleServerError(reason, form);
                    } else if (reason.response.status === 409) {
                        message.error(intlMessage("package-folder-create.already-exists"));
                    } else {
                        message.error(intlMessage("failed", {name: values.name}));
                    }
                }
            );
    }

}

export default RenameFolderModal;