import React from "react";
import {Checkbox, Form, Modal, Radio} from "antd";
import styles from "./ConfigModal.module.css";
import {useIntlMessage} from "../../../sal-ui/createIntlMessage";
import {useForm} from "antd/es/form/Form";

export interface PackageContentConfig {
    visibleStates: FileState[];
    showAs: ShowAs;
}

export enum ShowAs {
    SEPARATE = "SEPARATE",
    UNIFIED = "UNIFIED"
}

export enum FileState {
    ACTIVE = "ACTIVE",
    UNCLEAN = "UNCLEAN",
    DELETED = "DELETED",
}

interface Props {
    open: boolean;
    value: PackageContentConfig;
    onCancel: () => void;
    onChange: (config: PackageContentConfig) => void;
}

export function PackageContentConfigModal(props: Props) {
    const intlMessage = useIntlMessage("package-content-config");
    const [form] = useForm();

    return (
        <>
            <Modal
                open={props.open}
                title={intlMessage("title")}
                okText={intlMessage("common.save")}
                cancelText={intlMessage("common.cancel")}
                destroyOnClose={true}
                onOk={() => {
                    form.validateFields().then((values: any) => {
                        props.onChange(values);
                    });
                }}
                onCancel={() => {
                    props.onCancel();

                    form.resetFields();
                }}
            >
                <div>
                    <Form form={form} layout={"vertical"}>
                        <Form.Item
                            name={"visibleStates"}
                            label={intlMessage("show-files-in-states")}
                            initialValue={props.value.visibleStates}
                        >
                            <Checkbox.Group
                                className={styles.columns}
                                options={[
                                    {value: FileState.ACTIVE, label: intlMessage("state-ACTIVE")},
                                    {value: FileState.UNCLEAN, label: intlMessage("state-UNCLEAN")},
                                    {value: FileState.DELETED, label: intlMessage("state-DELETED")},
                                ]}
                            />
                        </Form.Item>

                        <Form.Item
                            name={"showAs"}
                            label={intlMessage("show-files-as")}
                            initialValue={props.value.showAs}
                        >
                            <Radio.Group
                                optionType="button"
                                buttonStyle="solid"
                                options={[
                                    {value: ShowAs.UNIFIED, label: intlMessage("show-as-unified")},
                                    {value: ShowAs.SEPARATE, label: intlMessage("show-as-separate")}
                                ]}
                            />
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </>
    );
}
