import {EventSourcePolyfill, NativeEventSource} from "event-source-polyfill";
import {SofieConfig} from "../domain/common/SofieConfig";

declare let GlobalSofieConfig: SofieConfig;

class EventSourceNotifier {

    private eventSource?: any;

    constructor(private options: { filters?: any[], onMessage: (message: MessageEvent) => void }) {
        //
    }

    public open() {
        const evtSource = (NativeEventSource) ? NativeEventSource : EventSourcePolyfill;

        let url = `${GlobalSofieConfig.webApiUrl}/user/sse`;

        if (this.options.filters) {
            const params = new URLSearchParams();

            params.append("filters", JSON.stringify(this.options.filters));

            url += `?${params.toString()}`;
        }

        this.eventSource = new evtSource(url);

        this.eventSource.onerror = (evt: any) => {
            if ((evt.target as EventSource).readyState === 2) {
                this.fireOnMessage(evt);
            }
        };

        this.eventSource.onmessage = (message: MessageEvent) => {
            const eventNames = this.options?.filters?.map(value => value.name);

            if (this.options?.filters) {
                if (eventNames?.includes(message.type)) {
                    this.fireOnMessage(message);
                }
            } else {
                this.fireOnMessage(message);
            }
        };

        if (this.options?.filters !== undefined) {
            const eventNames = this.options?.filters?.map(value => value.name);

            for (const eventName of eventNames) {
                this.eventSource.addEventListener(eventName, this.options.onMessage);
            }
        }
    }

    public close() {
        if (this.eventSource) {
            this.eventSource.close();
        }
    }

    private fireOnMessage(message: MessageEvent) {
        this.options.onMessage(message);
    }
}

export default EventSourceNotifier;
