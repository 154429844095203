import {Form, Input, InputRef, message} from "antd";
import {useForm} from "antd/lib/form/Form";
import {packageFileService} from "../../../Contexts";
import {useIntlMessage} from "../../../sal-ui/createIntlMessage";
import {FormModal, FormModalDelegate, FormModalProps} from "../../../sal-ui/FormModal";
import {ServerConstraintViolationsHolder} from "../../../sal-ui/ServerConstraintViolations";
import {Package} from "../../../domain/Package";
import {useEffect, useRef} from "react";

interface IProps extends FormModalProps {
    package?: Package;
    file?: any
}

const serverViolationsHolder = new ServerConstraintViolationsHolder();


function FileModal(props: IProps) {

    const [form] = useForm();
    const intlMessage = useIntlMessage('file-edit');
    const inputRef = useRef<InputRef>(null);
    const formModalRef = useRef<FormModalDelegate>(null);

    const {visible, title} = props;

    useEffect(() => {
        if (props.visible) {
            setTimeout(() => {
                inputRef.current?.focus();
            }, 100)
        }
    }, [props.visible]);

    return (
        <FormModal
            ref={formModalRef}
            form={form}
            visible={visible}
            title={title}
            editMode={props.editMode}
            okText={intlMessage("common.save")}
            cancelText={intlMessage("common.cancel")}
            onCancel={props.onCancel}
            updateItem={onRenameFile}
            violationsHolder={serverViolationsHolder}>

            <Form form={form} layout={"vertical"} onFinish={() => formModalRef.current?.onSubmit()}>

                <Form.Item name={"filename"}
                           label={intlMessage("common.filename")}
                           initialValue={props.file.name}
                           rules={[
                               {required: true, message: intlMessage("required.filename")},
                               {validator: serverViolationsHolder.createServerValidator('NOT_VALID_PATH_NAME', 'name'), message: intlMessage("package-detail.not-valid-file-name")},
                           ]}>
                    <Input ref={inputRef} maxLength={254}/>
                </Form.Item>

            </Form>
        </FormModal>
    );

    function onRenameFile(values: any): Promise<any> {
        return packageFileService.renameFile(props.package!.id!, props.file, values.filename).then(value => {
            message.success(intlMessage("packages-detail.file-renamed", {file: props.file.name}))

            props.onOk!();
        }, reason => {
            if (reason.response.status === 304) {
                message.success(intlMessage("packages-detail.file-renamed", {file: props.file.name}))

                props.onOk!();
            } else if (reason.response.status === 400) {
                serverViolationsHolder.handleServerError(reason, form);
            } else if (reason.response.status === 409) {
                message.error(intlMessage("package-detail.file-already-exists"));
            } else {
                message.error(intlMessage("packages-detail.file-rename-failed", {file: props.file.name}));
            }
        })
    }

}

export default FileModal;