import Axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {Folder} from "../domain/Folder";
import {FolderIndex} from "../domain/FolderIndex";
import {NameConflictResolution} from "../domain/NameConflictResolution";

export class FolderService {

    private BASE_URL = "/user/";

    public add(folderId: string, folder: Folder): Promise<AxiosResponse> {
        return Axios.post(`${this.BASE_URL}folders/${folderId}`, folder);
    }

    public rename(folderId: string, folder: Folder): Promise<AxiosResponse> {
        return Axios.put(`${this.BASE_URL}folders/${folderId}/name`, folder);
    }

    public delete(folderId: string): Promise<AxiosResponse> {
        return Axios.delete(`${this.BASE_URL}folders/${folderId}`);
    }

    public get(folderId: string, limitAccessToken?: string): Promise<Folder> {
        const config: AxiosRequestConfig = {};

        if (limitAccessToken) {
            config.headers = {'X-Sofie-Limit-Access-Token': limitAccessToken};
        }

        return Axios.get(`${this.BASE_URL}folders/${folderId}`, config).then(value => {
            return value.data;
        })
    }

    public getIndex(folderId: string, passwordToken?: string, limitAccessToken?: string): Promise<FolderIndex> {
        const tokenQueryStr = (passwordToken) ? `?token=${passwordToken}` : '';

        const config: AxiosRequestConfig = {};

        if (limitAccessToken) {
            config.headers = {'X-Sofie-Limit-Access-Token': limitAccessToken};
        }

        return Axios.get(`${this.BASE_URL}folders/${folderId}/index${tokenQueryStr}`, config).then(value => {
            return FolderIndex.fromPOJO(value.data);
        })
    }

    public isMoveToItself(targetFolderId: string, folderIds: string[], fileIds: string[]): Promise<boolean> {
        return Axios.post(`${this.BASE_URL}folders/${targetFolderId}/is-move-to-itself`, {folderIds, fileIds}).then(value => {
            return value.data;
        })
    }

    public moveItems(targetFolderId: string, folderIds: string[], fileIds: string[], conflictResolution: NameConflictResolution): Promise<void> {
        return Axios.post(`${this.BASE_URL}folders/${targetFolderId}/move`, {folderIds, fileIds, conflictResolution}).then(value => {
            return;
        })
    }

}

