import Axios, {AxiosRequestConfig} from "axios";
import {Package, PackageFile} from "../domain/Package";
import {Recipient} from "../domain/Recipient";
import PagedResult from "./common/PagedResult";
import QueryOptions from "./common/QueryOptions";
import {File} from "../domain/File";
import {Folder} from "../domain/Folder";

export enum DownloadObjectType {
    FILE = 'FILE',
    ACHIVE = 'ARCHIVE'
}

export class PackageService {

    private BASE_URL = "/user/";

    public add(aPackage: Package, captchaToken: string, uploadToken?: string): Promise<PackageFile[]> {

        const config: AxiosRequestConfig = {};

        if (uploadToken) {
            config.headers = {'X-Sofie-Upload-Token': uploadToken};
        }

        return Axios.post(`${this.BASE_URL}upload-package/${captchaToken}`, aPackage, config).then(value => {
            return value.data.data;
        });
    }

    public addFiles(packageId: string, folderId: string, files: any[], downloadPassword?: string): Promise<PackageFile[]> {
        return Axios.post(`${this.BASE_URL}packages/${packageId}/add-files/${folderId}`, {
            files,
            downloadPassword
        }).then(value => {
            return value.data.data;
        });
    }

    public createPackageRequest(aPackage: Package): Promise<Package> {
        return Axios.post(`${this.BASE_URL}package-requests`, aPackage).then(value => {
            return value.data;
        });
    }

    public packageForward(aPackage: Package): Promise<Package> {
        return Axios.post(`${this.BASE_URL}package-forward/${aPackage.id}`, aPackage).then(value => {
            return value.data;
        });
    }


    public update(id?: string, values?: Package): Promise<any> {
        return Axios.put(`${this.BASE_URL}packages/${id}`, values);
    }

    public get(id: string, password?: string, token?: string, limitAccessToken?: string): Promise<Package> {
        let queryString = "";

        const config: AxiosRequestConfig = {};


        if (password) {
            config.auth = {username: "", password}
        }

        if (token) {
            queryString = `?token=${token}`;
        }

        if (limitAccessToken) {
            config.headers = {'X-Sofie-Limit-Access-Token': limitAccessToken};
        }

        return Axios.get(`${this.BASE_URL}packages/${id}${queryString}`, config).then(value => {
            return value.data;
        })
    }

    public patch(packageId: string, uploadToken?: string): Promise<any> {
        const config: AxiosRequestConfig = {};

        if (uploadToken) {
            config.headers = {'X-Sofie-Upload-Token': uploadToken};
        }

        return Axios.patch(`${this.BASE_URL}upload-package/${packageId}`, undefined, config);
    }

    public filesUpdated(packageId: string, fileIds: (string | null | undefined)[]): Promise<any> {
        return Axios.patch(`${this.BASE_URL}packages/${packageId}/updated`, fileIds);
    }

    public cancel(packageId: string): Promise<any> {
        return Axios.patch(`${this.BASE_URL}upload-package/${packageId}/cancel`);
    }

    public delete(entity: Package): Promise<any> {
        return Axios.delete(`${this.BASE_URL}packages/${entity.id}`);
    }

    public resendNotifications(entity: Package): Promise<any> {
        return Axios.post(`${this.BASE_URL}packages/${entity.id}/resend-notifications`);
    }


    public removeRecipient(entity: Package): Promise<any> {
        return Axios.delete(`${this.BASE_URL}packages/${entity.id}/internal-recipient`);
    }

    public addRecipients(id?: string, recipients?: Recipient[]): Promise<any> {
        return Axios.post(`${this.BASE_URL}packages/${id}/recipients`, recipients);
    }

    public getInbox(queryOptions: QueryOptions): Promise<PagedResult<Package[]>> {
        return Axios.get(`${this.BASE_URL}packages-inbox/?${queryOptions.toQueryParams()}`).then(value => {
            return new PagedResult(value.data.total, value.data.data);
        })
    }

    public getOutbox(queryOptions: QueryOptions): Promise<PagedResult<Package[]>> {
        return Axios.get(`${this.BASE_URL}packages-outbox/?${queryOptions.toQueryParams()}`).then(value => {
            return new PagedResult(value.data.total, value.data.data);
        })
    }

    public getBriefcase(queryOptions: QueryOptions): Promise<PagedResult<Package[]>> {
        return Axios.get(`${this.BASE_URL}briefcase/?${queryOptions.toQueryParams()}`).then(value => {
            return new PagedResult(value.data.total, value.data.data);
        })
    }

    public getCooperative(queryOptions: QueryOptions): Promise<PagedResult<Package[]>> {
        return Axios.get(`${this.BASE_URL}cooperative/?${queryOptions.toQueryParams()}`).then(value => {
            return new PagedResult(value.data.total, value.data.data);
        })
    }

    public getApproveList(queryOptions: QueryOptions): Promise<PagedResult<Package[]>> {
        return Axios.get(`${this.BASE_URL}packages-approve-list/?${queryOptions.toQueryParams()}`).then(value => {
            return new PagedResult(value.data.total, value.data.data);
        })
    }

    public createArchive(aPackage: Package): Promise<string> {
        return Axios.post(`${this.BASE_URL}file-archive/${aPackage.id}`).then(value => {
            return value.data.id;
        })
    }

    public deleteInternalRecipient(aPackage: Package, recipient: Recipient): Promise<any> {
        return Axios.delete(`${this.BASE_URL}packages/${aPackage.id}/internal-recipient/${recipient.email}`);
    }

    public deleteExternalRecipient(aPackage: Package, recipient: Recipient): Promise<any> {
        return Axios.delete(`${this.BASE_URL}packages/${aPackage.id}/external-recipient/${recipient.email}`);
    }

    public getTokenForDownloadFile(objectId: string, objectType: DownloadObjectType, password: string): Promise<string> {
        return Axios.post(`${this.BASE_URL}resource-auth/${objectId}?type=${objectType}`, {password}).then(value => {
            return value.data;
        });
    }

    public checkFileIntegrity(packageId: string, file: File, token: any): Promise<string> {
        const tokenQueryStr = (token) ? `?token=${token}` : '';

        return Axios.post(`${this.BASE_URL}packages/${packageId}/check-file-integrity/${file.id}${tokenQueryStr}`);
    }

    public checkPackageIntegrity(aPackage: Package, token: any): Promise<string> {
        const tokenQueryStr = (token) ? `?token=${token}` : '';

        return Axios.post(`${this.BASE_URL}packages/${aPackage.id}/check-integrity${tokenQueryStr}`);
    }

    public approve(record: Package): Promise<string> {
        return Axios.post(`${this.BASE_URL}packages/${record.id}/approve`);
    }

    public disapprove(record: Package): Promise<string> {
        return Axios.post(`${this.BASE_URL}packages/${record.id}/disapprove`);
    }

    public setViewed(aPackage: Package) {
        return Axios.post(`${this.BASE_URL}packages/${aPackage.id}/viewed`);
    }

    public setFlagged(aPackage: Package) {
        return Axios.post(`${this.BASE_URL}packages/${aPackage.id}/flag`);
    }

    public unsetFlagged(aPackage: Package) {
        return Axios.delete(`${this.BASE_URL}packages/${aPackage.id}/flag`);
    }

    public setApprovers(packageId: string, approvers: string[]) {
        return Axios.post(`${this.BASE_URL}packages/${packageId}/approvers`, {approvers});
    }

    /**
     * Set package as persistent (workflow independent)
     *
     * @param entity
     */
    public setPersistentPackage(entity: Package): Promise<any> {
        return Axios.patch(`${this.BASE_URL}packages/${entity.id}/set-persistent`);
    }
    public massSetPersistentPackage(ids: string[]): Promise<any> {
        return Axios.patch(`${this.BASE_URL}packages/set-persistent`, {packageIds: ids});
    }

    public massDelete(ids: string[]): Promise<any> {
        return Axios.post(`${this.BASE_URL}packages/delete`, {packageIds: ids});
    }

    public massResendNotifications(ids: string[]): Promise<any> {
        return Axios.post(`${this.BASE_URL}packages/resend-notifications`, {packageIds: ids});
    }

    public massRemoveRecipient(ids: string[]): Promise<any> {
        return Axios.post(`${this.BASE_URL}packages/remove-internal-recipient`, {packageIds: ids});
    }


    /**
     * Set package as regular (workflow dependent)
     *
     * @param entity
     */
    public unsetPersistentPackage(entity: Package): Promise<any> {
        return Axios.patch(`${this.BASE_URL}packages/${entity.id}/unset-persistent`);
    }
    public massUnsetPersistentPackage(ids: string[]): Promise<any> {
        return Axios.patch(`${this.BASE_URL}packages/unset-persistent`, {packageIds: ids});
    }

    public getDeletedFiles(packageId: string) {
        return Axios.get(`${this.BASE_URL}packages/${packageId}/deleted-files`).then(response => {
            return {
                folders: response.data.folders.map((value: any) => Folder.fromPOJO(value)),
                files: response.data.files.map((value: any) => File.fromPOJO(value))
            };
        })
    }

    public getContentDeletedFiles(packageId: string) {
        return Axios.get(`${this.BASE_URL}packages/${packageId}/content-deleted-files`).then(response => {
            return {
                folders: response.data.folders.map((value: any) => Folder.fromPOJO(value)),
                files: response.data.files.map((value: any) => File.fromPOJO(value))
            };
        })
    }

    public getQuarantinedFiles(packageId: string) {
        return Axios.get(`${this.BASE_URL}packages/${packageId}/quarantined-files`).then(response => {
            return response.data.map((value: any) => File.fromPOJO(value));
        })
    }

}
