import Icon, {CheckCircleOutlined, ContactsFilled, LoadingOutlined, LockOutlined, PauseCircleFilled, QuestionCircleOutlined, SafetyCertificateFilled, ShareAltOutlined, SoundFilled, UnlockFilled, UnlockOutlined} from '@ant-design/icons';
import {Divider, Layout, Menu} from 'antd';
import SubMenu from "antd/lib/menu/SubMenu";
import Cookies from "js-cookie";
import {observer} from "mobx-react";
import {useContext, useEffect, useState} from 'react';
import {BsBriefcaseFill} from "react-icons/bs";
import {useHistory, useLocation} from "react-router";
import {matchRoutes, renderRoutes} from "react-router-config";
import {Link} from "react-router-dom";
import {AppContextContext, AuthServiceContext, LocalStorageServiceContext, UserServiceContext} from "../Contexts";
import {UserPermission} from "../domain/User";
import {useIntlMessage} from "../sal-ui/createIntlMessage";
import LocalStorageNamespace from "../service/LocalStorageNamespace";
import {anonymousRoutesMap} from "./AnonymousRoutes";
import ChangePasswordModal from "./ChangePasswordModal";
import {IconDownload, IconExit, IconLock, IconSendPackage, IconUpload, IconUser} from "./common/CustomIcons";
import ProfileModal from "./ProfileModal";
import {routes, routesMap} from "./Routes";
import SelectLang from "./SelectLang";
import SetApproverModal from "./SetApproverModal";

const {Header, Content, Sider} = Layout;

const JWT_CHECK_INTERVAL_IN_MS = 10 * 1000;

function HomePage() {
    const appContext = useContext(AppContextContext);
    const applicationConfig = appContext.applicationConfig!;
    const authService = useContext(AuthServiceContext);
    const userService = useContext(UserServiceContext);
    const intlMessage = useIntlMessage("home-page");
    const [jwtChecked, setJwtChecked] = useState(false);
    const [openKeys, setOpenKeys] = useState<string[]>([]);
    const [changePasswordModalVisible, setChangePasswordModalVisible] = useState(false);
    const [profileModalVisible, setProfileModalVisible] = useState(false);
    const [approverModalVisible, setApproverModalVisible] = useState(false);
    const location = useLocation();
    const navigate = useHistory();
    const localStorageService = useContext(LocalStorageServiceContext);

    const matchedRoute = firstMatchedRoute();

    const selectedKeys = (matchedRoute) ? [matchedRoute.menuKey!] : [];

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {

        // pokud mame v cookies info, ze mame nacist zasilku, smazeme pripadne presmerovani
        if (Cookies.get("Load-package")) {
            localStorageService.removeItem(LocalStorageNamespace.LocationLastPath);
        }

        // obnoveni posledniho mista, kde byl uzivatel pred expiraci jwt tokenu
        const lastPath = localStorageService.getItem(LocalStorageNamespace.LocationLastPath);
        if (lastPath) {
            const matchedRoutes = matchRoutes(routes, lastPath);
            if (matchedRoutes && matchedRoutes.length === 1 && matchedRoutes[0].route.path !== "*") {
                localStorageService.removeItem(LocalStorageNamespace.LocationLastPath);
                navigate.push(lastPath);
            } else {
                localStorageService.removeItem(LocalStorageNamespace.LocationLastPath);
            }
        }

        if (matchedRoute) {
            if (matchedRoute === routesMap.Dashboard) {
                setOpenKeys([routesMap.PackagesInbox.menuOpenKey]);
            } else {
                if (matchedRoute.menuOpenKey) {
                    setOpenKeys((matchedRoute) ? [matchedRoute.menuOpenKey] : []);
                } else {
                    setOpenKeys([]);
                }
            }
        }

        checkJwtTokenExpireAt();

        const timerHandle = setInterval(checkJwtTokenExpireAt, JWT_CHECK_INTERVAL_IN_MS);

        if (appContext.user && appContext.user.mfaEnforceRequirement(applicationConfig)) {
            setTimeout(navigate.push, 200, routesMap.MultiFactorKeyList.path);
        }

        return function cleaup() {
            clearInterval(timerHandle);
        }
    }, []);

    if (!jwtChecked) {
        return <LoadingOutlined className={"loading-page"}/>;
    } else {
        return (
            <Layout style={{minHeight: '100vh'}} className={`${applicationConfig.closedNetwork && applicationConfig.closedNetworkIsSet ? "closed-network" : "open-network"}`}>
                <Header style={{minWidth: "1030px"}}>
                    <div className={"logo"}>
                        <Link to={routesMap.PackagesInbox.path}>
                            <img src="/api/public/static-resource/logo"/>
                        </Link>
                    </div>

                    <h1 className={"app-banner"}>
                        {applicationConfig.banner}
                    </h1>

                    <div style={{textAlign: "right", display: "inline-block", float: "right", paddingLeft: "16px", height: "72px", overflow: "hidden"}}>
                        <span className={"help-link"}>
                            <a href={`https://docs.sofie.cloud/${appContext.language === 'hu' || appContext.language === 'sk' ? 'en' : appContext.language}/user/`} target={"_blank"} rel="noreferrer">
                                <QuestionCircleOutlined title={intlMessage('menu.help')}/>
                            </a>
                        </span>

                        <Menu mode="horizontal" className={"user-menu"} selectable={false} key={'profile'} disabledOverflow={true}>
                            <Menu.SubMenu key="setting:0" title={<span><Icon component={IconUser}/><b>{appContext.user?.username}</b></span>}>
                                <Menu.Item key="setting:2">
                                    <a onClick={onEditProfile}>
                                        <Icon component={IconUser}/>{intlMessage('common.edit-profile')}
                                    </a>
                                </Menu.Item>

                                <Menu.Item key="setting:3">
                                    <a onClick={onMultiFactorKeys}>
                                        <SafetyCertificateFilled/>{intlMessage('menu.multi-factor-keys')}
                                    </a>
                                </Menu.Item>

                                {
                                    appContext.user?.local &&

                                    <Menu.Item key="setting:1">
                                        <a onClick={onChangePassword}>
                                            <Icon component={IconLock}/>{intlMessage('common.change-password')}
                                        </a>
                                    </Menu.Item>
                                }

                                {appContext.user?.userConfig?.userCanSetApprover &&

                                    <Menu.Item key="setting:4">
                                        <a onClick={(e: any) => setApproverModalVisible(true)}>
                                            <CheckCircleOutlined/>{intlMessage('package-modal.set-approver.title')}
                                        </a>
                                    </Menu.Item>
                                }

                            </Menu.SubMenu>
                        </Menu>

                        <Divider type="vertical"/>
                        <div className={"logout"}>
                            <a onClick={logout}><Icon component={IconExit} style={{marginRight: "8px"}}/><b>{intlMessage('menu.logout')}</b></a>
                        </div>
                    </div>

                    <SelectLang/>

                    {applicationConfig.closedNetworkIsSet &&
                        <div className={"network-type"}>
                            {applicationConfig.closedNetwork ? <LockOutlined title={intlMessage("common.CLOSED_NETWORK")}/> : <UnlockOutlined title={intlMessage("common.OPEN_NETWORK")}/>}
                        </div>
                    }

                </Header>
                <Layout>
                    <Sider>
                        <Menu className={"sofie-menu"} theme="dark" openKeys={openKeys} selectedKeys={selectedKeys}
                              onOpenChange={keys => setOpenKeys(keys as string[])} mode="inline" onClick={onMenuClick}
                              disabled={appContext.user?.mfaEnforceRequirement(applicationConfig)}>
                            {appContext.user?.hasPermission(UserPermission.SEND_PACKAGE) && applicationConfig.allowUserUpload &&
                                <Menu.Item key={routesMap.UploadPackage.menuKey}>
                                    <Link to={routesMap.UploadPackage.path}>
                                        <Icon component={IconSendPackage} style={{fontSize: 20, width: 22, verticalAlign: "text-bottom"}}/>
                                        <span>{intlMessage('menu.package-upload')}</span>
                                    </Link>
                                </Menu.Item>
                            }

                            {appContext.user?.hasPermission(UserPermission.RECEIVE_PACKAGE) &&
                                <Menu.Item key={routesMap.PackageRequest.menuKey}>
                                    <Link to={routesMap.PackageRequest.path}>
                                        <SoundFilled/>
                                        <span>{intlMessage('menu.package-request')}</span>
                                    </Link>
                                </Menu.Item>
                            }

                            <Menu.Item key={routesMap.PackagesInbox.menuKey}>
                                <Link to={routesMap.PackagesInbox.path}>
                                    <Icon component={IconDownload}/>
                                    <span>{intlMessage('menu.packages-inbox')}</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key={routesMap.PackagesOutbox.menuKey}>
                                <Link to={routesMap.PackagesOutbox.path}>
                                    <Icon component={IconUpload}/>
                                    <span>{intlMessage('menu.packages-outbox')}</span>
                                </Link>
                            </Menu.Item>
                            {appContext.user?.hasPermission(UserPermission.APPROVE_SEND_PACKAGE) &&
                                <Menu.Item key={routesMap.PackagesApproveList.menuKey}>
                                    <Link to={routesMap.PackagesApproveList.path}>
                                        <PauseCircleFilled/>
                                        <span>{intlMessage('menu.packages-approve-list')}</span>
                                    </Link>
                                </Menu.Item>
                            }

                            {(applicationConfig.briefcase === "YES" || (applicationConfig.briefcase === "PERMISSION_BASED" && appContext.user?.hasPermission(UserPermission.SEND_PACKAGE__BRIEFCASE))) &&
                                <Menu.Item key={routesMap.Briefcase.menuKey}>
                                    <Link to={routesMap.Briefcase.path}>
                                    <span role="img" className="anticon">
                                        <BsBriefcaseFill/>
                                    </span>
                                        <span>{intlMessage('menu.briefcase')}</span>
                                    </Link>
                                </Menu.Item>
                            }

                            {(applicationConfig.cooperativePackages === "YES" || applicationConfig.cooperativePackages === "PERMISSION_BASED") &&
                                <Menu.Item key={routesMap.Cooperative.menuKey}>
                                    <Link to={routesMap.Cooperative.path}>
                                        <ShareAltOutlined/>
                                        <span>{intlMessage('menu.cooperative')}</span>
                                    </Link>
                                </Menu.Item>
                            }

                            <SubMenu key="contacts" title={<span><ContactsFilled/><span>{intlMessage('menu.contact-list')}</span></span>}>
                                <Menu.Item key={routesMap.ContactList.menuKey}>
                                    <Link to={routesMap.ContactList.path}>
                                        <span>{intlMessage('menu.contact-list.contacts')}</span>
                                    </Link>
                                </Menu.Item>

                                <Menu.Item key={routesMap.ContactGroupList.menuKey}>
                                    <Link to={routesMap.ContactGroupList.path}>
                                        <span>{intlMessage('menu.contact-list.groups')}</span>
                                    </Link>
                                </Menu.Item>
                            </SubMenu>

                        </Menu>
                        <div id={"sonpo-logo"}>
                            <a href={"https://www.sonpo.cz"} target={"_blank"} rel="noreferrer">
                                <img src="/api/public/static-resource/sonpo" style={{width: "80px"}}/><br/>
                                SONPO, a.s. © 2024
                            </a>
                        </div>
                    </Sider>

                    <Content style={{margin: '16px'}}>
                        <div style={{padding: 24, minHeight: 300}}>
                            {renderRoutes(routes)}
                        </div>

                        <ChangePasswordModal visible={changePasswordModalVisible}
                                             title={intlMessage("user-change-password.title", {username: appContext.user?.username})}
                                             user={appContext.user}
                                             editMode={true}
                                             onOk={() => setChangePasswordModalVisible(false)}
                                             onCancel={() => setChangePasswordModalVisible(false)}/>

                        <ProfileModal visible={profileModalVisible}
                                      title={intlMessage('user-edit-profile.title', {username: appContext.user?.username})}
                                      editMode={true}
                                      user={appContext.user}
                                      onOk={() => setProfileModalVisible(false)}
                                      onCancel={() => setProfileModalVisible(false)}/>

                        <SetApproverModal visible={approverModalVisible}
                                          title={intlMessage('package-modal.set-approver.title')}
                                          type={"USER"}
                                          onOk={onSetApprover}
                                          onCancel={() => setApproverModalVisible(false)}/>
                    </Content>
                </Layout>
            </Layout>
        );
    }

    function onMenuClick(clickParam: any) {
        window.scrollTo(0, 0);

        if (clickParam.key === "logout") {
            logout();
        }
    }

    function firstMatchedRoute(): any | null {
        const matchedRoutes = matchRoutes(routes, location.pathname);

        if (matchedRoutes && matchedRoutes.length === 1) {
            return matchedRoutes[0].route;
        } else {
            return null;
        }
    }

    function checkJwtTokenExpireAt() {
        const jwtTokenExpireAt = authService.loadJwtTokenExpirationFromLocalStorage();

        if (jwtTokenExpireAt == null || jwtTokenExpireAt.getTime() < new Date().getTime()) {
            // pokud je token expirovany, ulozime si posledni cestu a po prihlaseni ji muzeme zase obnovit
            // window.location je nutne ... jinak to nebere aktualni url
            localStorageService.setItem(LocalStorageNamespace.LocationLastPath, window.location.pathname);

            const path = anonymousRoutesMap.Welcome.path;
            setTimeout(navigate.push, 50, path);

            authService.localLogout();


        } else {
            setJwtChecked(true);
        }
    }

    function logout() {
        authService.logout().then(() => {
            navigate.push(anonymousRoutesMap.Welcome.path);
        });
    }

    function onChangePassword() {
        setChangePasswordModalVisible(true);
    }

    function onEditProfile() {
        userService.getLoggedUser().then(user => {
            appContext.user = user;

            setProfileModalVisible(true);
        });
    }

    function onSetApprover() {
        userService.getLoggedUser().then(user => {
            appContext.user = user;

            setApproverModalVisible(false);
        });
    }

    function onMultiFactorKeys(e?: React.MouseEvent) {
        navigate.push(routesMap.MultiFactorKeyList.path);
    }


}

export default observer(HomePage);
