import React, {ReactNode, useState} from "react";
import {useIntlMessage} from "../../sal-ui/createIntlMessage";
import {Checkbox, Popconfirm} from "antd";

function CheckboxPopConfirm(
    props: {
        title: string,
        onConfirm: (checkboxValue: boolean | undefined) => void,
        children: ReactNode,
        disabled?: boolean,
        checkboxEnabled?: boolean,
        checkboxLabel?: string,
        checkboxDefaultValue: boolean,
        icon?: ReactNode
    }) {

    const intlMessage = useIntlMessage("settings-encryption");
    const [checkboxValue, setCheckboxValue] = useState(props.checkboxDefaultValue);

    return (
        <>
            {
                props.disabled ? props.children :
                    <Popconfirm
                        title={
                            <>
                                <p>{props.title}</p>
                                {props.checkboxEnabled &&
                                    <Checkbox checked={checkboxValue} onChange={() => setCheckboxValue(!checkboxValue)}>{props.checkboxLabel}</Checkbox>
                                }
                            </>
                        }
                        okText={intlMessage("common.yes")}
                        cancelText={intlMessage("common.no")}
                        onConfirm={() => {
                            props.onConfirm(props.checkboxEnabled ? checkboxValue : undefined)
                        }}
                        icon={props.icon}>

                        {props.children}

                    </Popconfirm>
            }
        </>
    );
}

export default CheckboxPopConfirm;
