import {Button, Col, Form, Row} from "antd";
import {useState} from "react";
import {useForm} from "antd/es/form/Form";
import {useIntlMessage} from "../sal-ui/createIntlMessage";
import {jwtDecode} from 'jwt-decode'

interface IProps {
    onSubmit: () => Promise<any>;
    accessToken?: string;
}

function PackageAccessConfirmRequired(props: IProps) {
    const [inProgress, setInProgress] = useState(false);
    const intlMessage = useIntlMessage("package-access-confirm-required");
    const [form] = useForm();

    const token: any = jwtDecode(props.accessToken!);

    const formItemLayout = {
        labelCol: {span: 24},
        wrapperCol: {span: 24},
    };

    let titleId: string;
    let textId: string;
    let labelId: string;
    let requiredId: string;


        // PackagePasswordType.Download + PackagePasswordType.Forward
        titleId = 'package-detail.access-required.title';
        textId = 'package-detail.access-required.text';
        labelId = 'package-detail.password-required.download.label';
        requiredId = 'package-detail.password-required.download.required';


    return (
        <Row justify={"space-around"} align={"middle"} className={"login-form"}>
            <Col id={"access-confirm"}>

                <Row>
                    <h2>{intlMessage(titleId)}</h2>
                </Row>

                <div className={"package-access-info"} style={{textAlign: "justify"}}>
                    {intlMessage(textId, {count: token.limitAccessCount - token.accessCount})}
                </div>

                <Form form={form} onFinish={onFinish} layout={"vertical"} className={"package-password-required"}>
                    <Button type="primary" style={{width: "100%", height: "50px"}} size={"large"} loading={inProgress} htmlType="submit">
                        {intlMessage("common.access-package")}
                    </Button>
                </Form>
            </Col>
        </Row>
    );

    function onFinish(values: any) {
        setInProgress(true);

        props.onSubmit().finally(() => setInProgress(false));
    }
}

export default PackageAccessConfirmRequired;
