import {CloseOutlined} from "@ant-design/icons";
import {Popconfirm} from "antd";
import * as React from 'react';
import styles from "./SmartTag.module.css";

interface IProps {
    value: any,
    onClose?: any,
    closable: boolean,
    confirm?: boolean,
    title?: string
    okText?: string,
    cancelText?: string
    style?: any
}

function SmartTag(props: IProps) {

    return (
        <React.Fragment>
            <div className="ant-tag" style={props.style}>
                <span>{props.value}</span>
                {props.closable && props.confirm ?
                    <span>
                        <Popconfirm title={props.title} okText={props.okText} cancelText={props.cancelText} onConfirm={props.onClose}>
                            <CloseOutlined/>
                        </Popconfirm>
                        </span>
                    :
                    props.closable && <span>
                            <CloseOutlined onClick={props.onClose}/>
                        </span>
                }
            </div>

        </React.Fragment>
    );

}

export default SmartTag;