import {PlusOutlined, ReloadOutlined} from '@ant-design/icons';
import {Button} from "antd";
import {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router";
import {Link} from "react-router-dom";
import {AppContextContext, PackageServiceContext} from "../Contexts";
import {UserPermission} from "../domain/User";
import {useIntlMessage} from "../sal-ui/createIntlMessage";
import {useTableHandler} from "../sal-ui/TableHandler";
import {DocumentTitle} from "./DocumentTitle";
import PackageList from "./PackageList";
import PackagesOutboxSearchForm from "./PackagesOutboxSearchForm";
import {routesMap} from "./Routes";

function PackagesOutbox() {
    const appContext = useContext(AppContextContext);
    const packageService = useContext(PackageServiceContext);
    const applicationConfig = appContext.applicationConfig;
    const intlMessage = useIntlMessage("contact-list");
    const tableHandler = useTableHandler("uploaded desc", {reloadFunction: reload, persistentIdent: "PackagesOutbox"});
    const [data, setData] = useState<any>();
    const navigate = useHistory();

    useEffect(() => {
    }, []);

    const actions = appContext.user?.hasPermission(UserPermission.SEND_PACKAGE) && applicationConfig!.allowUserUpload ?
            <Button type={"primary"} icon={<PlusOutlined/>} onClick={() => navigate.push(routesMap.UploadPackage.path)}>{intlMessage('package.send_package')}</Button> : "";

    return (
        <DocumentTitle title={`${applicationConfig!.title}: ${intlMessage('packages-inbox-outbox.outbox-title')}`}>
            <div>
                <h1>{intlMessage("packages-inbox-outbox.outbox-title")}</h1>

                <PackagesOutboxSearchForm onSearch={tableHandler.onSearchSubmit}/>

                <PackageList tableHandler={tableHandler} data={data} reload={reload} tableType={'OUTBOX'} actions={actions}/>
            </div>
        </DocumentTitle>
    );

    function reload() {
        return packageService.getOutbox(tableHandler.queryOptions)
            .then((value: any) => {
                    tableHandler.updateTotal(value.total);
                    setData(value.data);
                }
            )
    }

}

export default PackagesOutbox;
