import Icon, {CloseCircleFilled, CloseOutlined, LinkOutlined, PaperClipOutlined, QuestionCircleOutlined, SyncOutlined, TeamOutlined, UserOutlined} from '@ant-design/icons';
import {Alert, Button, Card, Checkbox, Col, Form, Input, InputNumber, InputRef, message, Radio, Row, Select, Skeleton, Tooltip, Upload,} from 'antd';
import {useForm} from "antd/lib/form/Form";
import {UploadFile} from "antd/lib/upload/interface";
import PromisePool from "es6-promise-pool";
import Cookies from "js-cookie";
import {useContext, useEffect, useRef, useState} from "react";
import {AdalConfig, AuthenticationContext} from "react-adal";
import CopyToClipboard from 'react-copy-to-clipboard';
import {useHistory, useLocation, useParams} from "react-router";
import {matchRoutes} from "react-router-config";
import {Link, Redirect} from 'react-router-dom';
import {AppContextContext, CaptchaServiceContext, ContactGroupServiceContext, ContactServiceContext, PackageFileServiceContext, PackageServiceContext, PasswordServiceContext} from "../Contexts";
import {Contact} from "../domain/Contact";
import {ContactGroup} from "../domain/ContactGroup";
import {Package, PackageAccessType, PackageFile, PackagePasswordType} from "../domain/Package";
import {Recipient} from "../domain/Recipient";
import {UserPermission} from "../domain/User";
import {useIntlMessage} from "../sal-ui/createIntlMessage";
import {ServerConstraintViolationsHolder} from "../sal-ui/ServerConstraintViolations";
import ValidationUtils from "../service/common/ValidationUtils";
import FormatUtils from "../utils/FormatUtils";
import {anonymousRoutes, anonymousRoutesMap} from "./AnonymousRoutes";
import {IconCloudUpload} from "./common/CustomIcons";
import {DocumentTitle} from "./DocumentTitle";
import PackagePasswordRequired from "./PackagePasswordRequired";
import {routes, routesMap} from "./Routes";
import styles from "./UploadPackage.module.css";
import UploadProgress from "./UploadProgress";

const Dragger = Upload.Dragger;
const {TextArea} = Input;

enum RequestState {
    Loading,
    Loaded,
    PasswordRequired,
    AccessDenied,
    NotFound,
    TooManyRequests
}

interface IItemForUpload {
    packageId: string,
    fileId: string,
    file: PackageUploadFile,
    config: { onUploadProgress: () => void, cancelToken: () => void, source: any }
}

interface PackageUploadFile extends UploadFile {
    uploading?: boolean;
    uid: string;
}

function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}


function hasErrors(fieldsError: any) {
    return Object.keys(fieldsError).some(field => {
        return fieldsError[field].errors.length > 0;
    });
}

let autoreloadId: any = null;

const serverViolationsHolder = new ServerConstraintViolationsHolder();

function PackageUpload() {
    const forceUpdate = useForceUpdate();

    const appContext = useContext(AppContextContext);
    const applicationConfig = appContext.applicationConfig;
    const packageService = useContext(PackageServiceContext);
    const captchaService = useContext(CaptchaServiceContext);
    const packageFileService = useContext(PackageFileServiceContext);
    const contactService = useContext(ContactServiceContext);
    const contactGroupService = useContext(ContactGroupServiceContext);
    const passwordService = useContext(PasswordServiceContext);
    const location = useLocation();

    const intlMessage = useIntlMessage("packages-inbox-outbox");
    const navigate = useHistory();

    const [form] = useForm();

    let layout: any;
    let uploadFormLayout: any;

    // let downloadPasswordToken: string | undefined = undefined;
    const downloadPasswordToken = useRef<string | undefined>(undefined);

    let showValidationErrors = false;
    let addFileTimer: any = undefined;
    let addingFileTimer: any = undefined;

    let fileUploadSettings = {
        multiple: true,
        action: "upload",
        onChange: ((info: any) => draggedChanged(info)),
        beforeUpload: ((file: any) => beforeUpload(file)),
        onRemove: ((file: any) => onRemove(file)),
        accept: "",
    };

    const formItemLayout = {
        labelCol: {span: 24},
        wrapperCol: {span: 24},
    };


    const [canceledByUser, setCanceledByUser] = useState(false);

    const [requestState, setRequestState] = useState<RequestState>(RequestState.Loading);
    const [uploadState, setUploadState] = useState<"UPLOAD_FORM" | "REQUEST_UPLOAD_FORM" | "UPLOADING" | "DONE" | "FAILED">();
    const [submitAllowed, setSubmitAllowed] = useState<boolean>();
    const [uploading, setUploading] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);
    const [switchPassword, setSwitchPassword] = useState<boolean>(false);
    const [switchEncryptPassword, setSwitchEncryptPassword] = useState<boolean>(false);

    const [downloadPasswordRequired, setDownloadPasswordRequired] = useState<boolean>(false);

    const downloadPasswordRef = useRef<InputRef>(null);
    const [switchTtl, setSwitchTtl] = useState<boolean>(false);
    const [switchLimitAccessCount, setSwitchLimitAccessCount] = useState<boolean>(false);

    const [byteProgress, setByteProgress] = useState<number>(0);
    const [fileProgress, setFileProgress] = useState<number>(0);
    const [notice, setNotice] = useState<string>("");
    const [requestedPackage, setRequestedPackage] = useState<Package>();
    const [enteredInvalidPassword, setEnteredInvalidPassword] = useState<boolean>(false);
    const [serverErrorMessage, setServerErrorMessage] = useState<string>("");
    const [accessType, setAccessType] = useState<PackageAccessType | undefined>(PackageAccessType.PRIVATE);

    const [userAddressBook, setUserAddressBook] = useState<Contact[]>([]);
    const [userContactGroups, setUserContactGroups] = useState<ContactGroup[]>([]);

    const [selectedRecipients, setSelectedRecipients] = useState<any[]>([]);
    const [selectedBlindRecipients, setSelectedBlindRecipients] = useState<any[]>([]);
    const [selectedContributors, setSelectedContributors] = useState<any[]>([]);

    const [recipientEmailAddressBookVisible, setRecipientEmailAddressBookVisible] = useState<boolean>(false);
    const [blindRecipientEmailAddressBookVisible, setBlindRecipientEmailAddressBookVisible] = useState<boolean>(false);
    const [contributorEmailAddressBookVisible, setContributorEmailAddressBookVisible] = useState<boolean>(false);


    const [totalBytes, setTotalBytes] = useState(0);
    const [totalFiles, setTotalFiles] = useState(0);
    const [uploadedBytes, setUploadedBytes] = useState(0);
    const [uploadedFiles, setUploadedFiles] = useState(0);

    const [uploadFileList, setUploadFileList] = useState<PackageUploadFile[]>([]);
    const [componentUnmounting, setComponentUnmounting] = useState<boolean>(false);

    const [filesUploadProgress, setFilesUploadProgress] = useState<any>({});

    const [briefcaseMode, setBriefcaseMode] = useState<boolean>(false);

    const {id}: any = useParams();

    /**
     * Array of files - used as source for UploadProduder
     */
    const dataForUpload = useRef<IItemForUpload[]>([]);
    const uploadingData = useRef<IItemForUpload[]>([]);
    const uploadStateGlobal = useRef<"UPLOAD_FORM" | "REQUEST_UPLOAD_FORM" | "UPLOADING" | "DONE" | "FAILED">();
    // const [packageId, setPackageId] = useState<string | null>(null);
    const packageId = useRef<string | null>(null);

    const filesUploadProgressBytes = useRef<any>([]);

    const [templateMode, setTemplateMode] = useState<boolean>(false);
    const [templateRecipients, setTemplateRecipients] = useState<string[]>();
    const [templateShowPassword, setTemplateShowPassword] = useState<boolean>(true);
    const [templateShowExpiration, setTemplateShowExpiration] = useState<boolean>(true);

    function firstMatchedRoute(): any | null {
        const tmpRoutes = appContext.user ? routes : anonymousRoutes;
        const matchedRoutes = matchRoutes(tmpRoutes, location.pathname);

        if (matchedRoutes && matchedRoutes.length === 1) {
            return matchedRoutes[0].route;
        } else {
            return null;
        }
    }

    function getAccessType() {
        let type = !!appContext.user ? applicationConfig!.packageSendDefaultOption : PackageAccessType.PRIVATE

        if (briefcaseMode) {
            type = PackageAccessType.BRIEFCASE;
        }

        let perm;

        switch (type) {
            case PackageAccessType.INTERNAL:
                perm = UserPermission.SEND_PACKAGE__INTERNAL;
                break;
            case PackageAccessType.PRIVATE:
                perm = UserPermission.SEND_PACKAGE__PRIVATE;
                break;
            case PackageAccessType.PUBLIC:
                perm = UserPermission.SEND_PACKAGE__PUBLIC;
                break;
            case PackageAccessType.BRIEFCASE:
                perm = UserPermission.SEND_PACKAGE__BRIEFCASE;
                break;
        }

        if (!appContext?.user?.hasPermission(perm)) {
            return undefined;
        } else {
            return type;
        }
    }

    useEffect(() => {

        let firstState = firstMatchedRoute().firstState;

        const briefCaseModeLocal = firstMatchedRoute().firstState === "ADD_TO_BRIEFCASE_FORM";
        if (briefCaseModeLocal) {
            setBriefcaseMode(true);
            setSwitchPassword(true);

            firstState = "UPLOAD_FORM";
        }

        setUploadState(firstState);
        uploadStateGlobal.current = firstState;

        if (!appContext.user) {
            captchaService.loadCaptcha();
            setDownloadPasswordRequired(applicationConfig?.anonymousUploadDownloadPasswordRequired!);
        } else {
            if (!briefCaseModeLocal) {
                setDownloadPasswordRequired(applicationConfig?.userUploadDownloadPasswordRequired!);
            }
        }
        if (uploadStateGlobal.current === "REQUEST_UPLOAD_FORM") {
            loadRequestedPackage();

        }

        if ((!appContext.user && applicationConfig?.anonymousUploadDownloadPasswordRequired!) || (appContext.user && applicationConfig?.userUploadDownloadPasswordRequired! && !briefCaseModeLocal)) {
            setSwitchPassword(true);
        }

        loadContacts();

        setAccessType(getAccessType());

        setSwitchLimitAccessCount(applicationConfig?.packageLimitAccessRequired! && getAccessType() === PackageAccessType.PUBLIC);

        setTimeout(form.setFieldsValue, 200, {accessType: getAccessType()});
        setTimeout(form.setFieldsValue, 200, {rec: getAccessType()});

        // uprava pro pentu ... jde o sablonu prijemce definovanou z url
        // napriklad:
        // #/?template&recipient=malusek@napismito.cz&recipient=glo@glottis.net&password=false&expiration=false
        // prida dva odesilatele, udela pole needitovatelne, skryje pole por heslo a expiraci
        const query = new URLSearchParams(location.hash);
        if (query.size > 0) {
            const recipients = query.getAll("recipient");
            if (recipients.length > 0) {
                setTemplateMode(true);
                setTemplateRecipients(recipients);
            }

            setTemplateShowPassword(query.get("password") === "true");
            setTemplateShowExpiration(query.get("expiration") === "true");
        }

        setTimeout(forceUpdate, 100);

        return () => {
            // console.log("unmounting", uploadStateGlobal.current, packageId.current, uploadingData);
            setComponentUnmounting(true);

            if (uploadStateGlobal.current === "UPLOADING") {
                cancelAllUploads();
                if (packageId.current) {
                    packageService.cancel(packageId.current);
                }
            }

            captchaService.unloadCaptcha();
        }
    }, [])

    // nacte adresar
    function loadContacts() {
        if (appContext.user && !briefcaseMode) {
            contactService.getSimpleList().then((contactList: Contact[]) => {
                setUserAddressBook(contactList);
            });

            contactGroupService.getSimpleList().then((contactGroupList: ContactGroup[]) => {
                setUserContactGroups(contactGroupList);
            });
        }
    }

    layout = {xs: 24, sm: 24, md: 24, lg: 20, xl: 16, xxl: 12};
    uploadFormLayout = {xs: 24, sm: 12, md: 12, lg: 12, xl: 12, xxl: 12};

    if (appContext.user) {
        uploadFormLayout = {xs: 24, sm: 24, md: 12, lg: 12, xl: 12, xxl: 12};
        layout = {xs: 12, sm: 12, md: 24, lg: 24, xl: 20, xxl: 16};
    }

    let content: any;
    let h1: any;
    let h3: any;
    switch (uploadState) {
        case "UPLOAD_FORM":
            content = renderUploadForm();
            if (appContext.user) {
                if (briefcaseMode) {
                    h1 = "package.new_briefcase_package";
                } else {
                    h1 = "package.new_package";
                }
                h3 = "";
            } else {
                h1 = "package.send_package";
                h3 = "package.send_package_note";
            }
            break;
        case "REQUEST_UPLOAD_FORM":
            content = renderRequestUploadForm();

            switch (requestState) {
                case RequestState.Loaded:
                    h1 = (requestedPackage && requestedPackage.name ? requestedPackage.name : intlMessage("common.package"));
                    h3 = (requestedPackage ? requestedPackage.note : intlMessage("package.send_requested_package_note"));
                    break;
                case RequestState.PasswordRequired:
                    h1 = "";
                    h3 = "";
                    break;
                case RequestState.AccessDenied:
                    h1 = ""; // intlMessage("package-detail.access-denied-login-required");
                    h3 = ""; // intlMessage("package-detail.access-denied");

                    // presmerujeme na package jen kdyz je uzivatel neprihlaseny
                    if (!appContext.user) {
                        Cookies.set("Load-package", window.location.pathname, {path: "/", expires: new Date(Date.now() + (60 * 1000))});
                    } else {
                        Cookies.remove("Load-package", {path: "/"});
                    }

                    break;
                case RequestState.NotFound:
                default:
                    h1 = intlMessage("package-detail.not-found");
                    h3 = "";
                    break;
            }

            break;
        case "UPLOADING":
            content = renderUploading();
            h1 = "package.uploading_package";
            h3 = "package.uploading_package_note";
            break;
        case "DONE":
            content = renderUploadDone();
            h1 = "package.upload_done";
            h3 = "package.upload_done_note";
            break;
        case "FAILED":
            content = renderUploadFailed();
            h1 = "package.upload_failed";
            h3 = "package.upload_failed_note";
            break;
        default:
            content = "";
    }

    switch (uploadState) {
        case "REQUEST_UPLOAD_FORM":
            break;
        case "UPLOAD_FORM":
        case "UPLOADING":
        case "FAILED":
        case "DONE":
            h1 = intlMessage(h1);
            h3 = h3 ? intlMessage(h3) : "";
            break;
        default:
            break;
    }

    return (
        <DocumentTitle
            title={`${applicationConfig!.title}: ${intlMessage("package.send_package")}`}>
            <>
                <Row gutter={16} justify={!appContext.user ? "space-around" : undefined}
                     align={"middle"}>
                    <Col {...layout}>
                        <Row>
                            <Col span={24}>
                                <div className={!appContext.user ? "upload-package-title" : ""}>
                                    <h1>{h1}</h1>
                                    <h3>{h3}</h3>
                                </div>
                                {notice &&
                                    <Alert style={{marginBottom: 24}} message={notice} type="error"
                                           showIcon={true}/>
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {content}
            </>
        </DocumentTitle>
    );


    function renderUploading() {

        return <Row gutter={16} justify={!appContext.user ? "space-around" : undefined}
                    align={"middle"}>
            <Col {...layout}>
                <h2>{intlMessage("package.total")}</h2>

                <UploadProgress key={"allBytes"} name={intlMessage("package.uploaded")}
                                info={FormatUtils.formatBytes(uploadedBytes) + " z " + FormatUtils.formatBytes(totalBytes)}
                                value={totalFiles === uploadedFiles ? 100 : byteProgress ? byteProgress : 0}/>

                <UploadProgress key={"allFiles"}
                                name={intlMessage("package.files", {files: uploadedFiles})}
                                info={uploadedFiles + "/" + totalFiles}
                                value={Math.round((uploadedFiles * 100) / totalFiles)}/>
                <h2>{intlMessage("package.uploading-files")}</h2>

                <div style={{height: "100px"}}>
                    {uploadFileList.map(item => {
                        if (item.uploading) {
                            return (<UploadProgress key={item.uid} name={item.name} value={filesUploadProgress[item.uid]}/>)
                        } else {
                            return ""
                        }

                    })}
                </div>

                <Button type={"default"}
                        size={"large"}
                        className={"btn-upload btn-upload-storno"}
                        onClick={handleUploadStorno}>
                    {intlMessage("package.btn-upload_storno")}
                </Button>
            </Col>
        </Row>
    }

    function renderUploadDone() {
        const downloadUrl = applicationConfig!.baseDownloadUri + "/packages/" + packageId.current;

        let packageDoneId: string;
        if (accessType === PackageAccessType.PUBLIC) {
            packageDoneId = 'package.done_explain.public';
        } else {
            packageDoneId = 'package.done_explain.private';
        }

        return (
            <Row gutter={16} justify={!appContext.user ? "space-around" : undefined}
                 align={"middle"}>
                <Col {...layout}>
                    <Row>
                        <Col>
                            <div style={{fontSize: "16px", paddingBottom: "20px"}}>
                                <p>
                                    {intlMessage(packageDoneId)}
                                </p>
                                <Card style={{fontSize: "16px"}}>
                                    {appContext.user ?
                                        <>
                                            <a href={downloadUrl}>{downloadUrl}</a>
                                            &nbsp;
                                            <CopyToClipboard text={downloadUrl}
                                                             onCopy={() => message.info(intlMessage("package-detail.link-copied"))}>
                                                <a title={intlMessage("package-detail.copy-link-title")}
                                                   className={"copy-link"}><LinkOutlined/></a>
                                            </CopyToClipboard>
                                        </>
                                        : <>
                                            <CopyToClipboard text={downloadUrl}
                                                             onCopy={() => message.info(intlMessage("package-detail.link-copied"))}>
                                                <a title={intlMessage("package-detail.copy-link-title")}
                                                   className={"copy-link"}>{downloadUrl} <LinkOutlined/></a>
                                            </CopyToClipboard>
                                        </>}

                                </Card>
                            </div>
                        </Col>
                    </Row>
                    {(appContext.user || applicationConfig!.allowAnonymousUpload) &&
                        <Row>
                            <Col xs={14}>
                                <Button type={"primary"} size={"large"} className={"btn-upload btn-upload-done"}
                                        onClick={handleUploadDone}>{intlMessage("package.btn-upload_done")}</Button>
                            </Col>
                        </Row>
                    }
                </Col>
            </Row>
        );

    }

    function renderUploadFailed() {

        return <Row gutter={16} justify={!appContext.user ? "space-around" : undefined}
                    align={"middle"}>
            <Col {...layout}>
                <Row>
                    <Col xs={14}>
                        <Button type={"primary"} size={"large"} className={"btn-upload btn-upload-done"}
                                onClick={handleUploadRetry}>{intlMessage("package.btn-upload_retry")}</Button>
                    </Col>
                </Row>
            </Col>
        </Row>

    }

    function renderUploadForm() {
        const {getFieldsError} = form;

        // presmerujeme na login, pokud neni upload povolen pro anonymni uzivatele
        if (!appContext.user && !applicationConfig!.allowAnonymousUpload) {
            return <Redirect to={anonymousRoutesMap.Login.path}/>
        }

        // presmerujeme na welcome page pokud neni upload povolen pro prihlaseneho uzivatele
        if (appContext.user &&
            ((!appContext.user.hasPermission(UserPermission.SEND_PACKAGE) && !briefcaseMode) ||
                (briefcaseMode && (applicationConfig?.briefcase === "NO" ||
                    (applicationConfig?.briefcase === "PERMISSION_BASED" && !appContext.user.hasPermission(UserPermission.SEND_PACKAGE__BRIEFCASE)))))) {
            return <Redirect to={routesMap.Dashboard.path}/>
        }

        // validace
        const allowedDomainsRegExp = "(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])";
        const emailRegExpString = "(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*\")";

        const {Option} = Select;

        const filteredAddressBook: Contact[] = [...userAddressBook];
        const filteredAddressBookBlind: Contact[] = [...userAddressBook];
        /*
                selectedRecipients.forEach(value => {
                    if (!filteredAddressBook.find(item =>
                        item.email === value
                    )) {
                        // filteredAddressBook.push({email: value, name: value, group: false});
                        filteredAddressBook.splice(filteredAddressBook.indexOf(value), 1);
                    }
                });

                selectedBlindRecipients.forEach(value => {
                    if (!filteredAddressBookBlind.find(item =>
                        item.email === value
                    )) {
                        // filteredAddressBookBlind.push({email: value, name: value, group: false});
                    }
                })

                selectedContributors.forEach(value => {
                    if (!filteredAddressBook.find((item, index) =>
                        item.email === value
                    )) {
                        // filteredAddressBook.push({email: value, name: value, group: false});
                    }
                });
        */
        userContactGroups.forEach(group => {
            filteredAddressBook.push({email: group.name!, name: undefined, group: true});
            filteredAddressBookBlind.push({email: group.name!, name: undefined, group: true});
        })

        return (
            <>
                <Row justify={!appContext.user ? "space-around" : undefined} align={"middle"}>
                    <Col {...layout}>
                        <Row gutter={16}>
                            <Col {...uploadFormLayout}>
                                <Dragger name='file' {...fileUploadSettings} fileList={uploadFileList}
                                         className={"upload-package-dragger"}
                                         showUploadList={false}>

                                    <p className="ant-upload-drag-icon">
                                        <Icon component={IconCloudUpload}/>
                                    </p>
                                    <p className="ant-upload-text">{intlMessage("package.uploader_text")}</p>
                                    <p className="ant-upload-hint">{intlMessage("package.uploader_hint")}</p>
                                    <p className="ant-upload-limits">
                                        {intlMessage("package.limit-file-count", {files: applicationConfig!.maxPackageFileCount})}<br/>
                                        {intlMessage("package.limit-file-size", {size: FormatUtils.formatBytes(applicationConfig!.maxFileSize)})}<br/>
                                        {intlMessage("package.limit-package-size", {size: FormatUtils.formatBytes(applicationConfig!.maxPackageSize)})}
                                    </p>

                                    <div className={"upload-button ant-btn-primary"}>{intlMessage("package.upload_files")}</div>
                                </Dragger>

                                <div className="ant-upload-list ant-upload-list-text">
                                    {totalFiles < 10 ?
                                        uploadFileList.map((item: any) =>
                                            <div key={item.uid}
                                                 className="ant-upload-list-item ant-upload-list-item-undefined">
                                                <div className="ant-upload-list-item-info">
                                                    <span>
                                                        <PaperClipOutlined/>
                                                        <span className="ant-upload-list-item-name" title={item.name}>{item.name}</span>
                                                    </span>
                                                </div>
                                                <CloseOutlined onClick={() => removeFile(item)}/>
                                            </div>)
                                        :
                                        <div key={"files"}
                                             className="ant-upload-list-item ant-upload-list-item-undefined ant-upload-list-item-combined">
                                            <div className="ant-upload-list-item-info">
                                                <div>
                                                    <PaperClipOutlined/>
                                                    <div className="ant-upload-list-item-name">
                                                        <span>{intlMessage("package.upload_files_count")}</span>
                                                        <span className={"float-right"}>{totalFiles}</span>
                                                    </div>
                                                    <div className="ant-upload-list-item-name">
                                                        <span>{intlMessage("package.upload_files_size")}</span>
                                                        <span className={"float-right"}>{FormatUtils.formatBytes(totalBytes)}</span>
                                                    </div>
                                                    <div className="ant-upload-list-item-name" style={{textAlign: "right"}}>
                                                        <Button onClick={() => removeFiles()} style={{verticalAlign: "top"}}>{intlMessage("package.remove_all_files")}</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>

                            </Col>

                            <Col {...uploadFormLayout}>
                                <Form form={form} onFinish={handleSubmit} autoComplete={"none"} layout={"vertical"}
                                      className={"non-modal-form upload-form " + (!appContext.user ? "upload-form-public" : "")}>

                                    <Form.Item
                                        label={intlMessage("package.label.name")} name={"name"}>
                                        <Input type="text" name="package-name" autoComplete={"none"} maxLength={100}/>
                                    </Form.Item>

                                    {!briefcaseMode &&
                                        <>
                                            <Form.Item id={"recipients"}
                                                       label={intlMessage("package.label.recipients")}
                                                       name={"recipientEmails"}
                                                       initialValue={templateRecipients}
                                                       normalize={(value, prevValue, allValues) => normalizeRecipients(value, prevValue, allValues, "REGULAR")}
                                                       rules={[
                                                           {
                                                               required: !appContext.user || (accessType === PackageAccessType.PRIVATE && (selectedContributors.length === 0)),
                                                               message: intlMessage("required.recipients")
                                                           }, {
                                                               pattern: new RegExp("^(" + emailRegExpString + "@(" + allowedDomainsRegExp + ")[,\n;]{0,1}[ ]*)+$"),
                                                               message: intlMessage("validation.recipients-bad-format")
                                                           }, {
                                                               pattern: new RegExp("^(?!(^|([\\s\\S]*[,\\n;]{1}[ ]*))(" + emailRegExpString + "@(" + allowedDomainsRegExp + "))[\\s\\S]*[,\n;]{1}[ ]*\\3)[\\s\\S]*$"),
                                                               message: intlMessage("validation.recipients-contain-duplicates")
                                                           }

                                                       ]}>
                                                <Select mode="tags" size={'middle'} style={{width: '100%'}} tokenSeparators={[',']} className="notranslate"
                                                        disabled={templateMode}
                                                        notFoundContent={null}
                                                        onSearch={fetchAddressbook}
                                                        open={recipientEmailAddressBookVisible}
                                                        filterOption={(inputValue, option) => filterEmailOption(inputValue, option, selectedRecipients.concat(selectedContributors))}
                                                        popupClassName={"recipientEmailsOptions"}
                                                        defaultActiveFirstOption={true}
                                                        optionLabelProp={"value"}
                                                        autoFocus={true}
                                                        suffixIcon={null}
                                                        onChange={value => {
                                                            setTimeout(form.validateFields, 50);
                                                            setTimeout(forceUpdate, 200);
                                                        }}
                                                >
                                                    {filteredAddressBook.map((contact: any) => (
                                                        <Option key={contact.email} value={contact.email} title={contact.name ? contact.name : contact.email}>
                                                            <div className={"contact-icon"}>
                                                                {contact.group ? <TeamOutlined/> : <UserOutlined/>}
                                                            </div>
                                                            <div className={"contact-text"}>
                                                                <span>{contact.name && <>{contact.name}<br/></>}{contact.email}</span>
                                                            </div>
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>

                                            <div className={"ant-form-extra"}>{intlMessage("package.placeholder.recipients")}</div>
                                        </>
                                    }

                                    {!briefcaseMode && appContext.user &&
                                        <>
                                            <Form.Item id={"blindRecipients"}
                                                       label={intlMessage("package.label.blind-recipients")} {...formItemLayout}
                                                       name={"blindRecipientEmails"}
                                                       normalize={(value, prevValue, allValues) => normalizeRecipients(value, prevValue, allValues, "BLIND")}
                                                       rules={[{
                                                           pattern: new RegExp("^(" + emailRegExpString + "@(" + allowedDomainsRegExp + ")[,\n;]{0,1}[ ]*)+$"),
                                                           message: intlMessage("validation.recipients-bad-format")
                                                       }, {
                                                           pattern: new RegExp("^(?!(^|([\\s\\S]*[,\\n;]{1}[ ]*))(" + emailRegExpString + "@(" + allowedDomainsRegExp + "))[\\s\\S]*[,\n;]{1}[ ]*\\3)[\\s\\S]*$"),
                                                           message: intlMessage("validation.recipients-contain-duplicates")
                                                       }
                                                       ]}>
                                                <Select mode="tags" size={'middle'} style={{width: '100%'}} tokenSeparators={[',']} className="notranslate"
                                                        notFoundContent={null}
                                                        onSearch={fetchAddressbookBlind}
                                                    // onChange={handleChangeBlindRecipientEmail}
                                                        open={blindRecipientEmailAddressBookVisible}
                                                        filterOption={(inputValue, option) => filterEmailOption(inputValue, option, selectedBlindRecipients)}
                                                        popupClassName={"recipientEmailsOptions"}
                                                        defaultActiveFirstOption={true}
                                                        optionLabelProp={"value"}
                                                        suffixIcon={null}
                                                >
                                                    {filteredAddressBookBlind.map((contact: any) => (
                                                        <Option key={contact.email} value={contact.email} title={contact.name ? contact.name : contact.email}>
                                                            <div className={"contact-icon"}>
                                                                {contact.group ? <TeamOutlined/> : <UserOutlined/>}
                                                            </div>
                                                            <div className={"contact-text"}>
                                                                <span>{contact.name && <>{contact.name}<br/></>}{contact.email}</span>
                                                            </div>
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                            <div className={"ant-form-extra"}>{intlMessage("package.placeholder.blind-recipients")}</div>
                                        </>
                                    }

                                    {!briefcaseMode && appContext.user && appContext.user.userConfig?.cooperativePackagesAllowed &&
                                        <>
                                            <Form.Item id={"contributors"}
                                                       label={intlMessage("package-detail.contributors")}
                                                       name={"contributorEmails"}
                                                       normalize={(value, prevValue, allValues) => normalizeRecipients(value, prevValue, allValues, "CONTRIBUTOR")}
                                                       rules={[
                                                           {
                                                               required: !appContext.user || (accessType === PackageAccessType.PRIVATE && (selectedRecipients.length === 0)),
                                                               message: intlMessage("required.recipients")
                                                           }, {
                                                               pattern: new RegExp("^(" + emailRegExpString + "@(" + allowedDomainsRegExp + ")[,\n;]{0,1}[ ]*)+$"),
                                                               message: intlMessage("validation.recipients-bad-format")
                                                           }, {
                                                               pattern: new RegExp("^(?!(^|([\\s\\S]*[,\\n;]{1}[ ]*))(" + emailRegExpString + "@(" + allowedDomainsRegExp + "))[\\s\\S]*[,\n;]{1}[ ]*\\3)[\\s\\S]*$"),
                                                               message: intlMessage("validation.recipients-contain-duplicates")
                                                           }

                                                       ]}>
                                                <Select mode="tags" size={'middle'} style={{width: '100%'}} tokenSeparators={[',']} className="notranslate"
                                                        notFoundContent={null}
                                                        onSearch={fetchContributorsAddressbook}
                                                        open={contributorEmailAddressBookVisible}
                                                        filterOption={(inputValue, option) => filterEmailOption(inputValue, option, selectedRecipients.concat(selectedContributors))}
                                                        popupClassName={"recipientEmailsOptions"}
                                                        defaultActiveFirstOption={true}
                                                        optionLabelProp={"value"}
                                                        autoFocus={false}
                                                        suffixIcon={null}
                                                        onChange={value => {
                                                            setTimeout(form.validateFields, 50);
                                                            setTimeout(forceUpdate, 200);
                                                        }}
                                                >
                                                    {filteredAddressBook.map((contact: any) => (
                                                        <Option key={contact.email} value={contact.email} title={contact.name ? contact.name : contact.email}>
                                                            <div className={"contact-icon"}>
                                                                {contact.group ? <TeamOutlined/> : <UserOutlined/>}
                                                            </div>
                                                            <div className={"contact-text"}>
                                                                <span>{contact.name && <>{contact.name}<br/></>}{contact.email}</span>
                                                            </div>
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>

                                            <div className={"ant-form-extra"}>{intlMessage("package.placeholder.contributors")}</div>
                                        </>
                                    }


                                    {!appContext.user ?
                                        <>
                                            <Form.Item
                                                label={intlMessage("package.label.sender_name")} name={"anonymousSenderName"}>
                                                <Input type="text" name="anonymousSenderName" maxLength={50}/>
                                            </Form.Item>
                                            <Form.Item
                                                label={intlMessage("package.label.sender_email")} name={"anonymousSenderEmail"}
                                                rules={[
                                                    {
                                                        type: "email",
                                                        message: intlMessage("validation.email-bad-format")
                                                    },
                                                    {
                                                        validator: ValidationUtils.createServerValidator(serverViolationsHolder, 'EMAIL'),
                                                        message: intlMessage("validation.email-bad-format")
                                                    }
                                                ]}>
                                                <Input type="text" name="anonymousSenderEmail" autoComplete={"none"} onChange={e => {
                                                    setTimeout(forceUpdate, 100)
                                                }} maxLength={100}/>
                                            </Form.Item>
                                        </> : ""
                                    }


                                    <Form.Item
                                        className={"padding-bottom-12px"}
                                        label={intlMessage("package.label.note")} name={"note"}>
                                        <TextArea name="note" autoSize={{minRows: 1, maxRows: 6}}
                                                  autoComplete={"none"} maxLength={10000}/>
                                    </Form.Item>

                                    <div className="ant-row ant-form-item checkbox" hidden={!templateShowPassword}>
                                        {!briefcaseMode &&
                                            <div className="ant-col-24">
                                                <Checkbox
                                                    checked={downloadPasswordRequired === true || switchPassword}
                                                    disabled={downloadPasswordRequired}
                                                    data-test-id={"downloadPasswordCheckbox"}
                                                    onChange={(e) => {
                                                        setSwitchPassword(e.target.checked);

                                                        if (e.target.checked) {
                                                            setTimeout(() => downloadPasswordRef.current?.focus(), 0);
                                                        }

                                                        form.resetFields(["downloadPassword", "encryptDataWithPassword"]);

                                                        setTimeout(forceUpdate, 100);
                                                    }}>
                                                    {intlMessage("package.label.password")}
                                                </Checkbox>
                                            </div>
                                        }

                                        {switchPassword &&
                                            <div className="ant-col-24">
                                                {
                                                    isPasswordEncryptionEnabledForUser() &&

                                                    <Form.Item
                                                        name={"encryptDataWithPassword"}
                                                        className={styles['encrypt-data-with-password']}
                                                        valuePropName={"checked"}>
                                                        <Checkbox data-test-id={"encryptDataWithPassword"}
                                                                  onChange={(e) => {
                                                                      setSwitchEncryptPassword(e.target.checked);

                                                                      if (e.target.checked) {
                                                                          setTimeout(() => downloadPasswordRef.current?.focus(), 0);
                                                                      }
                                                                  }}>
                                                            {intlMessage("package.label.encrypt-data-with-password")}

                                                            <Tooltip title={intlMessage("package.label.encrypt-data-with-password-tooltip")} className={styles['tooltip']}>
                                                                <QuestionCircleOutlined/>
                                                            </Tooltip>
                                                        </Checkbox>
                                                    </Form.Item>
                                                }

                                                <Form.Item
                                                    name={"downloadPassword"}
                                                    className={'download-password'}
                                                    rules={[
                                                        {min: appContext.applicationConfig?.packagePasswordRequirements.minLength, message: intlMessage("validation.password-too-short", {length: appContext.applicationConfig?.packagePasswordRequirements.minLength})},
                                                        {required: ((!briefcaseMode && switchPassword) || switchEncryptPassword || downloadPasswordRequired), message: intlMessage("required.password")},
                                                        {validator: serverViolationsHolder.createServerValidator('CUSTOM')}
                                                    ]}>
                                                    <Input.Password autoComplete={"none"} maxLength={30} ref={downloadPasswordRef}
                                                                    onChange={e => {
                                                                        setTimeout(forceUpdate, 100)
                                                                    }}
                                                                    addonAfter={
                                                                        <Button type="text" icon={<SyncOutlined/>} title={intlMessage("generate-password")} onClick={generateRandomPassword}/>
                                                                    }
                                                                    style={{paddingBottom: 12}}

                                                    />
                                                </Form.Item>
                                            </div>
                                        }
                                    </div>

                                    <Form.Item className={"ant-row checkbox"} hidden={!templateShowExpiration}>
                                        <div className="ant-col-24">
                                            <Checkbox data-test-id={"downloadExpirationCheckbox"}
                                                      checked={switchTtl} onChange={(e) => {
                                                setSwitchTtl(e.target.checked);
                                                form.resetFields(["downloadExpiration"])
                                            }}>{intlMessage("package.label.ttl")}</Checkbox>
                                        </div>
                                        <div className="ant-col-24 padding-bottom-12px" hidden={!switchTtl}>
                                            <Form.Item name="downloadExpiration" noStyle
                                                       initialValue={briefcaseMode ? applicationConfig!.briefcaseCleanExpiration : applicationConfig!.cleanExpiration}
                                                       rules={[{
                                                           required: switchTtl,
                                                           message: intlMessage("required.download-expiration")
                                                       }
                                                       ]}>
                                                <InputNumber size={"large"}
                                                             min={applicationConfig!.minCleanExpiration}
                                                             max={applicationConfig!.maxCleanExpiration}
                                                             name="downloadExpiration" id="downloadExpiration"
                                                             autoComplete={"none"}
                                                             onChange={e => {
                                                                 setTimeout(forceUpdate, 100)
                                                             }}/>
                                            </Form.Item>
                                            &nbsp;{intlMessage("common.days")}
                                        </div>
                                    </Form.Item>

                                    {!briefcaseMode && appContext.user && applicationConfig?.packageLimitAccessAllowed &&
                                        <Form.Item className={"checkbox"}>
                                            <div className="ant-row">
                                                <div className="ant-col-24">
                                                    <Checkbox data-test-id={"limitAccessCountCheckbox"}
                                                              disabled={applicationConfig?.packageLimitAccessRequired && accessType === PackageAccessType.PUBLIC}
                                                              checked={(applicationConfig?.packageLimitAccessRequired && accessType === PackageAccessType.PUBLIC) || switchLimitAccessCount}
                                                              onChange={(e) => {
                                                                  setSwitchLimitAccessCount(e.target.checked);
                                                                  form.resetFields(["limitAccessCount"]);

                                                                  if (e.target.checked) {
                                                                      form.setFieldsValue({limitAccessCount: applicationConfig!.packageLimitAccessCount});
                                                                  }
                                                              }}>
                                                        {intlMessage("package.label.limit-access-count")}
                                                    </Checkbox>
                                                </div>
                                                <div className="ant-col-24 padding-bottom-12px" hidden={!switchLimitAccessCount}>
                                                    <Form.Item name="limitAccessCount" noStyle initialValue={applicationConfig!.packageLimitAccessCount} rules={[
                                                        {
                                                            required: switchLimitAccessCount,
                                                            message: intlMessage("required.limit-access-count")
                                                        }
                                                    ]}>
                                                        <InputNumber size={"large"}
                                                                     min={applicationConfig!.packageLimitAccessCountMin}
                                                                     max={applicationConfig!.packageLimitAccessCountMax}
                                                                     name="limitAccessCount" id="limitAccessCount"
                                                                     autoComplete={"none"}
                                                                     onChange={e => {
                                                                         setTimeout(forceUpdate, 100)
                                                                     }}/>
                                                    </Form.Item>
                                                    &nbsp;{intlMessage("common.accesses")}
                                                </div>
                                            </div>
                                        </Form.Item>
                                    }

                                    {!briefcaseMode && appContext.user &&
                                        <Form.Item name={"accessType"} className={"checkbox"} extra={intlMessage("package.access-type." + accessType + ".description")}
                                                   rules={[
                                                       {
                                                           required: true,
                                                           message: intlMessage("required.packageAccessType")
                                                       }
                                                   ]}>

                                            <Radio.Group data-test-id={"accessType"} buttonStyle="solid" onChange={(e) => {
                                                setAccessType(e.target.value);

                                                const touchedFields = Object.keys(form.getFieldsValue()).filter(value => form.isFieldTouched(value));

                                                if (applicationConfig?.packageLimitAccessRequired! && e.target.value === PackageAccessType.PUBLIC) {
                                                    setSwitchLimitAccessCount(e.target.value === PackageAccessType.PUBLIC);
                                                }

                                                // opravdu je toto potreba :)
                                                setTimeout(form.validateFields, 50);
                                                setTimeout(forceUpdate, 200);

                                            }}>
                                                {appContext.user.hasPermission(UserPermission.SEND_PACKAGE__PUBLIC) &&
                                                    <Radio.Button value={PackageAccessType.PUBLIC}>{intlMessage("package.access-type.PUBLIC")}</Radio.Button>
                                                }
                                                {appContext.user.hasPermission(UserPermission.SEND_PACKAGE__INTERNAL) &&
                                                    <Radio.Button value={PackageAccessType.INTERNAL}>{intlMessage("package.access-type.INTERNAL")}</Radio.Button>
                                                }
                                                {appContext.user.hasPermission(UserPermission.SEND_PACKAGE__PRIVATE) &&
                                                    <Radio.Button value={PackageAccessType.PRIVATE}>{intlMessage("package.access-type.PRIVATE")}</Radio.Button>
                                                }
                                            </Radio.Group>
                                        </Form.Item>
                                    }

                                    <Form.Item style={{textAlign: "center", paddingTop: "10px"}}>
                                        <Button type="primary" size="large" htmlType="submit" id={"uploadButton"}
                                                disabled={hasErrors(form.getFieldsError()) || !submitAllowed}
                                                loading={uploading}>{briefcaseMode ? intlMessage("common.save") : intlMessage("package.send_package")}</Button>
                                    </Form.Item>

                                </Form>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        );
    }

    function generateRandomPassword() {

        passwordService.generateRandomPassword().then(value => {
            form.setFieldsValue({downloadPassword: value});

            // heslo jde kopirovat jen pokud jsme na ssl nebo localhost
            if (window.isSecureContext) {
                navigator.clipboard.writeText(value);
                message.info(intlMessage("password-copied"), 5);
            }
        });

    }

    function filterEmailOption(inputValue: any, option: any, selectedEmails: any[]) {
        const key: string = option.key;
        const name: string = option.props.title;
        let displayItem = false;

        console.log(key, inputValue, option)

        // hledani podle klice
        if (key && key.toLowerCase().includes(inputValue.toLowerCase())) {
            displayItem = true;
        }

        // hledani podle jmena
        if (name && name.toLowerCase().includes(inputValue.toLowerCase())) {
            displayItem = true;
        }

        if (displayItem) {
            return !selectedEmails.find(value => {
                return value.trim() === key.trim();
            });

        } else {
            return false;
        }
    }

    function fetchAddressbook(value: any) {
        if (value && value.length > 0) {
            setRecipientEmailAddressBookVisible(true);
        } else {
            setRecipientEmailAddressBookVisible(false);
        }
    }

    function fetchContributorsAddressbook(value: any) {
        if (value && value.length > 0) {
            setContributorEmailAddressBookVisible(true);
        } else {
            setContributorEmailAddressBookVisible(false);
        }
    }

    function fetchAddressbookBlind(value: any) {
        if (value && value.length > 0) {
            setBlindRecipientEmailAddressBookVisible(true);
        } else {
            setBlindRecipientEmailAddressBookVisible(false);
        }
    }

    function removeOwnEmailAddress(recipients: any[]) {
        if (!recipients) return recipients;

        if (applicationConfig?.sendPackageToYourself === "YES") {
            return recipients;
        }

        if (applicationConfig?.sendPackageToYourself === "NO") {
            if (recipients) {
                const index = recipients.indexOf(appContext.user?.email, 0);
                if (index > -1) {
                    recipients.splice(index, 1);
                }
            }
            return recipients;
        }

        if (applicationConfig?.sendPackageToYourself === "PERMISSION_BASED" && !appContext.user?.hasPermission(UserPermission.SEND_PACKAGE_TO_YOURSELF)) {
            if (recipients) {
                const index = recipients.indexOf(appContext.user?.email, 0);
                if (index > -1) {
                    recipients.splice(index, 1);
                }
            }
            return recipients;
        }
    }

    function normalizeRecipients(valueArray: any[], prevArray: any[], all: any, type: "REGULAR" | "BLIND" | "CONTRIBUTOR") {
        FormatUtils.expandGroup(valueArray, userContactGroups);

        const tmp = FormatUtils.normalizeRecipientsFromSelect(valueArray, prevArray, null);

        // removeOwnEmailAddress(tmp!);

        if (type === "REGULAR") {
            setRecipientEmailAddressBookVisible(false);
            setSelectedRecipients(tmp ? tmp : []);
        }
        if (type === "BLIND") {
            setBlindRecipientEmailAddressBookVisible(false);
            setSelectedBlindRecipients(tmp ? tmp : []);
        }
        if (type === "CONTRIBUTOR") {
            setContributorEmailAddressBookVisible(false);
            setSelectedContributors(tmp ? tmp : []);
        }

        return tmp;
    }

    function removeFile(file: PackageFile) {
        const tmpFileList = [...uploadFileList];

        for (let i = 0; i < tmpFileList.length; i++) {
            if (tmpFileList[i].uid === file.uid) {
                tmpFileList.splice(i, 1);

                setTotalBytes(prevState => prevState - file.size);
                setTotalFiles(prevState => prevState - 1);

                i--;
            }
        }
        setUploadFileList(tmpFileList);

        setSubmitAllowed(tmpFileList.length > 0);
    }

    function removeFiles() {
        setUploadFileList([]);
        setTotalBytes(0);
        setTotalFiles(0);
        setUploadedFiles(0);

        setSubmitAllowed(false);
    }

    function renderRequestUploadForm() {
        return (
            <Skeleton loading={requestState === RequestState.Loading}>
                {requestState === RequestState.Loaded ? renderRequestLoaded() : ""}
                {requestState === RequestState.AccessDenied ? renderRequestAccessDenied() : ""}
                {requestState === RequestState.NotFound ? renderRequestNotFound() : ""}
                {requestState === RequestState.TooManyRequests ? renderTooManyRequests() : ""}
                {requestState === RequestState.PasswordRequired ? (
                    <Row gutter={16} justify={!appContext.user ? "space-around" : undefined}
                         align={"middle"}>
                        <Col {...layout}>
                            <PackagePasswordRequired onSubmit={onPasswordSubmit}
                                                     enteredInvalidPassword={enteredInvalidPassword}
                                                     serverErrorMessage={serverErrorMessage}
                                                     passwordType={PackagePasswordType.Request}/> </Col>
                    </Row>
                ) : ""}
            </Skeleton>
        );
    }

    function renderRequestLoaded() {

        const recipient = (requestedPackage && requestedPackage.internalRecipients && requestedPackage.internalRecipients.length > 0
            ? requestedPackage.internalRecipients[0].email
            : "");

        return (
            <>
                <Row justify={!appContext.user ? "space-around" : undefined} align={"middle"}>
                    <Col {...layout}>
                        <Row gutter={16}>
                            <Col {...uploadFormLayout}>

                                <Dragger name='file' {...fileUploadSettings} fileList={uploadFileList}
                                         className={"upload-package-dragger"}
                                         showUploadList={false}
                                >
                                    <p className="ant-upload-drag-icon">
                                        <Icon component={IconCloudUpload}/>
                                    </p>
                                    <p className="ant-upload-text">{intlMessage("package.uploader_text")}</p>
                                    <p className="ant-upload-hint">{intlMessage("package.uploader_hint")}</p>
                                    <div
                                        className={"upload-button ant-btn-primary"}>{intlMessage("package.upload_files")}</div>
                                </Dragger>

                                <div className="ant-upload-list ant-upload-list-text">
                                    {totalFiles < 10 ?
                                        uploadFileList.map((item: any) =>
                                            <div key={item.uid}
                                                 className="ant-upload-list-item ant-upload-list-item-undefined">
                                                <div className="ant-upload-list-item-info">
                                                    <span>
                                                        <PaperClipOutlined/>
                                                        <span className="ant-upload-list-item-name" title={item.name}>{item.name}</span>
                                                </span>
                                                </div>
                                                <CloseOutlined onClick={() => removeFile(item)}/>
                                            </div>)
                                        :
                                        <div key={"files"}
                                             className="ant-upload-list-item ant-upload-list-item-undefined ant-upload-list-item-combined">
                                            <div className="ant-upload-list-item-info">
                                                <div>
                                                    <PaperClipOutlined/>
                                                    <div className="ant-upload-list-item-name">
                                                        <span>{intlMessage("package.upload_files_count")}</span>
                                                        <span className={"float-right"}>{totalFiles}</span>
                                                    </div>
                                                    <div className="ant-upload-list-item-name">
                                                        <span>{intlMessage("package.upload_files_size")}</span>
                                                        <span className={"float-right"}>{FormatUtils.formatBytes(totalBytes)}</span>
                                                    </div>
                                                    <div className="ant-upload-list-item-name" style={{textAlign: "right"}}>
                                                        <Button onClick={() => removeFiles()} style={{verticalAlign: "top"}}>{intlMessage("package.remove_all_files")}</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>

                            </Col>

                            <Col {...uploadFormLayout}>
                                <Form form={form} onFinish={handleSubmit} layout={"vertical"}
                                      className={"non-modal-form upload-form " + (!appContext.user ? "upload-form-public" : "")}>

                                    <Form.Item
                                        label={intlMessage("package.label.recipient")} name={"recipient"} initialValue={recipient}>
                                        <Input name="recipient" disabled={true}/>
                                    </Form.Item>

                                    <Form.Item
                                        label={intlMessage("package.label.note")} name={"note"} className={"padding-bottom-12px"}>
                                        <TextArea name="note" autoSize={{minRows: 1, maxRows: 6}}/>
                                    </Form.Item>

                                    <div className="ant-row ant-form-item checkbox">
                                        <div className="ant-col-24">
                                            <Checkbox
                                                checked={downloadPasswordRequired === true || switchPassword}
                                                disabled={downloadPasswordRequired}
                                                data-test-id={"downloadPasswordCheckbox"}
                                                onChange={(e) => {
                                                    setSwitchPassword(e.target.checked);

                                                    if (e.target.checked) {
                                                        setTimeout(() => downloadPasswordRef.current?.focus(), 0);
                                                    }

                                                    form.resetFields(["downloadPassword", "encryptDataWithPassword"]);

                                                    setTimeout(forceUpdate, 100);
                                                }}>
                                                {intlMessage("package.label.password")}
                                            </Checkbox>
                                        </div>

                                        {
                                            switchPassword &&
                                            <div className="ant-col-24">
                                                {
                                                    isPasswordEncryptionEnabledForUser() &&

                                                    <Form.Item
                                                        name={"encryptDataWithPassword"}
                                                        className={styles['encrypt-data-with-password']}
                                                        valuePropName={"checked"}>
                                                        <Checkbox data-test-id={"encryptDataWithPassword"}
                                                                  onChange={(e) => {
                                                                      setSwitchEncryptPassword(e.target.checked);

                                                                      if (e.target.checked) {
                                                                          setTimeout(() => downloadPasswordRef.current?.focus(), 0);
                                                                      }
                                                                  }}>
                                                            {intlMessage("package.label.encrypt-data-with-password")}

                                                            <Tooltip title={intlMessage("package.label.encrypt-data-with-password-tooltip")} className={styles['tooltip']}>
                                                                <QuestionCircleOutlined/>
                                                            </Tooltip>
                                                        </Checkbox>
                                                    </Form.Item>
                                                }

                                                <Form.Item
                                                    name={"downloadPassword"}
                                                    className={'download-password'}
                                                    rules={[
                                                        {min: appContext.applicationConfig?.packagePasswordRequirements.minLength, message: intlMessage("validation.password-too-short", {length: appContext.applicationConfig?.packagePasswordRequirements.minLength})},
                                                        {required: ((!briefcaseMode && switchPassword) || switchEncryptPassword || downloadPasswordRequired), message: intlMessage("required.password")},
                                                        {validator: serverViolationsHolder.createServerValidator('CUSTOM')}
                                                    ]}>
                                                    <Input.Password autoComplete={"none"} maxLength={30}
                                                                    ref={downloadPasswordRef}
                                                                    onChange={e => {
                                                                        setTimeout(forceUpdate, 100)
                                                                    }}
                                                                    addonAfter={
                                                                        <Button type="text" icon={<SyncOutlined/>} title={intlMessage("generate-password")} onClick={generateRandomPassword}/>
                                                                    }
                                                                    style={{paddingBottom: 12}}/>
                                                </Form.Item>
                                            </div>
                                        }
                                    </div>


                                    <Form.Item className={"ant-row ant-form-item checkbox"}>
                                        <div className="ant-row">
                                            <div className="ant-col-24">
                                                <Checkbox checked={switchTtl} onChange={(e) => {
                                                    setSwitchTtl(e.target.checked);
                                                    form.resetFields(["downloadExpiration"])
                                                }}>{intlMessage("package.label.ttl")}</Checkbox>
                                            </div>
                                            <div className="ant-col-24" hidden={!switchTtl} style={{marginBottom: "15px"}}>
                                                <Form.Item name={"downloadExpiration"} noStyle
                                                           initialValue={applicationConfig!.cleanExpiration}
                                                           className={"checkbox"} rules={[
                                                    {
                                                        required: switchTtl,
                                                        message: intlMessage("required.download-expiration")
                                                    }
                                                ]}>
                                                    <InputNumber size={"large"}
                                                                 min={applicationConfig!.minCleanExpiration}
                                                                 max={applicationConfig!.maxCleanExpiration}
                                                                 name="downloadExpiration" id="downloadExpiration"/>
                                                </Form.Item>
                                                &nbsp;{intlMessage("common.days")}
                                            </div>
                                        </div>
                                    </Form.Item>

                                    <Form.Item style={{textAlign: "center", paddingTop: "10px"}}>
                                        <Button type="primary" size="large" htmlType="submit" id={"uploadButton"}
                                                disabled={hasErrors(form.getFieldsError()) || !submitAllowed}>{intlMessage("package.send_package")}</Button>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </>
        );
    }

    function onPasswordSubmit(password: string) {
        return loadRequestedPackage(password);
    }

    function renderRequestAccessDenied() {
        // presmerujeme na package jen kdyz je uzivatel neprihlaseny
        if (!appContext.user) {
            Cookies.set("Load-package", window.location.pathname, {path: "/", expires: new Date(Date.now() + (60 * 1000))});
        } else {
            Cookies.remove("Load-package", {path: "/"});
        }

        if (window.location.search && window.location.search === "?autologin-adfs") {
            const adfsConfigs = applicationConfig!.adfsConfig;
            const protocol = process.env.NODE_ENV === 'development' ? "http:" : "https:";

            if (adfsConfigs && adfsConfigs[0]) {
                const adalConfig: AdalConfig = {
                    tenant: 'adfs',
                    clientId: adfsConfigs[0].clientId,
                    redirectUri: protocol + "//" + window.location.host + adfsConfigs[0].redirectUri,
                    instance: adfsConfigs[0].instance,
                    cacheLocation: 'localStorage',
                    extraQueryParameter: 'scope=openid&response_mode=form_post',
                }

                const authContext = new AuthenticationContext(adalConfig);

                authContext.login();
            }
        }

        return (
            <Row justify={"space-around"} align={"middle"} className={"login-form"}>
                <Col id={"login-form"} style={{textAlign: "center"}}>
                    {appContext.user ?
                        <>
                            <h1 className={"package-access-problem"}>{intlMessage("package-detail.access-denied")}</h1>
                            <CloseCircleFilled style={{fontSize: "50px", opacity: 0.3}}/>
                        </>
                        :
                        <>
                            <h2 style={{marginBottom: "50px"}}
                                className={"package-access-problem"}>{intlMessage("package-detail.access-denied-login-required")}</h2>
                            <Link to={anonymousRoutesMap.Login.path}
                                  id={"loginLink"}>{intlMessage("header.login")}</Link>
                        </>
                    }

                </Col>
            </Row>
        );
    }

    function renderRequestNotFound() {
        return "";
    }

    function renderTooManyRequests() {
        return (
            <Row justify={!appContext.user ? "space-around" : undefined} align={"middle"}>
                <Col {...layout}>
                    <h3 className={"package-access-problem"}>{serverErrorMessage}</h3>
                </Col>
            </Row>
        );
    }


    function updateFilesUploadProgress(uid: string, progress: number) {
        setFilesUploadProgress((oldValue: any) => {
            const tmp = {...oldValue};
            tmp[uid] = progress;
            return tmp;
        });

    }

    async function handleSubmit(values: any) {
        setTotalBytes(0);
        setTotalFiles(0);

        setCanceledByUser(false);

        setUploading(true);
        setNotice("");
        let uploadInProgress = true;

        // expiration disabled
        if (!switchTtl) {
            values.downloadExpiration = undefined;
        }

        // password disabled
        if (!switchPassword) {
            values.downloadPassword = undefined;
        }

        // password disabled
        if (!switchLimitAccessCount) {
            values.limitAccessCount = 0;
        }

        // clean empty values
        Object.keys(values).forEach((key) => {
            if (typeof values[key] === "string" && values[key] === "") {
                values[key] = undefined;
            }
        })

        // prepare all files and count total amount of bytes
        values.fileList = [];
        for (const file of uploadFileList) {
            values.fileList.push({
                id: null,
                packageId: null,
                name: file.name,
                size: file.size,
                type: file.type,
                uid: file.uid
            });

            setTotalBytes(prevState => prevState + (file.size ?? 0));
            setTotalFiles(prevState => prevState + 1);
        }

        // check captcha for unregistered users
        let captchaToken: string = "-";
        if (!appContext.user) {
            await captchaService.checkCaptcha("upload").then(token => {
                captchaToken = token;
            }).catch(reason => {
                captchaToken = reason;
            });
        }

        // recipients
        const recipients: Recipient[] = [];
        if (values.recipientEmails) {
            values.recipientEmails!.toString().split(/[,\n;]/).forEach((value: string) => {
                if (value.trim()) {
                    recipients.push({email: value.trim()});
                }
            })
        }
        if (values.blindRecipientEmails) {
            values.blindRecipientEmails!.toString().split(/[,\n;]/).forEach((value: string) => {
                if (value.trim()) {
                    recipients.push({email: value.trim(), blind: true});
                }
            })
        }
        if (values.contributorEmails) {
            values.contributorEmails!.toString().split(/[,\n;]/).forEach((value: string) => {
                if (value.trim()) {
                    recipients.push({email: value.trim(), blind: false, contributor: true});
                }
            })
        }
        values.recipients = recipients;
        values.recipientEmails = undefined;
        values.blindRecipientEmails = undefined;

        if (requestedPackage) {
            values.id = requestedPackage.id;
            values.recipients = undefined;
        }

        if (briefcaseMode) {
            values.accessType = PackageAccessType.BRIEFCASE;
        }

        let packageIdTmp: string | null = null;

        // trying create packege
        packageService.add(values, captchaToken, downloadPasswordToken.current).then((files) => {

            // show progress modal
            setSwitchPassword(false);
            setUploadState("UPLOADING");
            uploadStateGlobal.current = "UPLOADING";
            setSaving(true);
            uploadInProgress = true;


            // configs for all uploads
            const configs: any = [];
            dataForUpload.current = [];
            uploadingData.current = [];

            for (const originalFile of uploadFileList) {
                for (const idsFile of files) {
                    if (originalFile.uid === idsFile.uid && originalFile.uid && idsFile.packageId && idsFile.id) {

                        packageId.current = idsFile.packageId;
                        packageIdTmp = idsFile.packageId;

                        updateFilesUploadProgress(originalFile.uid, 0);

                        // upload progress callback
                        configs[originalFile.uid] = {
                            onUploadProgress: (progressEvent: any) => {
                                filesUploadProgressBytes.current[originalFile.uid ? originalFile.uid : "all"] = progressEvent.loaded;

                                updateFilesUploadProgress(originalFile.uid, Math.round((progressEvent.loaded * 100) / (originalFile.size ?? 1)));

                                let bytesCompleted = 0;
                                for (const key in filesUploadProgressBytes.current) {
                                    if (filesUploadProgressBytes.current.hasOwnProperty(key)) {
                                        bytesCompleted += filesUploadProgressBytes.current[key];
                                    }
                                }

                                setUploadedBytes(bytesCompleted);
                                let totalPercent = Math.round((bytesCompleted * 100) / totalBytes);
                                if (totalPercent === 100 && totalFiles > uploadedFiles) {
                                    totalPercent = 99;
                                }

                                if (!componentUnmounting) {
                                    setByteProgress(totalPercent);
                                }
                            }
                        }

                        // prepare data for concurrent upload
                        dataForUpload.current.push({
                            packageId: idsFile.packageId,
                            fileId: idsFile.id,
                            file: originalFile,
                            config: configs[originalFile.uid]
                        })

                    }
                }
            }

            const pool = new PromisePool(uploadProducer, 2);

            autoreloadId = setInterval(keepAlive, 60000);
            pool.start()
                .then(() => {
                    if (uploadInProgress) {
                        if (packageIdTmp) {
                            packageService.patch(packageIdTmp, downloadPasswordToken.current).then(() => {
                                setUploadState("DONE");
                                uploadStateGlobal.current = "DONE";
                                setSaving(false);
                                uploadInProgress = false;

                                downloadPasswordToken.current = undefined;

                            }, reason => {
                                setUploadState("FAILED");
                                uploadStateGlobal.current = "FAILED";
                                setSaving(false);
                                uploadInProgress = false;

                                const violations = reason.response.data.constraintViolations;

                                if (violations.files && violations.files.CUSTOM_JSON) {
                                    const validationError = JSON.parse(violations.files.CUSTOM_JSON.message);

                                    message.error(intlMessage("package.server-validation." + validationError.key, validationError), 10);
                                }
                                return reason;
                            })
                        } else {
                            console.log("missing packageId");
                        }
                    } else {
                        if (packageIdTmp) {
                            packageService.cancel(packageIdTmp);
                        }
                    }


                    clearInterval(autoreloadId);
                    autoreloadId = null;

                }, reason => {
                    if (!canceledByUser) {
                        setUploadState("FAILED");
                        uploadStateGlobal.current = "FAILED";
                        setSaving(false);
                        uploadInProgress = false;

                        if (packageIdTmp) {
                            packageService.cancel(packageIdTmp);
                        }
                    }

                    clearInterval(autoreloadId);
                    autoreloadId = null;

                    return reason;
                })

        }, reason => {

            if (reason.response.data.captcha) {
                setNotice(reason.response.data.message);
                return reason;
            }

            if (reason.response.data.uploadNotAllowed) {
                setNotice(intlMessage("validation." + reason.response.data.message));

                return reason;
            }

            const violations = reason.response.data.constraintViolations;

            if (violations) {
                if (violations.anonymousSenderEmail && violations.anonymousSenderEmail.CUSTOM_JSON!) {
                    const error = violations.anonymousSenderEmail!.CUSTOM_JSON;
                    const jsonError = JSON.parse(error.message);

                    if (requestState === RequestState.Loaded) {
                        message.error(intlMessage("validation." + jsonError.key + ".message", {email: jsonError.message}), 10);

                    } else {
                        showValidationErrors = true;

                        form.setFields([
                            {
                                name: "anonymousSenderEmail",
                                value: form.getFieldValue("anonymousSenderEmail"),
                                errors: [new Error(intlMessage("validation." + jsonError.key + ".message", {email: jsonError.message})).message]
                            }
                        ]);

                    }
                }


                if (violations.downloadPassword!) {
                    form.setFields([
                        {
                            name: "downloadPassword",
                            value: form.getFieldValue("downloadPassword"),
                            errors: [new Error(violations.downloadPassword.CUSTOM.message).message]
                        }
                    ]);

                }

                if (violations.anonymousSenderEmail && violations.anonymousSenderEmail.EMAIL!) {
                    if (requestState === RequestState.Loaded) {
                        message.error(intlMessage("validation.email-bad-format"), 10);

                    } else {
                        showValidationErrors = true;
                        form.setFields([
                            {
                                name: "anonymousSenderEmail",
                                value: form.getFieldValue("anonymousSenderEmail"),
                                errors: [new Error(intlMessage("validation.email-bad-format")).message]
                            }
                        ]);
                    }
                }

                if (violations.recipientEmails && violations.recipientEmails.CUSTOM_JSON!) {
                    const error = violations.recipientEmails!.CUSTOM_JSON;
                    const jsonError = JSON.parse(error.message);

                    showValidationErrors = true;
                    form.setFields([{
                            name: "recipientEmails",
                            value: form.getFieldValue("recipientEmails"),
                            errors: [new Error(intlMessage("validation." + jsonError.key + ".message", {email: jsonError.message})).message]
                        }]
                    );
                }

                if (violations.email && violations.email.EMAIL!) {
                    showValidationErrors = true;
                    form.setFields([{
                        name: "recipientEmails",
                        value: form.getFieldValue("recipientEmails"),
                        errors: [new Error(intlMessage("validation.recipients-bad-format")).message]

                    }]);

                }

                if (violations.files && violations.files.CUSTOM_JSON) {
                    const validationError = JSON.parse(violations.files.CUSTOM_JSON.message);

                    message.error(intlMessage("package.server-validation." + validationError.key, validationError), 10);
                }

                return reason;
            }

            // limity
            if (reason.response && reason.response.status === 403) {
                message.error(intlMessage("action-limit.too-frequent", reason.response.data), 10);
            }

            clearInterval(autoreloadId);
            autoreloadId = null;

        }).finally(() => {
            setUploading(false);
        });

    }

    function keepAlive() {
        packageFileService.keepAlive();
    }

    function uploadProducer() {
        if (dataForUpload.current.length > 0) {
            const itemForUpload = dataForUpload.current.pop();

            if (itemForUpload) {
                uploadingData.current.push(itemForUpload);

                itemForUpload.file.uploading = true;

                return packageFileService.upload(itemForUpload.packageId, itemForUpload.fileId, itemForUpload.file, itemForUpload.config, downloadPasswordToken.current).then(() => {
                    setUploadedFiles(prevState => prevState + 1);

                    itemForUpload.file.uploading = false;
                })
            } else {
                return void 0;
            }
        } else {
            return void 0;
        }
    }

    function draggedChanged(info: any) {
        //
    }

    function setStateAfterFilesAdded() {
        setSubmitAllowed(true);

        clearTimeout(addingFileTimer);
    }

    function addToFileList(fileTmp: any) {

        if (totalFiles < applicationConfig!.maxPackageFileCount) {

            // nastavit state jen pokud 100ms neprisel pozadavek na pridani souboru
            if (addFileTimer !== undefined) {
                clearTimeout(addFileTimer);
            }
            addFileTimer = setTimeout(setStateAfterFilesAdded, 100);

            setTotalBytes(prevState => prevState + fileTmp.size);
            setTotalFiles(prevState => prevState + 1);

            setUploadFileList(prevState => {
                return [...prevState, fileTmp];
            });
        }

    }

    function validatePackageLimits(file: File) {

        const mimeFilter = (applicationConfig && applicationConfig.mimeFilter) ? applicationConfig.mimeFilter : null;

        if (file.size === 0) {
            return false;
        }


        // file size
        if (file.size > applicationConfig!.maxFileSize) {
            message.error(intlMessage("package.validation.file-too-big", {
                file: file.name,
                maxFileSize: FormatUtils.formatBytes(applicationConfig!.maxFileSize)
            }), 10);
            return false;
        }

        // package size
        if (totalBytes + file.size > applicationConfig!.maxPackageSize) {
            message.error(intlMessage("package.validation.package-too-big", {
                file: file.name,
                maxPackageSize: FormatUtils.formatBytes(applicationConfig!.maxPackageSize)
            }), 10);
            return false;
        }

        // file count
        if (totalFiles + 1 > applicationConfig!.maxPackageFileCount) {
            message.error(intlMessage("package.validation.too-many-files", {
                file: file.name,
                maxPackageFileCount: applicationConfig!.maxPackageFileCount
            }), 10);
            return false;
        }

        // file uniq
        let uniq = true;
        uploadFileList.forEach((item: PackageUploadFile) => {
            if (item.name === file.name) {
                message.error(intlMessage("package.validation.file-already-attached", {file: file.name}), 10);
                uniq = false;
            }
        });
        if (!uniq) {
            return false;
        }

        // MIME
        let allowed = false;
        // prevede string na regexp objekt
        const createRegExp = (str: string): RegExp => {
            str = str.replace("+", "[+]");
            return new RegExp("^" + str.trim() + "$");
        }

        // vypnuty filter nebo chybi nastaveni
        if (mimeFilter === null || !mimeFilter.enabled) {
            allowed = true;
        }

        if (mimeFilter !== null && mimeFilter.enabled) {
            const initValue = mimeFilter.kind === "whitelist";

            // aplikace BL/WL
            allowed = !initValue;

            mimeFilter.list.forEach((value) => {
                if (file.type.match(createRegExp(value))) {
                    allowed = initValue;
                }
            });

            // podle vysledku soubor bud pridame nebo ne
            if (!allowed) {
                message.error(intlMessage("package.mime-not-allowed"), 10);
                return false;
            }
        }

        return true;
    }

    function beforeUpload(file: File): boolean {

        if (validatePackageLimits(file)) {

            // pokud zname MIME, rovnou ho pridame
            if (file.type) {
                addToFileList(file);
            } else {
                // pokud mime nezname, zkusime soubor precist
                const testBlob = file.slice(0, 1);
                const reader = new FileReader();

                reader.onload = () => {
                    if (validatePackageLimits(file)) {
                        addToFileList(file);
                    }
                };

                reader.onerror = () => {
                    console.log("Cannot read file " + file.name + " or its directory.");

                    return false;
                };
                reader.readAsText(testBlob);
            }
        }

        return false;
    }


    function onRemove(file: any) {
        return true;
    }


    function handleUploadStorno(e: any) {
        setCanceledByUser(true)
        cancelAllUploads();

        if (packageId.current) {
            packageService.cancel(packageId.current);
        }

        resetForm();

        clearInterval(autoreloadId);
        autoreloadId = null;

    }

    function handleUploadDone(e: any) {
        // zaciname znovu, tak vycistime formular
        resetForm();

        // odeslana zasilka mohla pridat kontakt do adresare, tak ho znovu nacteme
        loadContacts();

        clearInterval(autoreloadId);
        autoreloadId = null;
    }

    function handleUploadRetry(e: any) {
        setUploadState("UPLOAD_FORM");
        uploadStateGlobal.current = "UPLOAD_FORM";
        filesUploadProgressBytes.current = [];
        setFilesUploadProgress({});
        setFileProgress(0);
        setByteProgress(0);
    }

    function cancelAllUploads() {

        console.log("Uploading data", uploadingData);

        uploadingData.current.forEach((value: IItemForUpload) => {
            value.config.source.cancel();
        });
        uploadingData.current = [];
    }

    function resetForm() {
        setTotalBytes(0);
        setTotalFiles(0);
        setUploadedFiles(0);
        filesUploadProgressBytes.current = [];
        setFilesUploadProgress({});
        setFileProgress(0);
        setByteProgress(0);
        setUploadFileList([]);
        dataForUpload.current = [];

        form.setFieldsValue({
                name: undefined,
                note: undefined,
                recipientEmails: undefined,
                blindRecipientEmails: undefined,
                contributorEmails: undefined,
                downloadPassword: undefined,
                anonymousSenderName: undefined,
                anonymousSenderEmail: undefined,
                downloadExpiration: undefined,
                limitAccessCount: undefined
            }
        );


        setSaving(false);

        setSubmitAllowed(false);
        setUploadState("UPLOAD_FORM");
        uploadStateGlobal.current = "UPLOAD_FORM";
        setSelectedRecipients([]);
        setSelectedBlindRecipients([]);
        setSelectedContributors([]);


        form.validateFields();
        if (briefcaseMode) {
            navigate.push(routesMap.AddToBriefcase.path);
        } else {
            navigate.push(anonymousRoutesMap.UploadPackage.path);
        }

    }

    function loadRequestedPackage(password?: string): Promise<any> {

        return packageService.get(id, password, downloadPasswordToken.current).then(
            aPackage => {
                if (aPackage.files && aPackage.files.length > 0) {
                    aPackage.totalSize = aPackage.files.map(f => f.size).reduce((total, size) => total + size);
                }

                downloadPasswordToken.current = aPackage.downloadTokenInfo ? aPackage.downloadTokenInfo!.passwordToken! : undefined;

                if (appContext.user !== null && aPackage.internalRecipients && appContext.user?.email === aPackage.internalRecipients[0].email && aPackage.internalRecipients[0].email !== aPackage.anonymousSenderEmail) {
                    setRequestState(RequestState.NotFound);
                } else {
                    setRequestedPackage(aPackage);
                    setRequestState(RequestState.Loaded);
                }

            },
            reason => {

                if (reason.response) {
                    if (reason.response.status === 400) {
                        setRequestState(RequestState.PasswordRequired);

                    } else if (reason.response.status === 404) {
                        setRequestState(RequestState.NotFound);

                    } else if (reason.response.status === 429) {
                        setEnteredInvalidPassword(true);
                        setRequestState(RequestState.PasswordRequired);
                        setServerErrorMessage(reason.response.data);

                    } else if (reason.response.status === 403) {
                        if (requestState === RequestState.PasswordRequired) {
                            setEnteredInvalidPassword(true);
                            setServerErrorMessage(reason.response.data);

                        } else {
                            setServerErrorMessage(reason.response.data);
                            if (appContext.user == null) {
                                setRequestState(RequestState.AccessDenied);
                            } else {
                                setRequestState(RequestState.NotFound);
                            }
                        }

                    }
                }
            });
    }

    function isPasswordEncryptionEnabledForUser() {
        if (appContext.user) {
            return applicationConfig?.encryptionAtRestEnabled && (applicationConfig.encryptionPasswordUsersMode === 'ENABLED' || applicationConfig.encryptionPasswordUsersMode === 'PERMISSION_BASED' && appContext.user.hasPermission(UserPermission.PASSWORD_ENCRYPTION));
        } else {
            return applicationConfig?.encryptionAtRestEnabled && applicationConfig.encryptionPasswordAnonymousEnabled;
        }
    }

}

export default PackageUpload;