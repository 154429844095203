import NoMatch from "./NoMatch";
import PackageDownload from "./package/download/PackageDownload";
import LoginPage from "./LoginPage";
import PackageUpload from "./PackageUpload";

export const anonymousRoutesMap: any = {
    Login: {
        path: "/login",
        exact: true,
        component: LoginPage
    },
    ResetPasswordToken: {
        path: "/reset-password-token",
        exact: true,
        component: LoginPage
    },
    UploadPackage: {
        path: "/upload-package",
        exact: true,
        component: PackageUpload,
        firstState: "UPLOAD_FORM"
    },
    DownloadPackage: {
        path: "/packages/:packageId([0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12})/:folderId([0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}|ROOT)?",
        exact: true,
        component: PackageDownload
    },
    PackageRequested: {
        path: "/package-requested/:id([0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12})",
        exact: true,
        component: PackageUpload,
        menuKey: "package-requested",
        firstState: "REQUEST_UPLOAD_FORM"
    },
    Welcome: {
        path: "/",
        exact: true,
        component: PackageUpload,
        firstState: "UPLOAD_FORM"
    },
    NoMatch: {
        path: "*",
        exact: false,
        component: NoMatch
    },

};

export const anonymousRoutes = Object.keys(anonymousRoutesMap).map(key => anonymousRoutesMap[key]);
