import {LinkOutlined, QuestionCircleOutlined, SyncOutlined, UserOutlined} from "@ant-design/icons";
import {Button, Card, Checkbox, Col, Form, Input, InputRef, message, Row, Select, Tooltip} from 'antd';
import {useForm} from "antd/lib/form/Form";
import {useContext, useEffect, useRef, useState} from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import {AppContextContext, ContactServiceContext, PackageServiceContext, PasswordServiceContext} from "../Contexts";
import {Contact} from "../domain/Contact";
import {Package, PackageAccessType} from "../domain/Package";
import {useIntlMessage} from "../sal-ui/createIntlMessage";
import {ServerConstraintViolationsHolder} from "../sal-ui/ServerConstraintViolations";
import ValidationUtils from "../service/common/ValidationUtils";
import FormatUtils from "../utils/FormatUtils";
import {DocumentTitle} from "./DocumentTitle";
import styles from "./UploadPackage.module.css";

const {TextArea} = Input;

function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}

function hasErrors(fieldsError: any) {
    return Object.keys(fieldsError).some(field => {
        return fieldsError[field].errors.length > 0;
    });
}

const serverViolationsHolder = new ServerConstraintViolationsHolder();

function PackageRequest() {
    const forceUpdate = useForceUpdate();
    const appContext = useContext(AppContextContext);
    const applicationConfig = appContext.applicationConfig;
    const packageService = useContext(PackageServiceContext);
    const contactService = useContext(ContactServiceContext);
    const passwordService = useContext(PasswordServiceContext);

    const intlMessage = useIntlMessage("packages-inbox-outbox");

    const [formState, setFormState] = useState<"REQUEST_FORM" | "DONE">("REQUEST_FORM");
    const [switchPassword, setSwitchPassword] = useState<boolean>(false);
    const [uploading, setUploading] = useState<boolean>(false);
    const [recipientEmailAddressBookVisible, setRecipientEmailAddressBookVisible] = useState<boolean>(false);
    const [selectedRecipients, setSelectedRecipients] = useState<any[]>([]);
    const [userAddressBook, setUserAddressBook] = useState<Contact[]>([]);
    const requestPasswordRef = useRef<InputRef>(null);
    const [recipientRequired, setRecipientRequired] = useState<boolean>(true);

    const packageId = useRef<string | null>(null);

    const [form] = useForm();

    const layout = {xs: 12, sm: 12, md: 16, lg: 20, xl: 16, xxl: 12};

    useEffect(() => {
        loadContacts();
    }, [])

    // nacte adresar
    function loadContacts() {
        if (appContext.user) {
            contactService.getSimpleList().then((contactList: Contact[]) => {
                setUserAddressBook(contactList);
            });
        }
    }

    let content: any;
    let h1: any;
    let h3: any;
    switch (formState) {
        case "REQUEST_FORM":
            content = renderRequestForm();
            h1 = "package.package_request";
            h3 = "package.package_request_note";
            break;
        case "DONE":
            content = renderDone();
            h1 = recipientRequired ? "package.request_done" : "package.request_create_done";
            h3 = "package.request_done_note";
            break;
        default:
            content = "";
    }

    return (
        <DocumentTitle title={`${applicationConfig!.title}: ${intlMessage("package.request_package")}`}>
            <Row>
                <Col {...layout}>
                    <Row>
                        <Col>
                            <h1>{intlMessage(h1)}</h1>
                            <h3>{intlMessage(h3)}</h3>
                        </Col>
                    </Row>
                    {content}

                </Col>
            </Row>
        </DocumentTitle>
    );


    function renderDone() {
        const requestUrl = applicationConfig!.baseDownloadUri + "/package-requested/" + packageId.current;
        const ownRequestUrl = applicationConfig!.baseDownloadUri + "/packages/" + packageId.current;

        return (
            <Row gutter={16} justify={!appContext.user ? "space-around" : undefined} align={"middle"}>
                <Col>
                    <Row>
                        <Col>
                            <div style={{fontSize: "16px", paddingBottom: "20px"}}>
                                <p>
                                    {recipientRequired ? intlMessage("package.request_done_explain") : intlMessage("package.request_create_done_explain")}
                                </p>
                                <Card style={{fontSize: "16px"}}>
                                    <a href={ownRequestUrl}>{requestUrl}</a>
                                    &nbsp;
                                    <CopyToClipboard text={requestUrl}
                                                     onCopy={() => message.info(intlMessage("package-detail.link-copied"))}>
                                        <a title={intlMessage("package-detail.copy-link-title")}
                                           className={"copy-link"}><LinkOutlined/></a>
                                    </CopyToClipboard>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={14}>
                            <Button type={"primary"} size={"large"} className={"btn-upload btn-upload-done"} onClick={handleDone}>{intlMessage("package.btn-upload_done")}</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );

    }

    function handleDone(e: any) {
        resetForm();
    }

    function renderRequestForm() {

        const {getFieldsError, isFieldsTouched} = form;

        const allowedDomainsRegExp = "(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])";

        const emailRegExpString = "(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*\")";

        const {Option} = Select;
        const filteredAddressBook: Contact[] = [...userAddressBook];

        return (
            <>
                <Form form={form} onFinish={handleSubmit} className={"non-modal-form package-request-form"} layout={"vertical"}>

                    <Form.Item label={intlMessage("package.label.package-request-name")} name={"name"}>
                        <Input type="text" name="name" maxLength={100}/>
                    </Form.Item>

                    <Form.Item id={"recipientEmails"} label={intlMessage("package.label.recipient-email")} name={"recipientEmails"} rules={[{
                        required: recipientRequired,
                        message: intlMessage("required.recipient")
                    }, {
                        pattern: new RegExp("^(" + emailRegExpString + "@(" + allowedDomainsRegExp + "))+$"),
                        message: intlMessage("validation.recipient-bad-format")
                    },
                        {
                            validator: ValidationUtils.createServerValidator(serverViolationsHolder, 'PATTERN'),
                            message: intlMessage("validation.recipient-bad-format")
                        }]} normalize={FormatUtils.normalizeRecipientsFromSelect}>
                        <Select mode="tags" size={'middle'} style={{width: '100%'}} tokenSeparators={[',']} className="notranslate"
                                notFoundContent={null}
                                onSearch={fetchAddressbook}
                                onChange={handleChangeRecipientEmail}
                                open={recipientEmailAddressBookVisible}
                                filterOption={filterRecipientEmailOption}
                                popupClassName={"recipientEmailsOptions"}
                                defaultActiveFirstOption={true}
                                optionLabelProp={"value"}
                                autoFocus={true}
                                suffixIcon={null}
                        >
                            {filteredAddressBook.map((contact: any) => (
                                <Option key={contact.email} value={contact.email} title={contact.name ? contact.name : contact.email}>
                                    <div className={"contact-icon"}>
                                        <UserOutlined/>
                                    </div>
                                    <div className={"contact-text"}>
                                        <span>{contact.name && <>{contact.name}<br/></>}{contact.email}</span>
                                    </div>
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name={"sendEmail"}
                        className={styles['send-email']}
                        valuePropName={"checked"}
                        initialValue={true}>
                        <Checkbox data-test-id={"sendEmail"}
                                  onChange={(e) => {
                                      setRecipientRequired(e.target.checked);
                                      setTimeout(form.validateFields, 50);
                                      setTimeout(forceUpdate, 200);
                                  }}
                        >
                            {intlMessage("package.label.send-email-notification")}

                            <Tooltip title={intlMessage("package.label.send-email-notification-tooltip")} className={styles['tooltip']}>
                                <QuestionCircleOutlined/>
                            </Tooltip>
                        </Checkbox>
                    </Form.Item>

                    <Form.Item label={intlMessage("common.note")} name={"note"} className={"padding-bottom-12px"}>
                        <TextArea name="note" autoSize={{minRows: 1, maxRows: 6}} autoComplete={"off"} maxLength={10000}/>
                    </Form.Item>


                    <div className="ant-row ant-form-item checkbox">
                        <div className="ant-col-24">
                            <Checkbox checked={switchPassword} onChange={(e) => {

                                setSwitchPassword(e.target.checked);

                                if (!e.target.checked) {
                                    form.resetFields(["requestPassword"]);
                                } else {
                                    if (!form.getFieldValue("requestPassword")) {
                                        form.setFields([{
                                            name: "requestPassword",
                                            value: null,
                                            errors: [new Error(intlMessage("required.password")).message],
                                        }]);
                                    }
                                }
                            }}>{intlMessage("package.label.package-request-password")}</Checkbox>
                        </div>

                        {
                            switchPassword &&
                            <>
                                <Form.Item
                                    name={"requestPassword"}
                                    className={'download-password'}
                                    rules={[
                                        {min: appContext.applicationConfig?.packagePasswordRequirements.minLength, message: intlMessage("validation.password-too-short", {length: appContext.applicationConfig?.packagePasswordRequirements.minLength})},
                                        {required: switchPassword, message: intlMessage("required.password")},
                                        {validator: serverViolationsHolder.createServerValidator('CUSTOM')}
                                    ]}>

                                    <Input.Password autoComplete={"off"} maxLength={30} ref={requestPasswordRef}
                                                    name={"requestPassword"}
                                                    onChange={e => {
                                                        setTimeout(forceUpdate, 100)
                                                    }}
                                                    addonAfter={
                                                        <Button type="text" icon={<SyncOutlined/>} title={intlMessage("generate-password")} onClick={generateRandomPassword}/>
                                                    }
                                                    style={{paddingBottom: 12}}/>

                                </Form.Item>
                            </>
                        }
                    </div>

                    <Form.Item style={{textAlign: "right", paddingTop: "10px"}}>
                        <Button type="primary" size="large" htmlType="submit" id={""}
                                loading={uploading}
                                disabled={hasErrors(getFieldsError()) || uploading || !isFieldsTouched()}>
                            {recipientRequired ? intlMessage("package.send_package_request") : intlMessage("package.create_package_request")}
                        </Button>
                    </Form.Item>
                </Form>
            </>
        );
    }

    function generateRandomPassword() {

        passwordService.generateRandomPassword().then(value => {
            form.setFieldsValue({requestPassword: value});

            // heslo jde kopirovat jen pokud jsme na ssl nebo localhost
            if (window.isSecureContext) {
                navigator.clipboard.writeText(value);
                message.info(intlMessage("password-copied"), 5);
            }
        });

    }

    function filterRecipientEmailOption(inputValue: any, option: any) {
        const key: string = option.key;
        let displayItem = false;

        if (key && key.toLowerCase().includes(inputValue.toLowerCase())) {
            displayItem = true;
        }

        if (displayItem) {
            return !selectedRecipients.find(value => {
                return value.trim() === key.trim();
            });
        } else {
            return false;
        }
    }

    function fetchAddressbook(value: any) {

        if (value && value.length > 0) {
            setRecipientEmailAddressBookVisible(true);
        } else {
            setRecipientEmailAddressBookVisible(false);
        }
    }

    function handleChangeRecipientEmail(value: string[]) {

        const tmp = FormatUtils.normalizeRecipientsFromSelect(value, value, null);

        setRecipientEmailAddressBookVisible(false);
        setSelectedRecipients(tmp ? tmp : []);

        // opozdena validace, jinak to nezafunguje dobre
        setTimeout(form.validateFields, 100, {force: true});
    }


    async function handleSubmit(values: Package) {

        setUploading(true);

        form.validateFields();

        if (!hasErrors(form.getFieldsError())) {
            values.passwordProtected = switchPassword;

            if (values.passwordProtected && !values.requestPassword) {
                form.setFields([{
                    name: "requestPassword",
                    value: null,
                    errors: [new Error(intlMessage("required.password")).message]
                }]);

                setUploading(false);
                return;
            }

            // prijemci jsou pole, tak vezmeme jen prvniho
            values.recipientEmails = values.recipientEmails ? values.recipientEmails![0] : undefined;

            packageService.createPackageRequest(values).then((res) => {
                packageId.current = res.id!;

                setFormState("DONE");
                setUploading(false);

            }, reason => {
                const violations = reason.response.data.constraintViolations;

                if (violations && violations.anonymousSenderEmail && violations.anonymousSenderEmail.EMAIL!) {
                    form.setFields([{
                        name: "recipientEmails",
                        value: form.getFieldValue("recipientEmails"),
                        errors: [new Error(intlMessage("validation.recipients-bad-format")).message]
                    }]);

                }

                if (violations && violations.requestPassword!) {
                    form.setFields([
                        {
                            name: "requestPassword",
                            value: form.getFieldValue("requestPassword"),
                            errors: [new Error(violations.requestPassword.CUSTOM.message).message]
                        }
                    ]);

                }

                // limity
                if (reason.response && reason.response.status === 403) {
                    message.error(intlMessage("action-limit.too-frequent", reason.response.data), 10);
                }

                setUploading(false);

                return reason;
            });
        } else {
            setUploading(false);
        }
    }

    function resetForm() {

        setSelectedRecipients([]);
        setFormState("REQUEST_FORM");

        form.setFieldsValue({
                name: undefined,
                note: undefined,
                recipientEmails: undefined,
                downloadPassword: undefined,
            }
        );


        form.validateFields();
    }

}

export default PackageRequest;
