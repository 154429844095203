/**
 * Vytvoří element "a", klikne na něj a zase smaže.
 *
 * @param baseURL base URL
 * @param queryParams URL parametry
 * @param payload dodatečná data odeslaná v těle formuláře (pokud je tento parametr přítomný, odešle se požadavek jako POST)
 */
export function downloadFileViaAnchorOrForm(baseURL: string, queryParams?: any, payload?: any) {
    let url = baseURL;

    if (queryParams !== undefined) {
        const params = new URLSearchParams();

        for (const key of Object.keys(queryParams)) {
            const value = queryParams[key];

            if (value !== undefined) {
                params.append(key, value);
            }
        }

        url += "?" + params.toString();
    }

    if (payload !== undefined) {
        const form = document.createElement('form');

        form.action = url;
        form.method = "POST";

        if (payload?.folderIds) {
            const input = document.createElement('input');

            input.setAttribute("name", "folderIds");
            input.setAttribute("type", "text");
            input.setAttribute("value", JSON.stringify(payload.folderIds));

            form.appendChild(input);
        }

        if (payload?.fileIds) {
            const input = document.createElement('input');

            input.setAttribute("name", "fileIds");
            input.setAttribute("type", "text");
            input.setAttribute("value", JSON.stringify(payload.fileIds));

            form.appendChild(input);
        }

        document.body.appendChild(form);

        form.submit();

        document.body.removeChild(form);
    } else {
        const link = document.createElement('a');

        link.href = url;

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
    }
}
