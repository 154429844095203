import {CalendarFilled} from '@ant-design/icons';
import {Button, Checkbox, Col, Collapse, DatePicker, Form, Input, Row} from "antd";
import {useForm} from "antd/lib/form/Form";
import * as _ from "lodash";
import moment from "moment";
import {useContext, useEffect, useState} from "react";
import {LocalStorageServiceContext} from "../Contexts";
import {PackageCheckState, PackageWorkflowState} from "../domain/Package";
import {useIntlMessage} from "../sal-ui/createIntlMessage";
import LocalStorageNamespace from "../service/LocalStorageNamespace";
import FilterParamsBuilder from "./common/filter/FilterParamsBuilder";
import {FormPersister} from "../sal-ui/FormPersister";

const {Panel} = Collapse;

function CooperativeSearchForm(props: any) {
    const intlMessage = useIntlMessage("packages-inbox-outbox");
    const localStorageService = useContext(LocalStorageServiceContext);
    const [form] = useForm();
    const filterFormPersister = new FormPersister("PackagesInbox");
    const [workflowStateList] = useState<PackageWorkflowState[]>([]);
    const [checkStateList] = useState<PackageCheckState[]>([]);
    const [searchFormVisibile, setSearchFormVisibile] = useState<any>();
    const [searchFormActive, setSearchFormActive] = useState<boolean>();

    useEffect(() => {
        filterFormPersister.loadState(form, handleSearch);

        const savedFormVisibility = localStorageService.getIndexedItem(LocalStorageNamespace.TableFilter, "package-search-form-visibility");

        if (savedFormVisibility != null) {
            setSearchFormVisibile(JSON.parse(savedFormVisibility));
        }
    }, []);

    return (
        <Row>
            <Col span={24}>
                <Collapse className={"packages-filter"} activeKey={searchFormVisibile} onChange={setFilterVisibility}>
                    <Panel header={intlMessage("search-form") + (searchFormActive ? " (" + intlMessage("search-form-active") + ")" : "")} key="packages">
                        <Form form={form} id={"search-form"} className="ant-advanced-search-form" onFinish={handleSearch} layout={"vertical"}>
                            <Row gutter={48}>
                                <Col span={16}>
                                    <Row gutter={48}>
                                        <Col span={12}>
                                            <Form.Item label={intlMessage("packages-inbox-outbox.search-form.id")} name={"id"}>
                                                <Input placeholder={intlMessage("packages-inbox-outbox.search-placeholder.id-or-name")} maxLength={100}/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label={intlMessage("packages-inbox-outbox.search-form.file")} name={"file"}>
                                                <Input placeholder={intlMessage("packages-inbox-outbox.search-placeholder.file-id-name-hash")} maxLength={100}/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={48}>
                                        <Col span={12}>
                                            <Form.Item label={intlMessage("packages-inbox-outbox.search-form.uploaded-from")} name={"timestampFrom"}>
                                                <DatePicker
                                                    style={{width: '100%'}}
                                                    showTime={{format: 'HH:mm', minuteStep: 5}}
                                                    format="YYYY-MM-DD HH:mm"
                                                    placeholder={intlMessage("packages-inbox-outbox.search-placeholder.uploaded-from")}
                                                    suffixIcon={<CalendarFilled/>}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label={intlMessage("packages-inbox-outbox.search-form.uploaded-to")} name={"timestampTo"}>
                                                <DatePicker
                                                    style={{width: '100%'}}
                                                    showTime={{format: 'HH:mm', minuteStep: 5}}
                                                    format="YYYY-MM-DD HH:mm"
                                                    placeholder={intlMessage("packages-inbox-outbox.search-placeholder.uploaded-to")}
                                                    suffixIcon={<CalendarFilled/>}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={48}>
                                        <Col span={12}>
                                            <Form.Item label={intlMessage("packages-inbox-outbox.search-form.recipient")} name={"recipient"}>
                                                <Input placeholder={intlMessage("packages-inbox-outbox.search-placeholder.recipient")} maxLength={100}/>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col span={24} style={{textAlign: 'left'}}>
                                            <Form.Item>
                                                <Button type="primary" htmlType="submit">{intlMessage("common.search")}</Button>
                                                <Button style={{marginLeft: 8}} onClick={handleClear}>{intlMessage("common.cancel")}</Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label={intlMessage("packages-inbox-outbox.search-form.state")} name={"checkState"} initialValue={checkStateList}>
                                        <Checkbox.Group>
                                            <Checkbox value={PackageCheckState.UNKNOWN}>{intlMessage("PackageCheckState.UNKNOWN")}</Checkbox>
                                            <Checkbox value={PackageCheckState.CLEAN}>{intlMessage("PackageCheckState.CLEAN")}</Checkbox>
                                            <Checkbox value={PackageCheckState.QUARANTINED}>{intlMessage("PackageCheckState.QUARANTINED")}</Checkbox>
                                        </Checkbox.Group>
                                    </Form.Item>
                                    <Form.Item className={"workflow-state no-padding-top"} name={"packageUserFlags"}>
                                        <Checkbox.Group>
                                            <Checkbox value={"flagged"}>{intlMessage("flagged")}</Checkbox>
                                        </Checkbox.Group>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Panel>
                </Collapse>
            </Col>
        </Row>
    );

    function setFilterVisibility(items: any) {
        setSearchFormVisibile(items);

        localStorageService.setIndexedItem(LocalStorageNamespace.TableFilter, "package-search-form-visibility", JSON.stringify(items));
    }

    function handleClear() {
        form.resetFields();

        filterFormPersister.resetState(form, handleSearch);
    }

    function handleSearch(formValues: any) {
        const filterParams = new FilterParamsBuilder()
            .in("workflowState")
            .in("checkState")
            .eq("id")
            .eq("file")
            .custom("sender")
            .ge("timestampFrom", "uploaded", value => moment(value))
            .le("timestampTo", "uploaded", value => moment(value))
            .custom("packageUserFlags")
            .build();

        const filters: string[] = [];

        const values = onBeforeHandleSearch(formValues);

        let formActive = false;
        Object.keys(formValues).forEach(key => {
            if ((!Array.isArray(formValues[key]) && formValues[key]) || (Array.isArray(formValues[key]) && formValues[key].length > 0)) {
                formActive = true;
            }
        });
        setSearchFormActive(formActive);

        if (props.quickSearch) {
            filters.push(`$quickSearch$ ~ '${props.quickSearch}'`)
        }

        Object.keys(filterParams).forEach(key => {
            const value = values[key];
            const filterParam = filterParams[key];

            if (filterParam === undefined) {
                return;
            }

            const filterExpression = filterParam.build(value);

            if (filterExpression !== "") {
                filters.push(filterExpression);
            }
        });

        filterFormPersister.saveState(formValues);

        props.onSearch(filters.join(" and "), formValues);

    }

    function onBeforeHandleSearch(values: any) {
        // if no "checkState" or "workFlowState" checkbox is checked, pretend that all checkboxes are checked
        if (_.isEmpty(values.checkState) && _.isEmpty(values.workflowState)) {
            const newValues = _.cloneDeep(values);

            newValues.checkState = [PackageCheckState.UNKNOWN, PackageCheckState.CLEAN, PackageCheckState.QUARANTINED];
            newValues.workflowState = [PackageWorkflowState.ACTIVE, PackageWorkflowState.REQUESTED];

            return newValues;
        }

        // if any "checkState" checkbox is checked, force ACTIVE workflow state
        if (!_.isEmpty(values.checkState) && !_.includes(values.workflowState, PackageWorkflowState.ACTIVE)) {
            const newValues = _.cloneDeep(values);

            if (!newValues.workflowState) {
                newValues.workflowState = [];
            }

            newValues.workflowState.push(PackageWorkflowState.ACTIVE);

            return newValues;
        }

        return values;
    }
}

export default CooperativeSearchForm;