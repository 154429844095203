import {DeleteFilled, EditFilled, PlusOutlined, ReloadOutlined} from '@ant-design/icons';
import {Alert, Button, message, Table} from "antd";
import Column from "antd/lib/table/Column";
import {MouseEvent, useContext, useEffect, useState} from "react";
import {AppContextContext, MultiFactorKeyServiceContext} from "../Contexts";
import {MultiFactorKey} from "../domain/MultiFactorKey";
import {useIntlMessage} from "../sal-ui/createIntlMessage";
import {useTableHandler} from "../sal-ui/TableHandler";
import ModalOperation from "./common/ModalOperation";
import {DocumentTitle} from "./DocumentTitle";
import MultiFactorKeyModal from "./MultiFactorKeyModal";
import OkLeftPopconfirm from "./OkLeftPopconfirm";
import PasswordConfirmModal from "./PasswordConfirmModal";

function MultiFactorKeyList() {
    const appContext = useContext(AppContextContext);
    const multiFactorKeyService = useContext(MultiFactorKeyServiceContext);
    const applicationConfig = appContext.applicationConfig;
    const intlMessage = useIntlMessage("multi-factor-key-list");
    const tableHandler = useTableHandler("name asc", {reloadFunction: reload, persistentIdent: "MultiFactorKeyList"});
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<MultiFactorKey[]>([]);
    const [modal, setModal] = useState<any>({visible: false, title: "", operation: ModalOperation.Add, model: undefined});
    const [confirmModal, setConfirmModal] = useState<any>({visible: false, title: "", operation: ModalOperation.Add, model: undefined});

    useEffect(() => {
    }, []);

    return (
        <DocumentTitle
            title={`${applicationConfig!.title}: ${intlMessage('title')}`}>
            <div className={"table"}>
                <>
                    <h1>{intlMessage('title')}</h1>

                    {appContext.user?.mfaEnforceRequirement(applicationConfig!) &&
                        <Alert message={intlMessage('mfa-required')} type={"warning"} style={{maxWidth: 600, marginBottom: '1.5em'}}/>
                    }

                    {
                        data.length === 1 &&

                        <Alert message={intlMessage('only-one-key-warning')} type={"warning"} style={{maxWidth: 600, marginBottom: '1.5em'}}/>
                    }

                    <div className={"actions"}>
                        <Button type="text" icon={<ReloadOutlined/>} onClick={reload}/>

                        <Button type="primary" icon={<PlusOutlined/>}
                                onClick={addItem}
                                title={intlMessage("multi-factor-key-add.title")}>
                            {intlMessage("common.add")}
                        </Button>
                    </div>
                </>

                <Table dataSource={data} size="middle" showSorterTooltip={false} style={{maxWidth: 600}}
                       onChange={tableHandler.onTableChange} pagination={tableHandler.pagination} rowKey="id"
                       onRow={onRow} loading={loading}>
                    <Column dataIndex="name" title={intlMessage("multi-factor-key.name")}/>
                    <Column dataIndex="type" title={intlMessage("multi-factor-key.type")}/>

                    <Column title={intlMessage("common.action")} width='100px' render={renderAction} className={"table-actions"}/>
                </Table>

                {
                    modal.visible &&
                    <MultiFactorKeyModal visible={modal.visible} title={modal.title}
                                         editMode={modal.operation === ModalOperation.Update} multiFactorKey={modal.model}
                                         onOk={onModalSave} onCancel={() => setModal({visible: false})}/>
                }

                {
                    confirmModal.visible &&
                    <PasswordConfirmModal visible={confirmModal.visible}
                                          title={confirmModal.title}
                                          editMode={false}
                                          entity={confirmModal.model}
                                          okText={intlMessage("common.yes")}
                                          cancelText={intlMessage("common.no")}
                                          onOk={onModalSave}
                                          onCancel={() => setConfirmModal({visible: false})}
                                          onConfirm={onDeleteConfirm}
                    />
                }

            </div>
        </DocumentTitle>
    );

    function onModalSave() {
        resetModalState();

        reload();
    }

    function resetModalState() {
        setModal({
            visible: false,
            title: "",
            model: undefined,
            operation: ModalOperation.Add
        });
    }

    function renderAction(text: any, record: MultiFactorKey) {
        return (
            <>
                <Button type="default" onClick={(e: any) => onEdit(record, e)}
                        title={intlMessage("common.edit")} className={"ant-btn-icon-only"}>
                    <EditFilled/>
                </Button>

                {appContext.user?.adfsUser ?
                    <OkLeftPopconfirm
                        title={intlMessage("confirm-delete", {name: record.name})}
                        onConfirm={() => onDeleteConfirm(record, "adfs_user")}
                        okText={intlMessage("common.yes")}
                        cancelText={intlMessage("common.no")}>

                        <Button className={"ant-btn-icon-only"}>
                            <DeleteFilled/>
                        </Button>

                    </OkLeftPopconfirm>

                    :

                    <Button className={"ant-btn-icon-only"} onClick={() => deleteConfirm(record)}>
                        <DeleteFilled/>
                    </Button>
                }

            </>
        );
    }

    function deleteConfirm(record: any) {
        setConfirmModal({visible: true, title: intlMessage("confirm-delete", {name: record.name}), operation: ModalOperation.Add, model: record});
    }

    function onDeleteConfirm(record: MultiFactorKey, currentPassword: string): Promise<any> {

        return multiFactorKeyService.delete(record, currentPassword)
            .then(() => {
                message.success(intlMessage('deleted', {name: record.name}))
                reload();
                setConfirmModal({visible: false});
            }).catch(reason => {


                // moc pokusu
                if (reason.response.status === 403) {
                    message.error(intlMessage("action-limit.too-frequent", reason.response.data));
                }

                return Promise.reject(reason);
            });

    }

    function onRow(record: MultiFactorKey) {
        return {
            onDoubleClick: () => {
                onEdit(record);
            },
        };
    }

    function onEdit(record: MultiFactorKey, e?: MouseEvent) {
        if (e) {
            e.preventDefault();
        }

        setModal((prevState: any) => {
            return (
                {
                    ...prevState,
                    visible: true,
                    title: intlMessage("multi-factor-key-edit.title", {name: record.name}),
                    operation: ModalOperation.Update,
                    model: record
                }
            )
        });
    }

    function addItem() {
        setModal((prevState: any) => {
            return (
                {
                    ...prevState,
                    visible: true,
                    title: intlMessage("multi-factor-key-add.title"),
                    operation: ModalOperation.Add,
                    model: undefined
                }
            )
        });
    }

    function reload() {
        setLoading(true);

        return multiFactorKeyService.getList(tableHandler.queryOptions)
            .then(value => {
                // kdyz nebyl vicefaktor zapnuty ale najednou pribyl autentikator, tak prenacteme stranku, aby se vse obnovilo
                if (value.total > 0 && !appContext.user?.twoFactorAuth) {
                    window.location.reload();
                }

                tableHandler.updateTotal(value.total);
                setData(value.data);
            })
            .finally(() => setLoading(false));
    }

}

export default MultiFactorKeyList;
