import {File} from "./File";
import {FileOrFolderAction} from "./FileOrFolderAction";

export class Folder {

    constructor(
        public id: string,
        public name: string,
        public path: string,
        public size: number,
        public created: string,
        public deleted: string | undefined,
        public folders: Folder[],
        public files: File[],
        public parent?: Folder,
        public possibleActions?: FileOrFolderAction[]) {
        //
    }

    public static fromPOJO(data: any): Folder {
        return new Folder(
            data.id,
            data.name,
            data.path,
            data.size,
            data.created,
            data.deleted,
            data.folders?.map((value: any) => Folder.fromPOJO(value)) || [],
            data.files?.map((value: any) => File.fromPOJO(value)) || [],
            (data.parent) ? Folder.fromPOJO(data.parent) : undefined,
            data.possibleActions
        );
    }

}