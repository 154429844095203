import {IntlMessageFormatter} from "../sal-ui/createIntlMessage";

export enum NameConflictResolution {
    REPLACE = "REPLACE",
    RENAME = "RENAME",
    DENY = "DENY"
}

export function formatNameConflictResolution(formatter: IntlMessageFormatter, conflictResolution: NameConflictResolution) {
    switch (conflictResolution) {
        case NameConflictResolution.REPLACE:
            return formatter('name-conflict-resolution.replace');
        case NameConflictResolution.RENAME:
            return formatter('name-conflict-resolution.rename');
        case NameConflictResolution.DENY:
            return formatter('name-conflict-resolution.deny');
    }
}
