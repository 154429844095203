import {Modal} from "antd";
import {FormInstance} from "antd/lib/form";
import React, {ForwardedRef, forwardRef, useImperativeHandle, useState} from "react";
import {ServerConstraintViolationsHolder} from "./ServerConstraintViolations";
import {useIntlMessage} from "./createIntlMessage";

export const defaultLayout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};

export interface FormModalProps {
    title?: string;
    okText?: string;
    cancelText?: string;
    editMode?: boolean;
    visible: boolean;
    onOk?: () => void;
    onCancel?: () => void;
}

interface FormModalPropsImpl extends FormModalProps {
    addItem?: (values: any) => Promise<void>;
    updateItem?: (values: any) => Promise<void>;
    form: FormInstance;
    violationsHolder?: ServerConstraintViolationsHolder;
    width?: number;
    afterClose?: () => void;
    maskCloseable?: boolean;
}

export interface FormModalDelegate {
    onSubmit: () => void;
}

/**
 * Pomocná komponenta pro modalní dialogy s formulářem. Automaticky zajišťuje nastavení atributu "confirmLoading" na dialogu.
 */
export const FormModal = forwardRef<FormModalDelegate, React.PropsWithChildren<FormModalPropsImpl>>(function (props: React.PropsWithChildren<FormModalPropsImpl>, ref: ForwardedRef<FormModalDelegate>) {
    const intlMessage = useIntlMessage('form-modal');
    const [inProgress, setInProgress] = useState(false);

    const {form, editMode, visible, addItem, updateItem} = props;

    useImperativeHandle(ref, () => ({
        onSubmit
    }));

    return (
        <Modal title={props.title}
               okText={props.okText || intlMessage('common.save')}
               cancelText={props.cancelText || intlMessage('common.cancel')}
               onOk={onSubmit}
               onCancel={props.onCancel}
               open={visible}
               width={props.width}
               destroyOnClose={true}
               maskClosable={props.maskCloseable}
               afterClose={props.afterClose}
               confirmLoading={inProgress}>

            {props.children}

        </Modal>
    );

    function onSubmit() {
        setInProgress(true);

        form.validateFields().then(
            values => {
                if (editMode && updateItem) {
                    return updateItem(values).catch(validationErrorHandler)
                } else if (addItem) {
                    return addItem(values).catch(validationErrorHandler);
                }
            },
            () => {
                //
            })
            .finally(() => setInProgress(false))
    }

    function validationErrorHandler(error: any) {
        if (!props.violationsHolder) {
            return Promise.reject(error);
        }

        if (error && error.response && error.response.data && props.violationsHolder.isConstraintViolations(error.response.data)) {
            props.violationsHolder.violations = error.response.data;

            props.form.validateFields();
        }

        return Promise.reject();
    }

});
