import {Button, Col, Form, Input, message, Modal, Row, Select} from "antd";
import {useForm} from "antd/lib/form/Form";
import * as React from "react";
import {useContext, useEffect, useReducer, useState} from "react";
import {AppContextContext, MultiFactorKeyServiceContext} from "../Contexts";
import {MultiFactorKey} from "../domain/MultiFactorKey";
import {useIntlMessage} from "../sal-ui/createIntlMessage";
import {FormModalProps} from "../sal-ui/FormModal";
import {ServerConstraintViolationsHolder} from "../sal-ui/ServerConstraintViolations";
import ValidationUtils from "../service/common/ValidationUtils";
import ServerViolations from "./ServerViolations";

interface IProps extends FormModalProps {
    multiFactorKey?: MultiFactorKey
}

const serverViolationsHolder = new ServerConstraintViolationsHolder();

function hasErrors(fieldsError: any) {
    return Object.keys(fieldsError).some(field => {
        return fieldsError[field].errors.length > 0;
    });
}

function MultiFactorKeyModal(props: IProps) {

    const [form] = useForm();
    const intlMessage = useIntlMessage('multi-factor-key');
    const appContext = useContext(AppContextContext);
    const multiFactorKeyService = useContext(MultiFactorKeyServiceContext);

    const [confirmMode, setConfirmMode] = useState<boolean>();
    const [qrCode, setQrCode] = useState<string>();
    const [secret, setSecret] = useState<string>();
    const [authenticatorId, setAuthenticatorId] = useState<string>();
    const [type, setType] = useState<string>();
    const [selectedType, setSelectedType] = useState<string>();
    const [inProgress, setInProgress] = useState<boolean>()
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

    useEffect(() => {
        setConfirmMode(false);


        window.addEventListener("beforeunload", unloadEventListener);

        return () => {
            window.removeEventListener("beforeunload", unloadEventListener);
        }
    }, [])

    function unloadEventListener(ev: BeforeUnloadEvent) {
        ev.preventDefault();

        if (authenticatorId && type === "TOTP") {
            handleCancelTotpActivation();

        }
        window.removeEventListener("beforeunload", unloadEventListener);

    }

    const {visible, title, multiFactorKey} = props;

    let serverViolations = serverViolationsHolder.violations.constraintViolations;

    return (
        <Modal destroyOnClose={true} open={visible} title={title} footer={renderFooter()} maskClosable={false} onCancel={handleCancel} width={700} style={{top: 50}}>

            <ServerViolations constraintViolations={serverViolations}/>

            <Form form={form} layout={"vertical"}>
                <Form.Item name={'name'} label={intlMessage("multi-factor-key.name")}
                           initialValue={(multiFactorKey) ? multiFactorKey.name : undefined}
                           rules={[
                               {
                                   required: true,
                                   message: intlMessage("required.name")
                               }, {
                                   validator: ValidationUtils.createServerValidator(serverViolationsHolder, 'UNIQUE'),
                                   message: intlMessage("validation.name-not-unique")
                               }
                           ]}>
                    <Input autoComplete={"off"} maxLength={50} onChange={e => setTimeout(forceUpdate, 100)}/>
                </Form.Item>

                {
                    !props.editMode &&

                    <React.Fragment>
                        <Form.Item name={"type"} label={intlMessage("multi-factor-key.type")}
                                   rules={[{required: true, message: intlMessage("validation.multi-factor-key-type-required")}]}>
                            <Select style={{width: 120}} disabled={confirmMode} onChange={(value: any) => setSelectedType(value)}>
                                <Select.Option value="FIDO2">FIDO2</Select.Option>
                                <Select.Option value="TOTP">TOTP</Select.Option>
                            </Select>
                        </Form.Item>

                        {renderTypeSpecificElements()}
                    </React.Fragment>
                }
            </Form>
        </Modal>
    );

    function handleCancel() {

        if (type === "TOTP") {
            handleCancelTotpActivation();
        }

        serverViolationsHolder.clearViolations();
        props.onCancel!();
    }

    function handleSubmit(event: any) {

        form.validateFields().then(value => {
            if (!props.editMode) {
                progressStarted();
                addItem(form.getFieldsValue())
                    .catch(validationErrorHandler)
                    .finally(() => progressFinished());

            } else if (props.editMode) {
                progressStarted();
                updateItem(form.getFieldsValue())
                    .catch(validationErrorHandler)
                    .finally(() => progressFinished());
            }
        }, reason => {
            console.log(reason);
            return reason;
        });
    }

    function validationErrorHandler(error: any) {
        if (error && error.response && error.response.data && ValidationUtils.isConstraintViolations(error.response.data)) {
            serverViolationsHolder.violations = error.response.data;

            form.validateFields();
        }

        return Promise.reject();
    }

    function progressStarted() {
        setInProgress(true);

    }

    function progressFinished() {
        setInProgress(false);
    }

    function addItem(values: any): Promise<any> {
        if (confirmMode) {
            props.onOk!();

            return Promise.resolve();
        }

        switch (values.type) {
            case 'FIDO2':
                if (appContext.user?.adfsUser) {
                    values.currentPassword = "adfs_user";
                }

                return multiFactorKeyService!.startFidoRegistration(values.currentPassword)
                    .then(creationOptions => {
                        return navigator.credentials
                            .create({publicKey: creationOptions})
                            .then(value => {
                                return multiFactorKeyService!.finishFidoRegistration(values.name, value as PublicKeyCredential, values.currentPassword)
                            })
                            .then(() => {
                                message.success(intlMessage("multi-factor-key-add.added", {name: values.name}));

                                props.onOk!();
                            })
                            .catch(reason => {
                                message.error(intlMessage("multi-factor-key-add.error"));

                                return Promise.reject(reason);
                            })
                    });
            case 'TOTP':
                return multiFactorKeyService!.createTotp(values.name)
                    .then(response => {
                        setConfirmMode(true);
                        setQrCode(response.qrCode);
                        setSecret(response.secret);
                        setAuthenticatorId(response.id);
                        setType("TOTP");
                    });
            default:
                return Promise.reject('Unsupported multi-factor authentication type');
        }
    }

    function updateItem(values: any): Promise<any> {
        return multiFactorKeyService!.update(new MultiFactorKey(
            props.multiFactorKey!.id,
            props.multiFactorKey!.type,
            values.name))
            .then(() => {
                message.success(intlMessage("multi-factor-key-edit.updated", {name: values.name}));

                props.onOk!();
            }, reason => {
                const violations = reason.response.data.constraintViolations;

                if (violations.uq_multi_factor_key_name_user && violations.uq_multi_factor_key_name_user.UNIQUE!) {
                    form.setFields([{

                        name: "name",
                        value: form.getFieldValue("name"),
                        errors: [new Error(intlMessage("validation.name-not-unique")).message]

                    }]);
                    return reason;
                }
            });
    }

    function handleCancelTotpActivation() {
        return multiFactorKeyService!.cancelActivationTotp(authenticatorId!)
            .then(() => {
                    setConfirmMode(false);
                    setQrCode(undefined);
                    setSecret(undefined);
                    setAuthenticatorId(undefined);
                    setType(undefined);
                    form.resetFields();
                    serverViolationsHolder.clearViolations();
                    props.onOk!();
                }
            )
    }

    function handleActivate() {
        const values = form.getFieldsValue();

        // adfs user nemusi zadavat heslo
        if (appContext.user?.adfsUser) {
            values.currentPassword = "adfs_user";
        }

        return multiFactorKeyService!.activateTotp(new MultiFactorKey(authenticatorId), values.otpCode, values.currentPassword)
            .then(() => {
                message.success(intlMessage("multi-factor-key.added", {name: values.name}));

                props.onOk!();
            }, reason => {
                validationErrorHandler(reason);

                if (reason.response.data === "multi-factor-key.bad_otp_code") {
                    form.setFields([{
                        name: "otpCode",
                        value: form.getFieldValue("otpCode"),
                        errors: [new Error(intlMessage("validation.bad_otp-code")).message]
                    }]);
                }

                return reason;
            })

    }


    function renderTypeSpecificElements() {
        const type = form.getFieldValue('type');

        if (!type) {
            return "";
        }

        switch (type) {
            case 'FIDO2':
                return renderFido2();
            case 'TOTP':
                return renderTotp();
            default:
                return "";
        }
    }

    function renderFooter() {
        let submitText: string;

        if (confirmMode) {
            submitText = "common.close";
        } else {
            submitText = (!props.editMode)
                ? "common.create"
                : "common.save";
        }

        return (
            <div>
                {
                    !confirmMode &&

                    <Button loading={inProgress} onClick={handleCancel}>
                        {intlMessage("common.cancel")}
                    </Button>
                }

                {
                    type === 'TOTP' &&

                    <>
                        <Button
                            loading={inProgress}
                            onClick={handleCancelTotpActivation}>
                            {intlMessage("common.cancel")}
                        </Button>

                        <Button
                            loading={inProgress}
                            type={'primary'}
                            onClick={handleActivate}>
                            {intlMessage("common.verify_otp_code")}
                        </Button>
                    </>
                }
                {
                    type !== 'TOTP' &&

                    <Button
                        disabled={(form.getFieldValue('type') === 'FIDO2' && !navigator.credentials) || hasErrors(form.getFieldsError())}
                        loading={inProgress}
                        type={'primary'}
                        onClick={handleSubmit}>
                        {intlMessage(submitText)}
                    </Button>
                }
            </div>
        );
    }

    function renderFido2() {

        return (
            <Row style={{marginTop: 16}}>
                <Col>
                    {
                        !navigator.credentials &&
                        <p>
                            {intlMessage("multi-factor-key.not-supported.fido2")}
                        </p>
                    }

                    {
                        navigator.credentials &&
                        <React.Fragment>
                            <p>{intlMessage("multi-factor-key.description.fido2-1")}</p>

                            <p>{intlMessage("multi-factor-key.description.fido2-2")}</p>
                        </React.Fragment>
                    }

                    {!appContext.user?.adfsUser &&
                        <Form.Item name={"currentPassword"} label={intlMessage("common.current-password")}
                                   rules={[
                                       {required: true, message: intlMessage("required.password")},
                                       {validator: serverViolationsHolder.createServerValidator('CUSTOM')},
                                   ]}>
                            <Input.Password maxLength={30} onChange={e => setTimeout(forceUpdate, 100)}/>
                        </Form.Item>
                    }
                </Col>
            </Row>
        )
    }

    function renderTotp() {

        const style = {
            display: 'inline-block',
            background: `url('data:image/png;base64,${qrCode}') no-repeat center center`,
            width: '100%',
            height: 220
        };

        return (
            <Row style={{marginTop: 16}}>
                <Col>
                    <p>{intlMessage("multi-factor-key.description.totp-1")}</p>

                    <p>{intlMessage("multi-factor-key.description.totp-2")}</p>

                    {
                        qrCode &&

                        <React.Fragment>
                            <p>
                                <b>{intlMessage("multi-factor-key.totp.secret")}:</b> {secret}
                            </p>

                            <div style={{textAlign: "center"}}>
                                <div style={style}/>
                            </div>

                            <Form.Item name={"otpCode"} label={intlMessage("multi-factor-key.otpCode")}
                                       rules={[
                                           {
                                               required: true, message: intlMessage("validation.multi-factor-key-otp-code-required")
                                           }, {
                                               validator: ValidationUtils.createServerValidator(serverViolationsHolder, 'BAD_OTP_CODE'),
                                               message: intlMessage("validation.bad_otp-code")
                                           }
                                       ]}>
                                <Input autoComplete={"off"} maxLength={50}/>
                            </Form.Item>

                            {!appContext.user?.adfsUser &&
                                <Form.Item name={"currentPassword"} label={intlMessage("common.current-password")}
                                           rules={[
                                               {required: true, message: intlMessage("required.password")},
                                               {validator: serverViolationsHolder.createServerValidator('CUSTOM')},
                                           ]}>
                                    <Input.Password maxLength={30} onChange={() => form.getFieldValue("password") && form.validateFields()}/>
                                </Form.Item>
                            }

                        </React.Fragment>
                    }
                </Col>
            </Row>
        )
    }

}

export default MultiFactorKeyModal;
