import Icon, {CloseOutlined, LinkOutlined, PaperClipOutlined} from '@ant-design/icons';
import {Alert, Button, Card, Col, Form, Input, message, Row, Upload,} from 'antd';
import {useForm} from "antd/lib/form/Form";
import {UploadFile} from "antd/lib/upload/interface";
import PromisePool from "es6-promise-pool";
import {useContext, useEffect, useState} from "react";
import CopyToClipboard from 'react-copy-to-clipboard';
import {Redirect, useHistory, useLocation, useParams} from "react-router";
import {AppContextContext, FolderServiceContext, LocalStorageServiceContext, PackageFileServiceContext, PackageServiceContext} from "../Contexts";
import {Package, PackageCheckState, PackageFile, PackageWorkflowState} from "../domain/Package";
import {UserPermission} from "../domain/User";
import {useIntlMessage} from "../sal-ui/createIntlMessage";
import {ServerConstraintViolationsHolder} from "../service/common/ServerConstraintViolations";
import LocalStorageNamespace from "../service/LocalStorageNamespace";
import FormatUtils from "../utils/FormatUtils";
import {IconCloudUpload} from "./common/CustomIcons";
import {DocumentTitle} from "./DocumentTitle";
import {routesMap} from "./Routes";
import UploadProgress from "./UploadProgress";
import styles from "./PackageAddFiles.module.css";
import {Folder} from "../domain/Folder";

const Dragger = Upload.Dragger;

interface IItemForUpload {
    packageId: string,
    fileId: string,
    file: PackageUploadFile,
    config: { onUploadProgress: () => void, cancelToken: () => void, source: any }
}

interface PackageUploadFile extends UploadFile {
    uploading?: boolean;
    uid: string;
}

function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}


function hasErrors(fieldsError: any) {
    return Object.keys(fieldsError).some(field => {
        return fieldsError[field].errors.length > 0;
    });
}

let autoReloadId: any = null;

function PackageAddFiles() {
    const forceUpdate = useForceUpdate();

    const appContext = useContext(AppContextContext);
    const applicationConfig = appContext.applicationConfig;
    const packageService = useContext(PackageServiceContext);
    const packageFileService = useContext(PackageFileServiceContext);
    const localStorageService = useContext(LocalStorageServiceContext);
    const folderService = useContext(FolderServiceContext);
    const location = useLocation();
    const {packageId, folderId}: any = useParams();
    const intlMessage = useIntlMessage("packages-inbox-outbox");
    const navigate = useHistory();

    const [form] = useForm();

    let layout: any;
    let uploadFormLayout: any;
    let showValidationErrors = false;
    let addFileTimer: any = undefined;
    let addingFileTimer: any = undefined;

    let fileUploadSettings = {
        multiple: true,
        action: "upload",
        beforeUpload: ((file: any) => beforeUpload(file)),
        onRemove: ((file: any) => onRemove(file)),
        accept: "",
    };

    const serverViolationsHolder: ServerConstraintViolationsHolder = new ServerConstraintViolationsHolder();

    let canceldByUser: boolean = false;

    const [uploadState, setUploadState] = useState<"UPLOAD_FORM" | "UPLOADING" | "DONE" | "FAILED">("UPLOAD_FORM");
    const [submitAllowed, setSubmitAllowed] = useState(false);
    const [uploading, setUploading] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);
    const [byteProgress, setByteProgress] = useState<number>(0);
    const [fileProgress, setFileProgress] = useState<number>(0);
    const [notice, setNotice] = useState<string>("");
    const [folder, setFolder] = useState<Folder>();
    const [serverErrorMessage, setServerErrorMessage] = useState<string>("");

    const [totalBytes, setTotalBytes] = useState(0);
    const [totalFiles, setTotalFiles] = useState(0);
    const [uploadedBytes, setUploadedBytes] = useState(0);
    const [uploadedFiles, setUploadedFiles] = useState(0);

    const [uploadFileList, setUploadFileList] = useState<PackageUploadFile[]>([]);
    const [componentUnmounting, setComponentUnmounting] = useState<boolean>(false);
    const [aPackage, setAPackage] = useState<Package | undefined>({
        workflowState: PackageWorkflowState.ACTIVE,
        checkState: PackageCheckState.UNKNOWN,
        internalRecipients: [],
        externalRecipients: []
    });
    const [filesUploadProgress, setFilesUploadProgress] = useState<any>({});

    /**
     * Array of files - used as source for UploadProduder
     */
    let dataForUpload: IItemForUpload[] = [];
    let uploadingData: IItemForUpload[] = [];
    let filesUploadProgressBytes: any = [];

    useEffect(() => {
        loadPackage(packageId);

        folderService.get(folderId).then(setFolder);

        return () => {
            setComponentUnmounting(true);

            if (uploadState === "UPLOADING") {

                cancelAllUploads();
                if (packageId) {
                    packageService.cancel(packageId);
                }
            }

            localStorageService.removeIndexedItem(LocalStorageNamespace.PackageDetail, packageId!);
        }
    }, [packageId, folderId]);

    uploadFormLayout = {xs: 24, sm: 24, md: 12, lg: 12, xl: 12, xxl: 12};
    layout = {xs: 12, sm: 12, md: 16, lg: 20, xl: 16, xxl: 12};

    let content: any;
    let h1: any = "";
    let h3: any = "";
    switch (uploadState) {
        case "UPLOAD_FORM":
            content = renderUploadForm();
            if (appContext.user) {
                h1 = "package.add-files-to-package";
                h3 = "";
            }
            break;
        case "UPLOADING":
            content = renderUploading();
            h1 = "package.uploading_files_to_package";
            h3 = "package.uploading_files_to_package_note";
            break;
        case "DONE":
            content = renderUploadDone();
            h1 = "package.files_upload_done";
            h3 = "package.files_upload_done_note";
            break;
        case "FAILED":
            content = renderUploadFailed();
            h1 = "package.files_upload_failed";
            h3 = "package.files_upload_failed_note";
            break;
        default:
            content = "";
    }

    switch (uploadState) {
        case "UPLOAD_FORM":
        case "UPLOADING":
        case "FAILED":
        case "DONE":
            h1 = intlMessage(h1, {id: (aPackage!.name ? aPackage!.name : packageId), folderName: renderFolderName(folder)});
            h3 = h3 ? intlMessage(h3) : "";
            break;
        default:
            break;
    }

    return (
        <DocumentTitle
            title={`${applicationConfig!.title}: ${intlMessage("package.send_package")}`}>
            <>
                <div className={!appContext.user ? "upload-package-title" : ""}>
                    <h1>{h1}</h1>
                    <h3>{h3}</h3>
                </div>

                <Row gutter={16} justify={!appContext.user ? "space-around" : undefined}
                     align={"middle"}>
                    <Col {...layout}>
                        <Row>
                            <Col span={24}>
                                {notice &&
                                    <Alert style={{marginBottom: 24}} message={notice} type="error"
                                           showIcon={true}/>
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {content}

            </>
        </DocumentTitle>
    );

    function updateFilesUploadProgress(uid: string, progress: number) {
        setFilesUploadProgress((oldValue: any) => {
            const tmp = {...oldValue};
            tmp[uid] = progress;
            return tmp;
        });

    }

    function renderUploading() {

        return <Row gutter={16} justify={!appContext.user ? "space-around" : undefined}
                    align={"middle"}>
            <Col {...layout}>
                <h2>{intlMessage("package.total")}</h2>

                <UploadProgress key={"allBytes"} name={intlMessage("package.uploaded")}
                                info={FormatUtils.formatBytes(uploadedBytes) + " z " + FormatUtils.formatBytes(totalBytes)}
                                value={totalFiles === uploadedFiles ? 100 : byteProgress ? byteProgress : 0}/>

                <UploadProgress key={"allFiles"}
                                name={intlMessage("package.files", {files: uploadedFiles})}
                                info={uploadedFiles + "/" + totalFiles}
                                value={Math.round((uploadedFiles * 100) / totalFiles)}/>
                <h2>{intlMessage("package.uploading-files")}</h2>

                <div style={{height: "100px"}}>
                    {uploadFileList.map(item => {

                        if (item.uploading) {
                            return (<UploadProgress key={item.uid} name={item.name} value={filesUploadProgress[item.uid]}/>)
                        } else {
                            return ""
                        }

                    })}
                </div>

                <Button type={"default"}
                        size={"large"}
                        className={"btn-upload btn-upload-storno"}
                        onClick={handleUploadStorno}>
                    {intlMessage("package.btn-upload_storno")}
                </Button>
            </Col>
        </Row>
    }

    function renderUploadDone() {

        // todo
        const downloadUrl = (folder?.name === '/') ? `${applicationConfig!.baseDownloadUri}/packages/${packageId}` : `${applicationConfig!.baseDownloadUri}/packages/${packageId}/${folderId}`;

        return (
            <Row gutter={16} justify={!appContext.user ? "space-around" : undefined} align={"middle"}>
                <Col {...layout}>
                    <Row>
                        <Col>
                            <div style={{fontSize: "16px", paddingBottom: "20px"}}>

                                <Card style={{fontSize: "16px"}}>
                                    {appContext.user
                                        ? <>
                                            <a href={downloadUrl}>{downloadUrl}</a>
                                            &nbsp;
                                            <CopyToClipboard text={downloadUrl} onCopy={() => message.info(intlMessage("package-detail.link-copied"))}>
                                                <a title={intlMessage("package-detail.copy-link-title")} className={"copy-link"}><LinkOutlined/></a>
                                            </CopyToClipboard>
                                        </>
                                        : <>
                                            <CopyToClipboard text={downloadUrl} onCopy={() => message.info(intlMessage("package-detail.link-copied"))}>
                                                <a title={intlMessage("package-detail.copy-link-title")} className={"copy-link"}>{downloadUrl} <LinkOutlined/></a>
                                            </CopyToClipboard>
                                        </>}

                                </Card>
                            </div>
                        </Col>
                    </Row>
                    {(appContext.user || applicationConfig!.allowAnonymousUpload) &&
                        <Row>
                            <Col xs={14}>
                                <Button type={"primary"} size={"large"} className={"btn-upload btn-upload-done"}
                                        onClick={handleUploadDone}>{intlMessage("package.btn-add-files-done")}</Button>
                            </Col>
                        </Row>
                    }
                </Col>
            </Row>
        );

    }

    function renderUploadFailed() {
        return (
            <Row gutter={16} justify={!appContext.user ? "space-around" : undefined} align={"middle"}>
                <Col {...layout}>
                    <Row>
                        <Col xs={14}>
                            <Button type={"primary"} size={"large"} className={"btn-upload btn-upload-done"}
                                    onClick={handleUploadRetry}>{intlMessage("package.btn-upload_retry")}</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }

    function renderUploadForm() {

        const {getFieldError, getFieldsError, isFieldTouched} = form;

        // presmerujeme na welcome page pokud neni upload povolen pro prihlaseneho uzivatele
        if (appContext.user && !appContext.user.hasPermission(UserPermission.SEND_PACKAGE)) {
            return <Redirect to={routesMap.Dashboard.path}/>
        }

        return (
            <>
                <Row justify={!appContext.user ? "space-around" : undefined} align={"middle"}>
                    <Col {...layout}>
                        <Row gutter={16}>
                            <Col {...uploadFormLayout}>
                                <Dragger name='file' {...fileUploadSettings} fileList={uploadFileList}
                                         className={"upload-package-dragger"}
                                         showUploadList={false}>

                                    <p className="ant-upload-drag-icon">
                                        <Icon component={IconCloudUpload}/>
                                    </p>
                                    <p className="ant-upload-text">{intlMessage("package.uploader_text")}</p>
                                    <p className="ant-upload-hint">{intlMessage("package.uploader_hint")}</p>
                                    <p className="ant-upload-limits">
                                        {intlMessage("package.limit-file-count", {files: applicationConfig!.maxPackageFileCount})}<br/>
                                        {intlMessage("package.limit-file-size", {size: FormatUtils.formatBytes(applicationConfig!.maxFileSize)})}<br/>
                                        {intlMessage("package.limit-package-size", {size: FormatUtils.formatBytes(applicationConfig!.maxPackageSize)})}
                                    </p>

                                    <div className={"upload-button ant-btn-primary"}>{intlMessage("package.upload_files")}</div>
                                </Dragger>

                                <div className="ant-upload-list ant-upload-list-text">
                                    {totalFiles < 10 ?
                                        uploadFileList.map((item: any) =>
                                            <div key={item.uid}
                                                 className="ant-upload-list-item ant-upload-list-item-undefined">
                                                <div className="ant-upload-list-item-info">
                                                    <span>
                                                        <PaperClipOutlined/>
                                                        <span className="ant-upload-list-item-name" title="{item.name}">{item.name}</span>
                                                    </span>
                                                </div>
                                                <CloseOutlined onClick={() => removeFile(item)}/>
                                            </div>)
                                        :
                                        <div key={"files"}
                                             className="ant-upload-list-item ant-upload-list-item-undefined ant-upload-list-item-combined">
                                            <div className="ant-upload-list-item-info">
                                                <div>
                                                    <PaperClipOutlined/>
                                                    <div className="ant-upload-list-item-name">
                                                        <span>{intlMessage("package.upload_files_count")}</span>
                                                        <span className={"float-right"}>{totalFiles}</span>
                                                    </div>
                                                    <div className="ant-upload-list-item-name">
                                                        <span>{intlMessage("package.upload_files_size")}</span>
                                                        <span className={"float-right"}>{FormatUtils.formatBytes(totalBytes)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="ant-upload-list-item-name" style={{textAlign: "right"}}>
                                                <Button onClick={() => removeFiles()} style={{verticalAlign: "top"}}>{intlMessage("package.remove_all_files")}</Button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </Col>
                            <Col {...uploadFormLayout}>
                                <Form form={form} onFinish={handleSubmit} autoComplete={"nope"} layout={"vertical"} className={"non-modal-form upload-form"}>
                                    {
                                        aPackage?.dataEncryptedWithPassword &&

                                        <Form.Item
                                            name={"downloadPassword"}
                                            label={intlMessage("package.label.password")}
                                            extra={intlMessage("package-detail.data-encrypted-with-password-required-extra")}
                                            className={styles['download-password']}
                                            rules={[{required: true, message: intlMessage("required.password")}]}>
                                            <div className="ant-col-24">
                                                <Input.Password autoComplete={"nope"} maxLength={30}/>
                                            </div>
                                        </Form.Item>
                                    }

                                    <Form.Item className={styles['submit-button']}>
                                        <Button type="primary" size="large" htmlType="submit" id={"uploadButton"}
                                                disabled={hasErrors(getFieldsError()) || !submitAllowed}
                                                loading={uploading}>{intlMessage("package.add-files-to-package-button")}</Button>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        );
    }

    function removeFile(file: PackageFile) {
        const tmpFileList = [...uploadFileList];

        for (let i = 0; i < tmpFileList.length; i++) {
            if (tmpFileList[i].uid === file.uid) {
                tmpFileList.splice(i, 1);

                setTotalBytes(prevState => prevState - file.size);
                setTotalFiles(prevState => prevState - 1);

                i--;
            }
        }
        setUploadFileList(tmpFileList);

        setSubmitAllowed(tmpFileList.length > 0);
    }

    function removeFiles() {
        setUploadFileList([]);
        setTotalBytes(0);
        setTotalFiles(0);
        setUploadedFiles(0);

        setSubmitAllowed(false);
    }

    async function handleSubmit(values: any) {

        setTotalBytes(0);
        setTotalFiles(0);

        canceldByUser = false;

        setUploading(true);
        setNotice("");
        let uploadInProgress = true;

        // clean empty values
        Object.keys(values).forEach((key) => {
            if (typeof values[key] === "string" && values[key] === "") {
                values[key] = undefined;
            }
        })

        // prepare all files and count total amount of bytes
        values.fileList = [];
        for (const file of uploadFileList) {
            values.fileList.push({
                id: null,
                packageId: packageId,
                name: file.name,
                size: file.size,
                type: file.type,
                uid: file.uid
            });

            setTotalBytes(prevState => prevState + (file.size ?? 0));
            setTotalFiles(prevState => prevState + 1);
        }

        // trying create packege
        packageService.addFiles(packageId, folderId, values.fileList, values.downloadPassword).then((files) => {

            // show progress modal
            setUploadState("UPLOADING");
            setSaving(true);

            // configs for all uploads
            const configs: any = [];
            dataForUpload = [];
            uploadingData = [];

            for (const originalFile of uploadFileList) {
                for (const idsFile of files) {
                    if (originalFile.uid === idsFile.uid && originalFile.uid && idsFile.packageId && idsFile.id) {

                        filesUploadProgressBytes[originalFile.uid] = 0;
                        updateFilesUploadProgress(originalFile.uid, 0);

                        // upload progress callback
                        configs[originalFile.uid] = {
                            onUploadProgress: (progressEvent: any) => {
                                filesUploadProgressBytes[originalFile.uid ? originalFile.uid : "all"] = progressEvent.loaded;

                                updateFilesUploadProgress(originalFile.uid, Math.round((progressEvent.loaded * 100) / (originalFile.size ?? 1)));

                                let bytesCompleted = 0;
                                for (const key in filesUploadProgressBytes) {
                                    if (filesUploadProgressBytes.hasOwnProperty(key)) {
                                        bytesCompleted += filesUploadProgressBytes[key];
                                    }
                                }

                                setUploadedBytes(bytesCompleted);
                                let totalPercent = Math.round((bytesCompleted * 100) / totalBytes);
                                if (totalPercent === 100 && totalFiles > uploadedFiles) {
                                    totalPercent = 99;
                                }

                                if (!componentUnmounting) {
                                    setByteProgress(totalPercent);
                                }
                            }
                        }

                        // prepare data for concurrent upload
                        dataForUpload.push({
                            packageId: packageId!,
                            fileId: idsFile.id,
                            file: originalFile,
                            config: configs[originalFile.uid]
                        })

                    }
                }
            }

            const pool = new PromisePool(uploadProducer, 2);

            autoReloadId = setInterval(keepAlive, 60000);

            pool.start()
                .then(() => {
                    if (uploadInProgress) {
                        if (packageId) {
                            packageService.filesUpdated(packageId, files.map(file => file.id)).then(() => {
                                setUploadState("DONE")
                                setSaving(false);
                                uploadInProgress = false;

                            }, reason => {
                                setUploadState("FAILED")
                                setSaving(false);
                                uploadInProgress = false;

                                const violations = reason.response.data.constraintViolations;

                                if (violations.files && violations.files.CUSTOM_JSON) {
                                    const validationError = JSON.parse(violations.files.CUSTOM_JSON.message);

                                    message.error(intlMessage("package.server-validation." + validationError.key, validationError), 10);
                                    return reason;
                                }
                            })
                        } else {
                            console.log("missing packageId");
                        }
                    } else {
                        if (packageId) {
                            packageService.cancel(packageId);
                        }
                    }


                    clearInterval(autoReloadId);
                    autoReloadId = null;

                }, reason => {
                    if (!canceldByUser) {
                        setUploadState("FAILED")
                        setSaving(false);
                        uploadInProgress = false;

                        if (packageId) {
                            packageService.cancel(packageId);
                        }
                    }

                    clearInterval(autoReloadId);
                    autoReloadId = null;

                    return reason;
                })

        }, reason => {

            if (reason.response.data.captcha) {
                setNotice(reason.response.data.message);
                return reason;
            }

            if (reason.response.data.uploadNotAllowed) {
                setNotice(intlMessage("validation." + reason.response.data.message));

                return reason;
            }

            if (reason.response.status === 403) {
                message.error(reason.response.data);

                return reason;
            }

            const violations = reason.response.data.constraintViolations;

            if (violations) {

                if (violations.files && violations.files.CUSTOM_JSON) {
                    const validationError = JSON.parse(violations.files.CUSTOM_JSON.message);

                    message.error(intlMessage("package.server-validation." + validationError.key, validationError), 10);
                    return reason;
                }
            }

            clearInterval(autoReloadId);
            autoReloadId = null;

        }).finally(() => {
            setUploading(false);
        });

    }

    function keepAlive() {
        packageFileService.keepAlive();
        console.log("keepalive");
    }

    function uploadProducer() {
        if (dataForUpload.length > 0) {
            const itemForUpload = dataForUpload.pop();
            if (itemForUpload) {

                uploadingData.push(itemForUpload);

                itemForUpload.file.uploading = true;
                return packageFileService.addFile(itemForUpload.packageId, itemForUpload.fileId, itemForUpload.file, itemForUpload.config).then(() => {

                    setUploadedFiles(prevState => prevState + 1);
                    itemForUpload.file.uploading = false;

                    setFileProgress(Math.round((uploadedFiles * 100) / totalFiles));
                    setByteProgress((prevState: any) => totalFiles === uploadedFiles ? 100 : prevState ? prevState.byteProgress : 0);

                })

            } else {
                return void 0;
            }
        } else {
            return void 0;
        }
    }

    function setStateAfterFilesAdded() {
        setSubmitAllowed(true);

        clearTimeout(addingFileTimer);
    }

    function addToFileList(fileTmp: any) {

        if (totalFiles < applicationConfig!.maxPackageFileCount) {

            // nastavit state jen pokud 100ms neprisel pozadavek na pridani souboru
            if (addFileTimer !== undefined) {
                clearTimeout(addFileTimer);
            }
            addFileTimer = setTimeout(setStateAfterFilesAdded, 100);

            setTotalBytes(prevState => prevState + fileTmp.size);
            setTotalFiles(prevState => prevState + 1);

            setUploadFileList(prevState => {
                return [...prevState, fileTmp];
            });
        }

    }

    function validatePackageLimits(file: File) {

        const mimeFilter = (applicationConfig && applicationConfig.mimeFilter) ? applicationConfig.mimeFilter : null;

        if (file.size === 0) {
            return false;
        }


        // file size
        if (file.size > applicationConfig!.maxFileSize) {
            message.error(intlMessage("package.validation.file-too-big", {
                file: file.name,
                maxFileSize: FormatUtils.formatBytes(applicationConfig!.maxFileSize)
            }), 10);
            return false;
        }

        // package size
        if (totalBytes + file.size > applicationConfig!.maxPackageSize) {
            message.error(intlMessage("package.validation.package-too-big", {
                file: file.name,
                maxPackageSize: FormatUtils.formatBytes(applicationConfig!.maxPackageSize)
            }), 10);
            return false;
        }

        // file count
        if (totalFiles + 1 > applicationConfig!.maxPackageFileCount) {
            message.error(intlMessage("package.validation.too-many-files", {
                file: file.name,
                maxPackageFileCount: applicationConfig!.maxPackageFileCount
            }), 10);
            return false;
        }

        // file uniq
        let uniq = true;
        uploadFileList.forEach((item: PackageUploadFile) => {
            if (item.name === file.name) {
                message.error(intlMessage("package.validation.file-already-attached", {file: file.name}), 10);
                uniq = false;
            }
        });
        if (!uniq) {
            return false;
        }

        // varovani na existujici soubor
        aPackage?.fileList && aPackage?.fileList.forEach((item: PackageFile) => {
            if (item.name === file.name) {
                message.warning(intlMessage("package.validation.file-already-attached-info", {file: file.name}), 10);
            }
        });

        // MIME
        let allowed = false;
        // prevede string na regexp objekt
        const createRegExp = (str: string): RegExp => {
            str = str.replace("+", "[+]");
            return new RegExp("^" + str.trim() + "$");
        }

        // vypnuty filter nebo chybi nastaveni
        if (mimeFilter === null || !mimeFilter.enabled) {
            allowed = true;
        }

        if (mimeFilter !== null && mimeFilter.enabled) {
            const initValue = mimeFilter.kind === "whitelist";

            // aplikace BL/WL
            allowed = !initValue;
            mimeFilter.list.forEach((value) => {
                if (file.type.match(createRegExp(value))) {
                    allowed = initValue;
                }
            });

            // podle vysledku soubor bud pridame nebo ne
            if (!allowed) {
                message.error(intlMessage("package.mime-not-allowed"), 10);
                return false;
            }
        }

        return true;
    }

    function beforeUpload(file: File): boolean {

        if (validatePackageLimits(file)) {

            console.log(file.name, aPackage?.fileList?.map(value => value.name))

            // pokud zname MIME, rovnou ho pridame
            if (file.type) {
                addToFileList(file);
            } else {
                // pokud mime nezname, zkusime soubor precist
                const testBlob = file.slice(0, 1);
                const reader = new FileReader();

                reader.onload = () => {
                    if (validatePackageLimits(file)) {
                        addToFileList(file);
                    }
                };

                reader.onerror = () => {
                    console.log("Cannot read file " + file.name + " or its directory.");

                    return false;
                };
                reader.readAsText(testBlob);
            }
        }

        return false;
    }


    function onRemove(file: any) {
        return true;
    }


    function handleUploadStorno(e: any) {
        canceldByUser = true;
        cancelAllUploads();

        if (packageId) {
            packageService.cancel(packageId);
        }

        resetForm();

        clearInterval(autoReloadId);
        autoReloadId = null;

    }

    function handleUploadDone(e: any) {
        // zaciname znovu, tak vycistime formular
        resetForm();

        const path = (folder?.name === '/') ? `/packages/${packageId}` : `/packages/${packageId}/${folderId}`

        navigate.push(path);
    }

    function handleUploadRetry(e: any) {
        setUploadState("UPLOAD_FORM");
    }

    function cancelAllUploads() {
        uploadingData.forEach((value: IItemForUpload) => {
            value.config.source.cancel();
        });
        uploadingData = [];
    }

    function resetForm() {
        setTotalBytes(0);
        setTotalFiles(0);
        setUploadedFiles(0);
        filesUploadProgressBytes = [];
        setFilesUploadProgress({});
        setUploadFileList([]);
        dataForUpload = [];

        setFileProgress(0);
        setSaving(false);
        setByteProgress(0);
        setSubmitAllowed(false);
        setUploadState("UPLOAD_FORM");

        form.validateFields();

    }

    function loadPackage(pkgId: string) {
        const pkgJson = localStorageService.getIndexedItem(LocalStorageNamespace.PackageDetail, pkgId);

        if (pkgJson) {
            setAPackage(JSON.parse(pkgJson));
        }
    }

    function renderFolderName(folder?: Folder) {
        return folder?.name === '/' ? intlMessage("root-folder") : folder?.name;
    }

}

export default PackageAddFiles;