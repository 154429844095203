import {PasswordTokenInfo} from "./PasswordTokenInfo";
import {ExternalRecipient} from "./ExternalRecipient";
import {InternalRecipient} from "./InternalRecipient";
import {Recipient} from "./Recipient";
import {User} from "./User";

export enum PackageFlag {
    ARCHIVED = "ARCHIVED",
    OVERSIZE = "OVERSIZE",
    DLP_MATCHED = "DLP_MATCHED",
    ENCRYPTED_CONTENT = "ENCRYPTED_CONTENT",
    FORWARDED = "FORWARDED",
    COOPERATIVE = "COOPERATIVE",
    UNKNOWN = "UNKNOWN",
    NONE = "NONE",
}

export enum FileIntegrityResult {
    UNKNOWN = "UNKNOWN",
    VALID = "VALID",
    INVALID = "INVALID",
    INACCESSIBLE = "INACCESSIBLE"
}

export enum PackageDataIntegrity {
    UNKNOWN = "UNKNOWN",
    VALID = "VALID",
    INVALID = "INVALID",
    INACCESSIBLE = "INACCESSIBLE"
}

export enum FileFlag {
    DLP_MATCHED = "DLP_MATCHED",
    ENCRYPTED_CONTENT = "ENCRYPTED_CONTENT",
    OVERSIZE = "OVERSIZE",
    CDR = "CDR",
}

export enum PackageCheckState {
    CLEAN = "CLEAN",
    QUARANTINED = "QUARANTINED",
    UNKNOWN = "UNKNOWN"
}

export enum PackageWorkflowState {
    REQUESTED = "REQUESTED",
    UPLOADING = "UPLOADING",
    ACTIVE = "ACTIVE",
    DELETED = "DELETED",
    ARCHIVED = "ARCHIVED",
    CONTENT_DELETED = "CONTENT_DELETED"
}

export enum PackagePasswordType {
    Request,
    Download,
    Forward
}

export enum PackageAccessType {
    PUBLIC = "PUBLIC",
    PRIVATE = "PRIVATE",
    INTERNAL = "INTERNAL",
    BRIEFCASE = "BRIEFCASE",
}

export enum RecipientType {
    REGULAR = "REGULAR",
    BLIND = "BLIND",
    CONTRIBUTOR = "CONTRIBUTOR",
}

export enum EncryptionStatus {
    NOT_ENCRYPTED = "NOT_ENCRYPTED",
    ENCRYPTED = "ENCRYPTED",
    ENCRYPTION_FAILED = "ENCRYPTION_FAILED",
    DECRYPTION_FAILED = "DECRYPTION_FAILED",
    ENCRYPTION_IN_PROGRESS = "ENCRYPTION_IN_PROGRESS",
    DECRYPTION_IN_PROGRESS = "DECRYPTION_IN_PROGRESS"
}

export enum EncryptionType {
    NONE = "NONE",
    AT_REST_SERVER_KEK = "AT_REST_SERVER_KEK",
    AT_REST_PASSWORD = "AT_REST_PASSWORD"
}

export enum EmailType {
    INTERNAL = "INTERNAL",
    DOMAIN = "DOMAIN",
    ANONYMOUS = "ANONYMOUS"
}

export enum PackageOrigin {
    CLOSED = "CLOSED",
    OPEN = "OPEN"
}

export interface UserFlags {
    flagged?: boolean;
    viewed?: boolean;
}

export interface Approver {
    id: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    deleted?: any;
}

export interface Package {
    id?: string;
    name?: string;
    note?: string;
    noteFrom?: string;
    noteTo?: string;
    uploaded?: string;
    created?: string;
    workflowState?: PackageWorkflowState;
    checkState?: PackageCheckState;
    hash?: string;
    downloadPassword?: string;
    requestPassword?: string;
    dataEncryptedWithPassword?: boolean;
    fromRegisteredUser?: boolean;
    anonymousSenderName?: string;
    anonymousSenderEmail?: string;
    downloadExpiration?: number;
    sender?: User;
    totalSize?: number;
    activeSize?: number;
    /**
     * Jen pro přeposlání zásilky.
     */
    downloadPasswordToken?: string;
    downloadTokenInfo?: PasswordTokenInfo;
    passwordProtected?: boolean;
    encryptionQueued?: boolean;
    decryptionQueued?: boolean;
    recipientEmails?: string;
    blindRecipientEmails?: string;
    rootFolderId?: string;
    anyCleanFile?: boolean;
    anyFileUnchecked?: boolean;
    anyFileRechecked?: boolean;
    someFileIsSmallerThanMax?: boolean;
    recipients?: Recipient[] // for upload only
    internalRecipients?: InternalRecipient[]
    externalRecipients?: ExternalRecipient[]
    files?: PackageFile[]
    fileList?: PackageFile[];
    deletedFileList?: PackageFile[];
    uncleanFileList?: PackageFile[];
    flags?: PackageFlag[];
    cleanExpiration?: string;
    minCleanExpiration?: string;
    maxCleanExpiration?: string;
    quarantinedExpiration?: string;
    deletedCleanExpiration?: string;
    deletedQuarantinedExpiration?: string;
    archivedCleanExpiration?: string;
    archivedQuarantinedExpiration?: string;
    fileCount?: number;
    activeFileCount?: number;
    archiveSize?: number;
    accessType?: PackageAccessType;
    encryptionStatus?: EncryptionStatus;
    encryptionType?: EncryptionType;
    persistent?: boolean;
    updated?: boolean;
    archiveDownloadCounter?: DownloadCounter;
    dataIntegrity?: PackageDataIntegrity;
    requestedSenderEmailType?: EmailType;
    approveRequired?: boolean;
    approved?: string;
    disapproved?: string;
    approverUser?: User;
    approverAdmin?: User;
    limitAccessCount?: number;
    accessCount?: number;
    originalPackage?: string;
    packageOrigin?: PackageOrigin;
    downloadFilesAllowed?: boolean;
    userFlags?: UserFlags;
    approvers?: Approver[];
    contributor?: boolean;
    canBeApproved?: boolean;
    canSetApprover?: boolean;
    canDeleteOrRenameFilesInPackage?: boolean;
    canAddFilesToPackage?: boolean;
}

export interface PackageFile {
    id?: string | null,
    packageId?: string | null,
    uid: string | null | undefined;
    name: string;
    size: number;
    type: string;
    hash?: string;
    mimeType?: string;
    state?: string;
    flags?: FileFlag[];
    selected?: boolean;
    uploaded?: string;
    uploadedBy?: User;
    deleted?: boolean;
    downloadCounter?: DownloadCounter;
    integrityCheckStarted?: string;
    integrityCheckDone?: string;
    integrityCheckResult?: FileIntegrityResult;
    contentDeleted?: string;
    fileChecks?: FileCheck[];
    cdrCount?: number;
    cdrFiles?: PackageFile[];
    originalFile?: string;
}


export interface FileCheck {
    checkFinished: string;
    checkResult: FileCheckResult;
    checkStarted: string,
    detectionEngine: DetectionEngine,
    fileCheckReports: any[]
    finalCheckResult: FinalFileCheckResult,
    detectionName?: string,
    id: string,
    extraInfo: any,
    linkToDetail: string
}


export enum FinalFileCheckResult {
    NONE = "NONE",
    CLEAN = "CLEAN",
    UNCLEAN = "UNCLEAN",
    WARNING = "WARNING"
}

export interface DetectionEngine {
    id?: string;
    type?: DetectionEngineType;
    engineClass?: DetectionEngineClass;
    enabled: boolean;
    configParams: any;
    configParamsSecure?: any;
    mandatory: boolean;
    status?: any;
    statusUpdated?: Date;
}

export enum DetectionEngineClass {
    ANTIVIRUS = "ANTIVIRUS",
    SANDBOX = "SANDBOX",
    INTERNAL = "INTERNAL"
}

export enum DetectionEngineType {
    CHECKPOINT_SANDBLAST_CLOUD = "CHECKPOINT_SANDBLAST_CLOUD",
    CHECKPOINT_SANDBLAST = "CHECKPOINT_SANDBLAST",
    FORTISANDBOX = "FORTISANDBOX",
    CLAMAV = "CLAMAV",
    ESET = "ESET",
    BITDEFENDER = "BITDEFENDER",
    KASPERSKY = "KASPERSKY",
    SOPHOS = "SOPHOS",
    FORTICLIENT = "FORTICLIENT",
    DLP = "DLP",
    ENCRYPTED_CONTENT = "ENCRYPTED_CONTENT",
    DUMMY = "DUMMY",
    MIME = "MIME"
}

export enum FileCheckResult {
    VIRUS = "VIRUS",
    HIGH_RISK = "HIGH_RISK",
    MEDIUM_RISK = "MEDIUM_RISK",
    LOW_RISK = "LOW_RISK",
    MIME_BLOCK = "MIME_BLOCK",
    DLP_MATCH = "DLP_MATCH",
    ENCRYPTED = "ENCRYPTED",
    CLEAN = "CLEAN",
    FAILED = "FAILED",
    OVERSIZE = "OVERSIZE",
    NONE = "NONE"
}


export interface DownloadCounter {
    anonymousUsersTotal: number;
    registeredUsersTotal: number;
    adminsTotal: number;
}
