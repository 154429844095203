import {UserOutlined} from "@ant-design/icons";
import {Form, message, Select, SelectProps} from "antd";

import {useForm} from "antd/lib/form/Form";
import {useContext, useEffect, useRef, useState} from "react";
import {appContext, PackageServiceContext, UserServiceContext} from "../Contexts";
import {Package} from "../domain/Package";
import {Approver} from "../domain/User";
import {useIntlMessage} from "../sal-ui/createIntlMessage";
import {FormModal, FormModalDelegate, FormModalProps} from "../sal-ui/FormModal";

interface IProps extends FormModalProps {
    package?: Package;
    type: "USER"|"PACKAGE"
}

function SetApproverModal(props: IProps) {

    const userService = useContext(UserServiceContext);
    const packageService = useContext(PackageServiceContext);

    const [form] = useForm();
    const intlMessage = useIntlMessage('package-modal');
    const formModalRef = useRef<FormModalDelegate>(null);

    const [allApprovers, setAllApprovers] = useState<Approver[]>();
    const [selectedApprovers, setSelectedApprovers] = useState<string[]>();

    const [approversListVisible, setApproversListVisible] = useState<boolean>(false);


    useEffect(() => {

    }, [])

    useEffect(() => {

        if ((!appContext.user && !props.package) || !props.visible) return;

        userService.getAllApprovers().then(approvers => {
            setSelectedApprovers([]);

            if (approvers) {
                setAllApprovers(approvers);
            } else {
                setAllApprovers([]);
            }

            switch (props.type) {
                case "PACKAGE":
                    setSelectedApprovers(props.package!.approvers?.map(value => value.id));
                    form.setFieldsValue({approver: props.package!.approvers?.map(value => value.email)});
                    break;
                case "USER":
                    setSelectedApprovers(appContext.user!.approvers?.map(value => value.id));
                    form.setFieldsValue({approver: appContext.user!.approvers?.map(value => value.email)});
                    break;
            }

        });

    }, [appContext.user, props.package, props.visible])

    return (
        <FormModal okText={intlMessage("common.save")}
                   cancelText={intlMessage("common.cancel")}
                   onCancel={props.onCancel}
                   onOk={props.onOk}
                   width={600}
                   title={props.title}
                   visible={props.visible}
                   form={form}
                   editMode={true}
                   updateItem={handleSubmit}
                   ref={formModalRef}
                   maskCloseable={false}>

            <Form form={form} onFinish={() => formModalRef.current?.onSubmit()} layout={"vertical"}>
                <Form.Item label={intlMessage("approver")} name={'approver'}>

                    <Select mode="multiple" size={'middle'} style={{width: '100%'}}
                            notFoundContent={intlMessage("approver-not-found")}
                            filterOption={filterRecipientEmailOption}
                            popupClassName={"recipientEmailsOptions"}
                            defaultActiveFirstOption={true}
                            optionLabelProp={"value"}
                            autoFocus={true}
                            onChange={(value: any, option: any) => {
                                setSelectedApprovers(option.map((value1: any) => value1.key));
                                setApproversListVisible(false);
                            }}

                    >
                        {
                            allApprovers && allApprovers.map((approver: Approver) => (

                                <Select.Option key={approver.id} value={approver.email!} title={approverName(approver)}>
                                    <div className={"contact-icon"}>
                                        <UserOutlined/>
                                    </div>
                                    <div className={"contact-text"}>
                                        <span>{approver.lastName && <>{approver.firstName + " " + approver.lastName}<br/></>}{approver.email}</span>
                                    </div>
                                </Select.Option>
                            ))
                        }
                    </Select>

                </Form.Item>

            </Form>
        </FormModal>
    );

    function approverName(approver: Approver): any {
        return approver.lastName ? approver.firstName + " " + approver.lastName : approver.email;
    }

    function filterRecipientEmailOption(inputValue: any, option: any) {

        // if (allApprovers && allApprovers?.length < 5) return true;

        const email: string = option.value;
        const name: string = option.title;
        let displayItem = false;

        // hledani podle klice
        if (email.toLowerCase().includes(inputValue.toLowerCase())) {
            displayItem = true;
        }

        // hledani podle jmena
        if (name && name.toLowerCase().includes(inputValue.toLowerCase())) {
            displayItem = true;
        }

        return displayItem;
    }

    function handleSubmit(values: any): Promise<void> {

        if (props.type === "PACKAGE") {
            return packageService.setApprovers(props.package!.id!, selectedApprovers!).then(() => {
                message.success(intlMessage('approvers-has-been-set-for-package', {name: props.package!.name ? props.package!.name : props.package!.id}));

                props.onOk!();
            })
        }

        if (props.type === "USER") {
            return userService.setApprovers(selectedApprovers!).then(() => {
                message.success(intlMessage('approvers-has-been-set'));

                props.onOk!();
            })
        }

        return new Promise(resolve => {
        });
    }


}

export default SetApproverModal;
