import {ConfigProvider} from "antd";
import cs_CZ from "antd/lib/locale/cs_CZ";
import en_US from "antd/lib/locale/en_US";
import hu_HU from "antd/lib/locale/hu_HU";
import sk_SK from "antd/lib/locale/sk_SK";
import {observer} from "mobx-react"
import "moment/locale/cs";
import "moment/locale/hu";
import "moment/locale/sk";
import {useContext} from 'react';
import {RawIntlProvider} from "react-intl";
import {AppContextContext} from "../Contexts";
import {intl} from "../sal-ui/Intl";
import AnonymousHomePage from "./AnonymousHomePage";
import HomePage from "./HomePage";

function App() {
    const appContext = useContext(AppContextContext);

    return (
        <RawIntlProvider value={intl} key={appContext.language}>
            <ConfigProvider locale={getEffectiveLocale()} csp={appContext.cspConfig} iconPrefixCls={"anticon"}

                            theme={{
                                token: {
                                    // Seed Token
                                    colorPrimary: '#0583FF',
                                    borderRadius: 2,
                                    colorText: "#434656",
                                    lineHeight: 1.5,
                                    fontSize: 13,
                                    fontFamily: "'Open Sans', sans-serif",
                                    colorBgLayout: "#E9EBF3",
                                    // Alias Token
                                    // colorBgContainer: '#f6ffed',
                                },
                                components: {
                                    Upload: {
                                        colorFillAlter: "white"
                                    },
                                    Checkbox: {
                                        controlHeight: 20,
                                        controlInteractiveSize: 20
                                    },
                                    Dropdown: {
                                        padding: 0,
                                        paddingBlock: 0,
                                        controlItemBgHover: "#F7F7F7",
                                        controlPaddingHorizontal: 0,

                                    },
                                    Button: {
                                        sizeLG: 48,
                                        controlHeightLG: 48,
                                        lineHeight: 48
                                    },
                                    Form: {
                                        controlHeight: 24
                                    },
                                    Input: {
                                        controlHeight: 36,
                                        fontSize: 14,
                                        colorText: "#333343",
                                        lineHeight: 2.55

                                    },
                                    Card: {
                                        headerBg: "#515165",
                                        colorBgLayout: "red",
                                        colorTextHeading: "white",
                                    },
                                    Tag: {
                                        fontSize: 13,
                                    },

                                    Select: {
                                        controlHeight: 36,
                                    },
                                    Table: {
                                        headerSortActiveBg: "#515165",
                                        headerBg: "#515165",
                                        headerFilterHoverBg: "#515165",
                                        headerSortHoverBg: "#515165",
                                        headerColor: "#fff",
                                        headerSplitColor: "#fff",

                                    },
                                    Modal: {
                                        headerBg: "#0583ff",
                                        colorTextHeading: "#fff",
                                    },
                                    Switch: {
                                        borderRadius: 2,
                                        handleSize: 14,
                                        trackHeight: 18,
                                    },
                                    Menu: {
                                        darkItemBg: "#333343",
                                        darkSubMenuItemBg: "#2c2c3a",
                                        colorBgBase: "#333343",
                                        colorBgLayout: "#333343",
                                        itemHeight: 54,
                                        borderRadius: 0,
                                        itemBorderRadius: 0,
                                        itemMarginInline: 0,
                                        itemMarginBlock: 0,

                                    },
                                    Layout: {
                                        siderBg: "#333343"
                                    }

                                }
                            }}

            >
                {appContext.user ? <HomePage/> : <AnonymousHomePage/>}
            </ConfigProvider>
        </RawIntlProvider>
    )

    /*
@btn-height-lg: 48px;

@layout-body-background: #E9EBF3;
@layout-sider-background: #333343;
@menu-dark-bg: #333343;
@menu-dark-inline-submenu-bg: #2c2c3a;
@menu-inline-toplevel-item-height: 54px;
@menu-item-color: #F7F9FC;
@menu-item-height: 40px;
@primary-color: #0583FF;
@table-row-hover-bg: #B2D5FF;
@text-color: #434656;
@line-height-base: 1.5;
     */

    function getEffectiveLocale() {
        switch (appContext.language) {
            case 'cs':
                return cs_CZ;
            case 'sk':
                return sk_SK;
            case 'hu':
                return hu_HU;
            default:
                return en_US;
        }
    }
}

export default observer(App);
