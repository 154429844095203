import {UploadFile} from "antd/lib/upload/interface";
import Axios from "axios";
import {File} from "../domain/File";
import {Folder} from "../domain/Folder";

export class PackageFileService {

    private BASE_URL = "/user/upload-file/";

    private defaultConfig = {}

    public upload(packageId: string, fileId: string, entity: UploadFile, config: any, uploadToken?: string): Promise<any> {

        if (!config) {
            config = this.defaultConfig;
        }

        if (uploadToken) {
            config.headers = {'X-Sofie-Upload-Token': uploadToken};
        }

        const CancelToken = Axios.CancelToken;
        const cancelSource = CancelToken.source();

        config.cancelToken = cancelSource.token;
        config.source = cancelSource;

        config.headers = {
            ...config.headers,
            "Content-Type": "text/html"
        };

        return Axios.post(this.BASE_URL + packageId + "/" + fileId, entity, config);
    }

    public addFile(packageId: string, fileId: string, entity: UploadFile, config: any): Promise<any> {

        if (!config) {
            config = this.defaultConfig;
        }

        const CancelToken = Axios.CancelToken;
        const cancelSource = CancelToken.source();

        config.cancelToken = cancelSource.token;
        config.source = cancelSource;

        config.headers = {
            ...config.headers,
            "Content-Type": "text/html"
        };

        return Axios.post(this.BASE_URL + packageId + "/add-file/" + fileId, entity, config);
    }

    public delete(packageId: string, file: File, deleteSafeCopy: boolean | undefined): Promise<any> {
        if (deleteSafeCopy === undefined) {
            return Axios.delete(`/user/packages/${packageId}/file/${file.id}`);
        } else {
            return Axios.delete(`/user/packages/${packageId}/file/${file.id}?deleteSafeCopy=${deleteSafeCopy}`);
        }
    }

    public renameFile(packageId: string, file: File, filename: string): Promise<any> {
        return Axios.post(`/user/packages/${packageId}/rename-file/${file.id}`, {filename});
    }


    public keepAlive() {
        return Axios.get(this.BASE_URL + "in-progress", {params: {withoutProgressBar: true}});
    }


    public massDelete(packageId: string, files: (File | Folder)[], deleteSafeCopy: boolean | undefined): Promise<any> {
        if (deleteSafeCopy === undefined) {
            return Axios.post(`/user/packages/${packageId}/files/delete`, this.getFileFolterIds(files));
        } else {
            return Axios.post(`/user/packages/${packageId}/files/delete?deleteSafeCopy=${deleteSafeCopy}`, this.getFileFolterIds(files));
        }
    }

    public getFileFolterIds(items: (File | Folder)[]) {
        const ids: FileFolderIds = {fileIds: [], folderIds: []}

        items.forEach(item => {
            if (item instanceof File) {
                ids.fileIds.push(item.id);
            }

            if (item instanceof Folder) {
                ids.folderIds.push(item.id);
            }
        });

        return ids;
    }

}

type FileFolderIds = {
    fileIds: string[];
    folderIds: string[];
}
