import {Breadcrumb, Button, Modal, Select} from "antd";
import React, {useContext, useEffect, useState} from "react";
import {useIntlMessage} from "../../../sal-ui/createIntlMessage";
import {FolderServiceContext} from "../../../Contexts";
import {FolderIndex} from "../../../domain/FolderIndex";
import FolderContent, {ColumnName} from "./FolderContent";
import {File} from "../../../domain/File";
import {FileFlag} from "../../../domain/Package";
import {Folder} from "../../../domain/Folder";
import styles from "./MoveModal.module.css";
import downloadStyles from "./PackageDownload.module.css";
import {formatNameConflictResolution, NameConflictResolution} from "../../../domain/NameConflictResolution";

interface IProps {
    initialFolderId?: string;
    packageId: string;
    items: (File | Folder)[];
    visible: boolean;
    inProgress: boolean;
    onCancel: () => void;
    onOk: (targetFolder: Folder, conflictResolution: NameConflictResolution) => void;
}

function MoveModal(props: IProps) {
    const folderService = useContext(FolderServiceContext);
    const intlMessage = useIntlMessage('move-modal');
    const [folderId, setFolderId] = useState<string>();
    const [folderIndex, setFolderIndex] = useState<FolderIndex>();
    const [items, setItems] = useState<(File | Folder)[]>([]);
    const [isMovePossible, setIsMovePossible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [conflictResolution, setConflictResolution] = useState(NameConflictResolution.REPLACE);

    useEffect(() => {
        if (props.initialFolderId === undefined) {
            return;
        }

        setFolderId(props.initialFolderId);
    }, [props.initialFolderId]);

    useEffect(() => {
        if (folderId === undefined || !props.visible) {
            return;
        }

        loadFolderIndex(folderId);
    }, [props.visible, folderId]);

    return (
        <Modal title={intlMessage("title", {items: props.items.length})}
               open={props.visible}
               style={{top: 60}}
               width={800}
               destroyOnClose={true}
               confirmLoading={props.inProgress}
               maskClosable={false}
               onCancel={props.onCancel}
               footer={<>
                   <div className={styles['replace-policy']}>
                       {intlMessage("existing-items-policy")}:
                       <Select
                           popupMatchSelectWidth={false}
                           value={conflictResolution}
                           onChange={setConflictResolution}
                           options={[
                               {label: formatNameConflictResolution(intlMessage, NameConflictResolution.REPLACE), value: NameConflictResolution.REPLACE},
                               {label: formatNameConflictResolution(intlMessage, NameConflictResolution.RENAME), value: NameConflictResolution.RENAME}
                           ]}/>
                   </div>

                   <Button onClick={e => props.onCancel()}>
                       {intlMessage('common.cancel')}
                   </Button>

                   <Button type="primary"
                           onClick={e => props.onOk(folderIndex?.folder!, conflictResolution)}
                           disabled={!isMovePossible || props.inProgress}>
                       {intlMessage("move-here")}
                   </Button>
               </>}
        >
            <Breadcrumb className={downloadStyles['folder-breadcrumb']}>
                {folderIndex?.breadcrumbs?.map((path: Folder) => <Breadcrumb.Item key={path.id}>{renderFolderName(path, true)}</Breadcrumb.Item>)}
            </Breadcrumb>

            <FolderContent
                packageId={props.packageId}
                items={items}
                visibleColumns={[
                    ColumnName.NAME,
                    ColumnName.UPLOADED,
                    ColumnName.SIZE,
                ]}
                loading={loading}
                integrityCheckAllowed={false}
                hideFileFlags={true}
                hideExpandable={true}
                maxHeight={'calc(100vh - 400px)'}
                disableDownloadLinks={true}
                onFolderNameClick={folder => {
                    loadFolderIndex(folder.id);
                }}
            />

        </Modal>
    );

    function loadFolderIndex(folderId: string) {
        setIsMovePossible(false);
        setLoading(true);

        folderService.getIndex(folderId)
            .then(index => {
                setFolderIndex(index);

                const activeFolders = index.folders.filter(folder => folder.deleted === undefined);

                const fileList: File[] = [];

                for (const file of index.files) {
                    if (!file.deleted && file.state === "CLEAN") {
                        fileList.push(file);
                    }
                }

                const activeFiles = fileList.filter(file =>
                    !file.flags?.includes(FileFlag.CDR) ||
                    (file.flags?.includes(FileFlag.CDR) && !fileList?.find(packageFile => packageFile.id === file.originalFile)));

                setItems([
                    ...(activeFolders || []),
                    ...(activeFiles || []),
                ]);

                folderService
                    .isMoveToItself(
                        folderId,
                        props.items
                            .filter(value => value instanceof Folder)
                            .map(value => value.id),
                        props.items
                            .filter(value => value instanceof File)
                            .map(value => value.id)
                    )
                    .then(isMoveToItself => {
                        setIsMovePossible(!isMoveToItself);
                    });
            })
            .finally(() => setLoading(false));
    }

    function renderFolderName(folder: Folder, includeStatus?: boolean) {
        if (folder.name === '/') {
            return <a onClick={e => loadFolderIndex(folder.id)}>{intlMessage("package-detail.root-folder")}</a>;
        }

        return <a onClick={e => loadFolderIndex(folder.id)}>{folder.name}</a>;
    }

}

export default MoveModal;