import {
    ArrowsAltOutlined,
    BarsOutlined,
    CheckCircleFilled,
    CheckCircleOutlined,
    CloseCircleFilled,
    CloseCircleOutlined,
    ColumnHeightOutlined,
    DeleteFilled,
    DownloadOutlined,
    EditFilled,
    ExclamationCircleFilled,
    FileAddOutlined,
    FileUnknownFilled,
    FolderAddFilled,
    FolderAddOutlined,
    ForwardFilled,
    GlobalOutlined,
    InfoCircleFilled,
    LinkOutlined,
    LoadingOutlined,
    LockFilled,
    MailFilled,
    PlusOutlined,
    PushpinFilled,
    PushpinOutlined,
    QuestionCircleFilled,
    SettingOutlined,
    ShareAltOutlined,
    ShrinkOutlined,
    StarFilled,
    StarOutlined,
    SyncOutlined,
    WarningFilled,
} from '@ant-design/icons';
import {Alert, Breadcrumb, Button, Card, Col, Drawer, Dropdown, Empty, MenuProps, message, Row, Skeleton, Space, Spin, Table, Tabs, Tag, Tooltip} from "antd";
import {MenuItemType} from "antd/es/menu/interface";
import useForceUpdate from "antd/lib/_util/hooks/useForceUpdate";
import Column from "antd/lib/table/Column";
import Cookies from "js-cookie";
import {useContext, useEffect, useRef, useState} from "react";
import {AdalConfig, AuthenticationContext} from "react-adal";
import CopyToClipboard from 'react-copy-to-clipboard';
import {useHistory, useLocation, useParams} from 'react-router';
import {Link} from "react-router-dom";
import {AppContextContext, FolderServiceContext, LocalStorageServiceContext, packageFileService, PackageServiceContext} from "../../../Contexts";
import {isDownloadTokenInfoValid, PasswordTokenInfo} from "../../../domain/PasswordTokenInfo";
import {File} from "../../../domain/File";
import {Folder} from "../../../domain/Folder";
import {FolderIndex} from "../../../domain/FolderIndex";
import {InternalRecipient} from "../../../domain/InternalRecipient";
import {Approver, DownloadCounter, EncryptionStatus, FileCheck, FileFlag, Package, PackageAccessType, PackageCheckState, PackageDataIntegrity, PackageFlag, PackagePasswordType, PackageWorkflowState} from "../../../domain/Package";
import {User, UserPermission} from "../../../domain/User";
import {useIntlMessage} from "../../../sal-ui/createIntlMessage";
import {intl} from "../../../sal-ui/Intl";
import LocalStorageNamespace from "../../../service/LocalStorageNamespace";
import {DownloadObjectType} from "../../../service/PackageService";
import DataUtils from "../../../utils/DataUtils";
import EventSourceNotifier from "../../../utils/EventSourceNotifier";
import {downloadFileViaAnchorOrForm} from "../../../utils/FileUtils";
import FormatUtils from "../../../utils/FormatUtils";
import {anonymousRoutesMap} from "../../AnonymousRoutes";
import ModalOperation from "../../common/ModalOperation";
import {DocumentTitle} from "../../DocumentTitle";
import DownloadFileLoginModal from "../../DownloadFileLoginModal";
import OkLeftPopconfirm from "../../OkLeftPopconfirm";
import PackageAccessConfirmRequired from "../../PackageAccessConfirmRequired";
import PackageModal from "../../PackageModal";
import PackagePasswordRequired from "../../PackagePasswordRequired";
import SetApproverModal from "../../SetApproverModal";
import SmartTag from "../../SmartTag";
// import {MenuItemType} from "antd/es/menu/hooks/useItems";
import AddFolderModal from "./AddFolderModal";
import DownloadPackagePasswordModal from "./DownloadPackagePasswordModal";
import FileModal from "./FileModal";
import FolderContent, {ColumnName, MassActions} from "./FolderContent";
import {FileState, PackageContentConfig, PackageContentConfigModal, ShowAs} from "./PackageContentConfigModal";
import styles from "./PackageDownload.module.css";
import RenameFolderModal from "./RenameFolderModal";
import MoveModal from "./MoveModal";

enum ModelState {
    Loading,
    Loaded,
    PasswordRequired,
    AccessDenied,
    NotFound,
    TooManyRequests,
    LimitAccessConfirmRequired
}

const defaultPackageContentConfig = {
    showAs: ShowAs.UNIFIED,
    visibleStates: [FileState.ACTIVE, FileState.UNCLEAN, FileState.DELETED]
};

function PackageDownload() {
    const appContext = useContext(AppContextContext);
    const applicationConfig = appContext.applicationConfig;
    const packageService = useContext(PackageServiceContext);
    const localStorageService = useContext(LocalStorageServiceContext);
    const folderService = useContext(FolderServiceContext);
    const intlMessage = useIntlMessage("package-detail");
    const location = useLocation();
    const {packageId, folderId}: any = useParams();
    const navigate = useHistory();

    const [modelState, setModelState] = useState<ModelState>(ModelState.Loading);
    const [ownPackage, setOwnPackage] = useState<boolean>(false);
    const [recipientPackage, setRecipientPackage] = useState<boolean>(false);

    const loadPackageQueue = useRef<any>();
    const skipSse = useRef<boolean>(false);
    const moveDonePromiseRef = useRef<any>();

    const [aPackage, setAPackage] = useState<Package>({
        workflowState: PackageWorkflowState.ACTIVE,
        checkState: PackageCheckState.UNKNOWN,
        internalRecipients: [],
        externalRecipients: [],
    });

    const [allFiles, setAllFiles] = useState<(File | Folder)[]>([]);
    const [activeFiles, setActiveFiles] = useState<(File | Folder)[]>([]); // aktivní soubory (bez bezpečných kopií)
    const [uncleanFiles, setUncleanFiles] = useState<(File | Folder)[]>([]); // aktivní soubory (bez bezpečných kopií)
    const [deletedFiles, setDeletedFiles] = useState<(File | Folder)[]>([]); // smazané soubory (bez bezpečných kopií)
    const [folderIndex, setFolderIndex] = useState<FolderIndex>();
    const [folderLoading, setFolderLoading] = useState(false);
    const [allDeletedLoading, setAllDeletedLoading] = useState(false);
    const [allQuarantinedLoading, setAllQuarantinedLoading] = useState(false);
    const [allDeletedFiles, setAllDeletedFiles] = useState<(File | Folder)[]>();
    const [allQuarantinedFiles, setAllQuarantinedFiles] = useState<(File | Folder)[]>();
    const [itemsToMove, setItemsToMove] = useState<(File | Folder)[]>([]);
    const [moveInProgress, setMoveInProgress] = useState(false);

    const [modal, setModal] = useState({visible: false, title: "", model: {}, operation: ModalOperation.Update});
    const [fileModal, setFileModal] = useState({visible: false, title: "", model: {}, operation: ModalOperation.Update});
    const [fileModalVisible, setFileModalVisible] = useState<boolean>(false);
    const [moveModalVisible, setMoveModalVisible] = useState<boolean>(false);
    const [setApproverModal, setSetApproverModal] = useState({visible: false, title: "", model: {}, operation: ModalOperation.Update});
    const [addFolderModalVisible, setAddFolderModalVisible] = useState<boolean>(false);
    const [renameFolderModalVisible, setRenameFolderModalVisible] = useState<boolean>(false);
    const [packageContentConfigModalVisible, setPackageContentConfigModalVisible] = useState(false);

    const [packageRequiresPassword, setPackageRequiresPassword] = useState<boolean>(false);
    const [enteredInvalidPassword, setEnteredInvalidPassword] = useState<boolean>(false);
    const [serverErrorMessage, setServerErrorMessage] = useState<string>("");
    const [selectedFolder, setSelectedFolder] = useState<Folder>();
    const [compactView, setCompactView] = useState(true);
    const [packageContentConfig, setPackageContentConfig] = useState<PackageContentConfig>(defaultPackageContentConfig);

    const [reportsVisible, setReportsVisible] = useState<boolean>(false);
    const [allMimeTypesVisible, setAllMimeTypesVisible] = useState<boolean>(false);
    const [allMimeTypes, setAllMimeTypes] = useState<any>();
    const [activeFileCheck, setActiveFileCheck] = useState<FileCheck>();
    const [fileCheck, setFileCheck] = useState<FileCheck>();

    const forceUpdate = useForceUpdate();

    const [downloadFileModal, setDownloadFileModal] = useState<{ visible: boolean, url: string, objectId: string, objectType: DownloadObjectType, packageDownloadToken?: string }>({
        visible: false,
        url: "",
        objectId: "",
        objectType: DownloadObjectType.FILE
    });

    const [packagePasswordModal, setPackagePasswordModal] = useState<{ visible: boolean, packageId: string, onDownloadTokenAcquired: (token: PasswordTokenInfo, rememberPassword: boolean) => void }>({
        visible: false,
        packageId: "",
        onDownloadTokenAcquired: () => {
            // no-op
        }
    });

    const downloadTokenInfo = useRef<PasswordTokenInfo>();
    const limitAccessToken = useRef<string>();

    // noinspection JSUnusedGlobalSymbols
    const commonFolderContentProperties: any = {
        packageId,
        aPackage,
        onRowFileCheck: onFileCheckRow,

        onMove: onMove,
        onDownloadAsZip: onDownloadAsZip,
        onMassDeleteConfirm: onMassDeleteConfirm,
    };

    useEffect(() => {
        const savedPackageContentConfig = localStorageService.getItem(LocalStorageNamespace.PackageContentConfig);

        if (savedPackageContentConfig != null) {
            setPackageContentConfig(JSON.parse(savedPackageContentConfig));
        }

        const savedCompactView = localStorageService.getItem(LocalStorageNamespace.PackageDetailCompactView);

        if (savedCompactView != null) {
            setCompactView(JSON.parse(savedCompactView) as boolean);
        }

        const notifier = new EventSourceNotifier({
                filters: [
                    {name: 'PackageUpdatedNotification', id: packageId}
                ],
                onMessage: () => {
                    if (skipSse.current) return;

                    // hodne udalosti spusti load jen jednou
                    if (loadPackageQueue.current !== undefined) {
                        clearTimeout(loadPackageQueue.current);
                    }

                    loadPackageQueue.current = setTimeout(() => {
                        reloadAllData();
                    }, 500);
                }
            }
        );

        notifier.open();

        if (appContext.user && appContext.user.mfaEnforceRequirement(applicationConfig!)) {
            // routesMap neni pozite schvalne ... shazuje apliakci
            // nechame dokud to nevyresime
            setTimeout(navigate.push, 200, "/multi-factor-key/list");
        }

        return function () {
            appContext.disableLangSelector = false;

            notifier.close();
        };
    }, []);

    useEffect(() => {
        localStorageService.setItem(LocalStorageNamespace.PackageDetailCompactView, JSON.stringify(compactView));
    }, [compactView]);

    const matches = location.pathname.match(/\/packages\/([0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12})/i);

    const urlPath = matches![0];

    useEffect(() => {
        const storedDownloadTokenInfo = localStorageService.getIndexedItem(LocalStorageNamespace.PackagePassword, packageId);

        if (storedDownloadTokenInfo != null) {
            const parsedDownloadTokenInfo = JSON.parse(storedDownloadTokenInfo);

            downloadTokenInfo.current = (isDownloadTokenInfoValid(parsedDownloadTokenInfo)) ? parsedDownloadTokenInfo : undefined;
        } else {
            downloadTokenInfo.current = undefined;
        }

        loadPackage();
    }, [packageId]);

    useEffect(() => {
        loadFolder(folderId || aPackage.rootFolderId);
    }, [aPackage, folderId]);

    useEffect(() => {
        setAllFiles([
            ...((packageContentConfig.visibleStates.includes(FileState.ACTIVE)) ? activeFiles : []),
            ...((packageContentConfig.visibleStates.includes(FileState.UNCLEAN)) ? uncleanFiles : []),
            ...((packageContentConfig.visibleStates.includes(FileState.DELETED)) ? deletedFiles : []),
        ]);
    }, [packageContentConfig]);

    const title = intlMessage("window-title");

    return (
        <DocumentTitle title={`${applicationConfig!.title}: ${title}`}>
            <div>
                <Skeleton loading={modelState === ModelState.Loading}>
                    {modelState === ModelState.Loaded ? renderLoaded() : ""}

                    {modelState === ModelState.AccessDenied ? renderAccessDenied() : ""}

                    {modelState === ModelState.NotFound ? renderNotFound() : ""}

                    {modelState === ModelState.TooManyRequests ? renderTooManyRequests() : ""}

                    {modelState === ModelState.PasswordRequired ?
                        <PackagePasswordRequired onSubmit={onPasswordSubmit}
                                                 enteredInvalidPassword={enteredInvalidPassword}
                                                 serverErrorMessage={serverErrorMessage}
                                                 passwordType={PackagePasswordType.Download}/> : ""}

                    {modelState === ModelState.LimitAccessConfirmRequired ? <PackageAccessConfirmRequired onSubmit={onAccessConfirm} accessToken={limitAccessToken.current}/> : ""}
                </Skeleton>

                {modal.visible && aPackage &&
                    <PackageModal visible={modal.visible}
                                  title={modal.title}
                                  editMode={modal.operation === ModalOperation.Update}
                                  model={aPackage}
                                  onOk={onModalSave}
                                  onCancel={onModalCancel}
                    />
                }

                {fileModalVisible &&
                    <FileModal visible={fileModalVisible}
                               title={fileModal.title}
                               package={aPackage}
                               file={fileModal.model}
                               editMode={true}
                               onOk={() => onFileEditDone()}
                               onCancel={() => setFileModalVisible(false)}/>
                }

                {setApproverModal.visible &&
                    <SetApproverModal visible={setApproverModal.visible}
                                      title={setApproverModal.title}
                                      type={"PACKAGE"}
                                      package={aPackage}
                                      onOk={onSetApproverModalSave}
                                      onCancel={onSetApproverModalCancel}/>
                }

                <PackageContentConfigModal
                    open={packageContentConfigModalVisible}
                    value={packageContentConfig}
                    onCancel={() => setPackageContentConfigModalVisible(false)}
                    onChange={config => {
                        setPackageContentConfigModalVisible(false);

                        setPackageContentConfig(config);

                        localStorageService.setItem(LocalStorageNamespace.PackageContentConfig, JSON.stringify(config));
                    }}
                />
            </div>
        </DocumentTitle>
    )

    function onSetApproverModalCancel() {
        resetSetApproverModalState();
    }

    function onSetApproverModalSave() {
        resetSetApproverModalState();

        loadPackage();
    }

    function onFileEditDone() {
        setFileModalVisible(false);
    }

    function onModalCancel() {
        resetModalState();
    }

    function onModalSave() {
        resetModalState();

        loadPackage();
    }

    function resetModalState() {
        setModal(prevState => {
            return ({
                ...prevState,
                visible: false,
                title: "",
                operation: ModalOperation.Update,
                model: {}
            });
        });
    }

    function resetSetApproverModalState() {
        setSetApproverModal(prevState => {
            return ({
                ...prevState,
                visible: false,
                title: "",
                operation: ModalOperation.Update,
                model: {}
            });
        });
    }

    function renderAllMimeTypes() {

        const setOfTypes = allMimeTypes
            ?.map((value: any) => value.type)
            .reduce((acc: any, value: any) => acc.includes(value) ? acc : acc.concat(value), []);

        const detections: any = activeFileCheck?.detectionName?.replace("=", " - ").split(",");

        return (
            <div style={{marginTop: -16}}>
                <Table dataSource={detections} rowKey={(record: any) => record} pagination={false}
                       className={styles['detail-heading']}
                       showSorterTooltip={false}
                       title={() => intlMessage("package-detail.mime-blocked-files")}
                       size="small" style={{marginTop: 8, marginBottom: 8}}>
                    <Column dataIndex={""} title={intlMessage("package-detail.mime-blocked-files.file__mime")}/>
                </Table>

                <Table dataSource={setOfTypes} rowKey={(record: any) => record} pagination={false}
                       className={styles['detail-heading']}
                       showSorterTooltip={false}
                       title={() => intlMessage("package-detail.all-mime-types.mime-list")}
                       size="small" style={{marginTop: 8, marginBottom: 8}}>
                    <Column dataIndex={""} title={intlMessage("package-detail.file.mimeType")}/>
                </Table>

                <Table dataSource={allMimeTypes} rowKey="name" pagination={false}
                       className={styles['detail-heading']}
                       showSorterTooltip={false}
                       title={() => intlMessage("package-detail.all-mime-types.object-list")}
                       size="small" style={{marginTop: 8, marginBottom: 8}}>
                    <Column dataIndex="name" title={intlMessage("package-detail.file.name")}/>
                    <Column dataIndex="type" title={intlMessage("package-detail.file.mimeType")}/>
                </Table>
            </div>
        )
    }

    function packageApproved() {
        return !aPackage.approveRequired || (aPackage.approveRequired && aPackage.approved);
    }

    function renderActions() {
        if (!aPackage || !appContext.user) {
            return;
        }

        const menuItems: MenuItemType[] = [];

        if (canAddFilesToPackage()) {
            menuItems.push({
                key: 'add-files',
                label: <Button
                    onClick={addFiles}
                    type="text">
                    <FileAddOutlined/>
                    {intlMessage("common.add-files-to-package")}
                </Button>
            });
        }

        // own packages
        // outbox
        if (ownPackage) {
            // active and clean or scan running
            if (packageApproved() && aPackage.workflowState === PackageWorkflowState.ACTIVE && (aPackage.checkState === PackageCheckState.CLEAN || aPackage.checkState === PackageCheckState.UNKNOWN)) {
                if (appContext.user.hasPermission(UserPermission.SEND_PACKAGE) && aPackage.accessType !== PackageAccessType.BRIEFCASE) {
                    menuItems.push({
                        key: 'add-recipient',
                        label: <Button onClick={(e: any) => onAddRecipient(aPackage, e)}
                                       type="text">
                            <MailFilled/>
                            {intlMessage("common.add-recipients")}
                        </Button>
                    })
                }

                menuItems.push({
                    key: 'edit',
                    label: <Button onClick={(e: any) => onEdit(aPackage, e)}
                                   type="text">
                        <EditFilled/>
                        {intlMessage("common.edit")}
                    </Button>
                });

                if (aPackage.accessType !== PackageAccessType.BRIEFCASE) {
                    menuItems.push({
                        key: 'resend-notifications',
                        label: <OkLeftPopconfirm
                            title={intlMessage("packages-inbox-outbox.confirm-resend-notifications", {packageName: aPackage.name ? aPackage.name : aPackage.id})}
                            onConfirm={() => onResendNotificationsConfirm(aPackage)}
                            okText={intlMessage("common.yes")}
                            cancelText={intlMessage("common.no")}>

                            <Button type={"text"}>
                                <MailFilled/>
                                {intlMessage("packages-inbox-outbox.resend-notifications")}
                            </Button>
                        </OkLeftPopconfirm>
                    });
                }
            }

            if (packageApproved() &&
                ((aPackage.workflowState === PackageWorkflowState.ACTIVE && (aPackage.checkState === PackageCheckState.CLEAN || aPackage.checkState === PackageCheckState.UNKNOWN)) ||
                    aPackage.workflowState === PackageWorkflowState.REQUESTED)) {

                menuItems.push({
                    key: 'delete',
                    label: <OkLeftPopconfirm
                        title={intlMessage("packages-inbox-outbox.confirm-delete", {packageName: aPackage.name ? aPackage.name : aPackage.id})}
                        onConfirm={() => onDeleteConfirm(aPackage)}
                        okText={intlMessage("common.yes")}
                        cancelText={intlMessage("common.no")}>
                        <Button type="text">
                            <DeleteFilled/>
                            {intlMessage("common.delete")}
                        </Button>
                    </OkLeftPopconfirm>
                });
            }
        } else {
            // inbox
            if (packageApproved() &&
                (ownPackage || recipientPackage) &&
                aPackage.workflowState === PackageWorkflowState.ACTIVE && aPackage.checkState === PackageCheckState.CLEAN &&
                aPackage.accessType !== PackageAccessType.BRIEFCASE
            ) {
                menuItems.push({
                    key: 'delete',
                    label: <OkLeftPopconfirm
                        title={intlMessage("packages-inbox-outbox.confirm-delete", {packageName: aPackage.name ? aPackage.name : aPackage.id})}
                        onConfirm={() => onRemoveRecipientConfirm(aPackage)}
                        okText={intlMessage("common.yes")}
                        cancelText={intlMessage("common.no")}>
                        <Button type="text">
                            <DeleteFilled/>
                            {intlMessage("common.delete")}
                        </Button>
                    </OkLeftPopconfirm>
                });
            }

            // inbox - vyzvy
            if (aPackage.workflowState === PackageWorkflowState.REQUESTED && recipientPackage) {
                menuItems.push({
                    key: 'delete',
                    label: <OkLeftPopconfirm
                        title={intlMessage("packages-inbox-outbox.confirm-delete", {packageName: aPackage.name ? aPackage.name : aPackage.id})}
                        onConfirm={() => onDeleteConfirm(aPackage)}
                        okText={intlMessage("common.yes")}
                        cancelText={intlMessage("common.no")}>
                        <Button type="text">
                            <DeleteFilled/>
                            {intlMessage("common.delete")}
                        </Button>
                    </OkLeftPopconfirm>
                });

                menuItems.push({
                    key: 'resend-notifications',
                    label: <OkLeftPopconfirm
                        title={intlMessage("packages-inbox-outbox.confirm-resend-notifications", {packageName: aPackage.name ? aPackage.name : aPackage.id})}
                        onConfirm={() => onResendNotificationsConfirm(aPackage)}
                        okText={intlMessage("common.yes")}
                        cancelText={intlMessage("common.no")}>

                        <Button className={"ant-btn-icon-only"}>
                            <MailFilled/>
                            {intlMessage("packages-inbox-outbox.resend-notifications")}
                        </Button>
                    </OkLeftPopconfirm>
                });
            }
        }

        // active clean
        if (packageApproved() && aPackage.workflowState === PackageWorkflowState.ACTIVE && (aPackage.checkState === PackageCheckState.CLEAN) && (ownPackage || recipientPackage)) {
            if (appContext.user.hasPermission(UserPermission.SEND_PACKAGE) && aPackage.activeFileCount! > 0 && aPackage.limitAccessCount === 0) {
                menuItems.push({
                    key: 'forward',
                    label: <Button
                        onClick={() => navigate.push("/package-forward/" + aPackage.id!)}
                        type="text">
                        <ForwardFilled/>
                        {intlMessage("common.forward-package")}
                    </Button>
                });
            }
        }

        if (aPackage.approveRequired && aPackage.canBeApproved) {
            if (!aPackage.approved) {
                menuItems.push({
                    key: 'approve',
                    label: <OkLeftPopconfirm
                        title={intlMessage("packages-inbox-outbox.confirm-approve", {packageName: aPackage.name ? aPackage.name : aPackage.id})}
                        onConfirm={() => onApproveConfirm(aPackage)}
                        okText={intlMessage("common.yes")}
                        cancelText={intlMessage("common.no")}>
                        <Button type="text">
                            <CheckCircleOutlined/>
                            {intlMessage("common.approve")}
                        </Button>
                    </OkLeftPopconfirm>
                });
            }

            if (!aPackage.disapproved) {
                menuItems.push({
                    key: 'disapprove',
                    label: <OkLeftPopconfirm
                        title={intlMessage("packages-inbox-outbox.confirm-disapprove", {packageName: aPackage.name ? aPackage.name : aPackage.id})}
                        onConfirm={() => onDisapproveConfirm(aPackage)}
                        okText={intlMessage("common.yes")}
                        cancelText={intlMessage("common.no")}>
                        <Button type="text">
                            <CloseCircleOutlined/>
                            {intlMessage("common.disapprove")}
                        </Button>
                    </OkLeftPopconfirm>
                });
            }
        }

        if (aPackage.canSetApprover) {
            menuItems.push({
                key: 'set-approver',
                label: <Button onClick={() => onSetApprovers(aPackage)}
                               type="text">
                    <MailFilled/>
                    {intlMessage("package-modal.set-approver.title")}
                </Button>
            });
        }

        if (ownPackage && aPackage.workflowState === PackageWorkflowState.ACTIVE && aPackage.checkState === PackageCheckState.CLEAN && appContext.user?.hasPermission(UserPermission.SET_PACKAGE_PERSISTENT)) {
            if (aPackage.persistent) {
                menuItems.push({
                    key: 'set-persistent',
                    label: <OkLeftPopconfirm
                        title={intlMessage("packages-list.confirm-set-temporary", {packageName: aPackage.name ? aPackage.name : aPackage.id})}
                        onConfirm={() => onUnsetPersistentPackageConfirm(aPackage)}
                        okText={intlMessage("common.yes")}
                        cancelText={intlMessage("common.no")}>
                        <Button type="text">
                            <PushpinFilled/>
                            {intlMessage("package.set-temporary")}
                        </Button>
                    </OkLeftPopconfirm>
                });
            } else {
                menuItems.push({
                    key: 'set-persistent',
                    label: <OkLeftPopconfirm
                        title={intlMessage("packages-list.confirm-set-persistent", {packageName: aPackage.name ? aPackage.name : aPackage.id})}
                        onConfirm={() => onSetPersistentPackageConfirm(aPackage)}
                        okText={intlMessage("common.yes")}
                        cancelText={intlMessage("common.no")}>
                        <Button type="text">
                            <PushpinOutlined/>
                            {intlMessage("package.set-persistent")}
                        </Button>
                    </OkLeftPopconfirm>
                });
            }
        }

        menuItems.push({
            key: 'switch-view',
            label: <Button
                type={"text"}
                onClick={() => setCompactView(prevState => !prevState)}>
                {(compactView) ? <ArrowsAltOutlined/> : <ShrinkOutlined/>} {intlMessage("switch-view")}
            </Button>
        });

        const cleanOrQuarantinedAndAllowed = aPackage.checkState === PackageCheckState.CLEAN || (aPackage.checkState === PackageCheckState.QUARANTINED && applicationConfig!.allowDownloadCleanFilesFromQuaratine);

        if (aPackage.workflowState === PackageWorkflowState.ACTIVE && aPackage.anyCleanFile && cleanOrQuarantinedAndAllowed) {
            const isPublicPackage = aPackage.accessType === PackageAccessType.PUBLIC

            const fileArchivePath = `/download-archive/${aPackage.id}`;

            let downloadArchiveButton =
                <Button type={"text"}
                        onClick={e => checkPackageAndLoginPasswordForDownload(fileArchivePath, packageId, DownloadObjectType.ACHIVE, {})}>
                    <DownloadOutlined/> {intlMessage("package-detail.archive.download-action")}
                </Button>;

            if (!isPublicPackage && !appContext?.user?.hasPermission(UserPermission.ALLOW_DOWNLOAD_WITHOUT_LOGIN)) {
                downloadArchiveButton = <Button type={"text"}
                                                onClick={() => promptForLoginPassword(fileArchivePath, aPackage.id!, DownloadObjectType.ACHIVE)}>
                    <DownloadOutlined/> {intlMessage("package-detail.archive.download-action")}
                </Button>;
            }

            menuItems.push({
                key: 'archive-download',
                label: downloadArchiveButton
            });

        }

        if (menuItems.length > 0) {
            const menu: MenuProps = {
                className: "more-actions",
                items: menuItems
            };

            return (
                <Dropdown menu={menu} trigger={['click']}>
                    <a className="ant-dropdown-link ant-btn ant-btn-icon-only ant-btn-default" title={intlMessage("common.actions")}>

                        <BarsOutlined style={{verticalAlign: 'middle'}}/>
                        &nbsp;
                        {intlMessage("common.action")}
                    </a>
                </Dropdown>
            );
        }
    }

    function onSetPersistentPackageConfirm(record: Package) {
        packageService.setPersistentPackage(record)
            .then(() => {
                message.success(intlMessage("package.marked-as-persistent", {packageName: record.name ? record.name : record.id}));
                loadPackage();
            });
    }

    function addFiles() {
        localStorageService.setIndexedItem(LocalStorageNamespace.PackageDetail, packageId, JSON.stringify(aPackage));

        navigate.push(`/package/${aPackage.id}/add-files/${folderIndex?.folder?.id || aPackage.rootFolderId}`);
    }

    function onUnsetPersistentPackageConfirm(record: Package) {
        packageService.unsetPersistentPackage(record)
            .then(() => {
                message.success(intlMessage("package.marked-as-temporary", {packageName: record.name ? record.name : record.id}));
                loadPackage();
            });
    }

    function onResendNotificationsConfirm(record: Package) {
        packageService.resendNotifications(record)
            .then(() => {
                message.success(intlMessage('package.notifications-resent', {packageName: record.name ? record.name : record.id}));
                loadPackage();
            });
    }

    function onApproveConfirm(record: Package) {
        packageService.approve(record)
            .then(() => {
                message.success(intlMessage('packages-inbox-outbox.approved', {packageName: record.name ? record.name : record.id}));
                loadPackage();
            });
    }

    function onDisapproveConfirm(record: Package) {
        packageService.disapprove(record)
            .then(() => {
                message.success(intlMessage('packages-inbox-outbox.disapproved', {packageName: record.name ? record.name : record.id}));
                loadPackage();
            });
    }

    function onSetApprovers(record: Package) {
        setSetApproverModal(prevState => {
            return ({
                ...prevState,
                visible: true,
                title: intlMessage("package-modal.set-approver.title", {name: record.name ? record.name : record.id}),
                operation: ModalOperation.Add,
                model: record
            });
        });
    }

    // odstrani prihlaseneho uzivatele z prijemcu
    function onRemoveRecipientConfirm(record: Package) {
        packageService.removeRecipient(record)
            .then(() => {
                message.success(intlMessage('package.deleted', {packageName: record.name ? record.name : record.id}));

                navigate.goBack();
            });
    }

    function onEdit(record: Package, e?: React.MouseEvent) {
        e!.preventDefault();
        setModal(prevState => {
            return ({
                ...prevState,
                visible: true,
                title: intlMessage("package-edit.title", {name: record.name ? record.name : record.id}),
                operation: ModalOperation.Update,
                model: record
            });
        });
    }

    function onEditFolder(folder: Folder) {
        setRenameFolderModalVisible(true);
        setSelectedFolder(folder);
    }

    function onEditFile(record: File) {
        setFileModalVisible(true);
        setFileModal(prevState => {
            return ({
                ...prevState,
                visible: true,
                title: intlMessage("package-file-edit.title", {name: record.name ? record.name : record.id}),
                operation: ModalOperation.Update,
                model: record
            });
        });
    }

    function onAddRecipient(record: Package, e?: React.MouseEvent) {
        e!.preventDefault();

        setModal(prevState => {
            return ({
                ...prevState,
                visible: true,
                title: intlMessage("package-add-recipient.title", {name: record.name ? record.name : record.id}),
                operation: ModalOperation.Add,
                model: record
            });
        });
    }

    function onDeleteConfirm(record: Package) {
        skipSse.current = true;

        packageService.delete(record)
            .then(() => {
                message.success(intlMessage("package.deleted", {packageName: record.name ? record.name : record.id}));

                navigate.goBack();
            });
    }

    function onDeleteFolderConfirm(folder: Folder) {
        folderService.delete(folder.id!)
            .then(() => {
                message.success(intlMessage("package-folder-delete.deleted", {name: folder.name}));

                if (folderIndex?.folder.id === folder.id) {
                    navigate.push(`/packages/${packageId}/${folderIndex?.breadcrumbs[folderIndex?.breadcrumbs.length - 2].id}`);
                }

                reloadAllData();
            });
    }

    function onDeleteFileConfirm(record: File, deleteSafeCopy: boolean | undefined) {
        packageFileService.delete(packageId, record, deleteSafeCopy)
            .then(() => {
                message.success(intlMessage("package.file-deleted", {fileName: record.name}));

                reloadAllData();
            });
    }

    function renderUserFlags(pkg: Package) {

        if (!ownPackage && !recipientPackage) {
            return ("");
        }

        return (<>{pkg.userFlags?.flagged ?
            <a className={"button"} title={intlMessage("packages.unset-flag")} onClick={() => setFlagged(pkg, false)} style={{opacity: 1.0}}><StarFilled className={"filled"}/></a> :
            <a className={"button"} title={intlMessage("packages.set-flag")} onClick={() => setFlagged(pkg, true)}><StarOutlined className={"outlined"} style={{opacity: 0.5}}/></a>
        }
        </>);
    }

    function setFlagged(pkg: Package, value: boolean) {
        if (!pkg.userFlags) {
            pkg.userFlags = {flagged: false, viewed: false}
        }

        pkg.userFlags!.flagged = value;

        setTimeout(forceUpdate, 10)

        if (value) {
            packageService.setFlagged(pkg);
        } else {
            packageService.unsetFlagged(pkg);
        }
    }

    function renderTitle() {
        return (
            <div className={"package-flags"}>
                {renderUserFlags(aPackage)}

                {aPackage.persistent && <PushpinFilled className={"info"} title={intlMessage("persistent-package")}/>}

                {aPackage.passwordProtected ? <LockFilled title={intlMessage("password-protected")}/> : ""}

                {aPackage.flags!.map((flag) => {
                    const key = "" + aPackage.id + flag.toString();
                    switch (flag) {
                        case PackageFlag.ARCHIVED:
                            return <FolderAddFilled key={key} title={intlMessage("packages-inbox-outbox.flags." + flag)}/>
                        case PackageFlag.OVERSIZE:
                            return <ColumnHeightOutlined key={key} style={{color: "crimson"}} title={intlMessage("packages-inbox-outbox.flags." + flag)}/>;
                        case PackageFlag.ENCRYPTED_CONTENT:
                            return <FileUnknownFilled key={key} style={{color: "crimson"}} title={intlMessage("packages-inbox-outbox.flags." + flag)}/>;
                        case PackageFlag.FORWARDED:
                            return <ForwardFilled key={key} title={intlMessage("packages-inbox-outbox.flags." + flag)}/>;
                        case PackageFlag.COOPERATIVE:
                            return ownPackage && <ShareAltOutlined key={"" + aPackage.id + flag.toString()} className={"info"} title={intlMessage("packages-inbox-outbox.flags.COOPERATIVE")}/>;
                        case PackageFlag.DLP_MATCHED:
                        default:
                            return undefined;
                    }
                })}

                {aPackage.accessType === PackageAccessType.PUBLIC && aPackage.workflowState !== PackageWorkflowState.REQUESTED && <GlobalOutlined className={"info"} title={intlMessage("package-detail.public-access")}/>}
            </div>
        );
    }

    function renderLoaded() {
        const pkg = aPackage || {};

        const downloadLink = window.location.origin + (pkg ? urlPath : "/packages/");
        const uploadLink = window.location.origin + (pkg ? "/package-requested/" + packageId : "/packages/");

        const extra = renderTitle();
        const layout1 = {xs: 24, sm: 24, md: 10, lg: 10, xl: 10, xxl: 10};
        const layout2 = {xs: 24, sm: 24, md: 14, lg: 14, xl: 14, xxl: 14};

        return <>
            <h1 style={{display: "inline-flex"}}>
                {intlMessage("title", {id: aPackage.name ? aPackage.name : packageId})}

                <CopyToClipboard text={packageId} onCopy={() => message.info(intlMessage("package-detail.package-id-copied"))}>
                    <Button style={{marginLeft: 8}} title={intlMessage("package-detail.copy-id-title")} icon={<LinkOutlined/>} type={"text"} className={"copy-link"}/>
                </CopyToClipboard>

                <Button
                    title={intlMessage("switch-view")}
                    icon={(compactView) ? <ArrowsAltOutlined/> : <ShrinkOutlined/>}
                    type={"text"}
                    onClick={() => setCompactView(prevState => !prevState)}
                />
            </h1>

            <span style={{float: "right"}} className={"package-actions"}>
                {renderActions()}
            </span>

            <Row gutter={16}>
                <Col {...layout1} >
                    {aPackage.accessType === "BRIEFCASE" ?
                        <Card title={intlMessage("owner-title")} type="inner" className="card-sparse-rows package-detail">
                            <Row>
                                <Col span={6}>{intlMessage("owner")}:</Col>
                                <Col span={18}>{renderSender()}</Col>
                            </Row>
                        </Card>
                        :
                        <Card title={intlMessage("sender-recipients-title")} type="inner"
                              className="card-sparse-rows package-detail">
                            <Row>
                                <Col span={6}>{intlMessage("sender")}:</Col>
                                <Col span={18}>{renderSender()}</Col>
                            </Row>
                            {appContext.user && (aPackage.externalRecipients || aPackage.internalRecipients?.some(value => !value.contributor)) &&
                                <Row>
                                    <Col span={6}>{intlMessage("recipients")}:</Col>
                                    <Col span={18}>{renderRecipients(false)}</Col>
                                </Row>
                            }
                            {appContext.user && aPackage.internalRecipients?.some(value => value.contributor) &&
                                <Row>
                                    <Col span={6}>{intlMessage("contributors")}:</Col>
                                    <Col span={18}>{renderRecipients(true)}</Col>
                                </Row>
                            }
                        </Card>
                    }

                    {renderArchive()}
                </Col>

                <Col  {...layout2} >
                    <Card title={intlMessage("general-info-title")} type="inner"
                          className="card-sparse-rows package-detail" extra={extra}>
                        <Row>
                            <Col span={6}>{intlMessage("state")}:</Col>
                            <Col span={18} style={{fontWeight: 600}}>{renderPackageState()}</Col>
                        </Row>
                        <Row>
                            <Col span={6}>{intlMessage("package-access-type")}:</Col>
                            <Col span={18} style={{fontWeight: 600}}>{renderPackageAccess()}</Col>
                        </Row>

                        {!compactView && pkg.dataEncryptedWithPassword &&

                            <Row>
                                <Col span={6}>{intlMessage("encryption-status")}:</Col>
                                <Col span={18} style={{fontWeight: 600}}>{intlMessage("data-encrypted-with-password")}</Col>
                            </Row>
                        }
                        {!compactView && aPackage.workflowState !== PackageWorkflowState.REQUESTED &&
                            <Row>
                                <Col span={6}>{intlMessage("package-integrity")}:</Col>
                                <Col span={18} style={{fontWeight: 600}}>{renderPackageIntegrity()}</Col>
                            </Row>
                        }

                        <Row>
                            <Col span={6}>{intlMessage("uploaded")}:</Col>
                            <Col span={18}>{FormatUtils.formatOnDateAtTime(intl, pkg.uploaded!)}</Col>
                        </Row>
                        {!compactView &&
                            <Row>
                                <Col span={6}>{intlMessage("id")}:</Col>
                                <Col span={18}>{pkg.id}</Col>
                            </Row>
                        }
                        <Row>
                            <Col span={6}>{intlMessage("total-size")}:</Col>
                            <Col span={18}>{FormatUtils.formatBytes(pkg.activeSize)}</Col>
                        </Row>

                        {!compactView && pkg.passwordProtected &&
                            <Row>
                                <Col span={6}>{intlMessage("password-protected")}:</Col>
                                <Col span={18}>{pkg.passwordProtected ? intlMessage("common.yes") : intlMessage("common.no")}</Col>
                            </Row>

                        }

                        {!compactView && (appContext.applicationConfig?.sftpAccessAllowed === 'YES' || (appContext.applicationConfig?.sftpAccessAllowed === 'PERMISSION_BASED' && appContext.user?.hasPermission(UserPermission.SFTP_ACCESS_ALLOWED))) &&
                            <Row>
                                <Col span={6}>{intlMessage("sftp-url")}:</Col>
                                <Col span={18}>{renderSftpLink()}</Col>
                            </Row>
                        }

                        {!compactView && (aPackage.limitAccessCount !== undefined && aPackage.limitAccessCount > 0) &&
                            <Row>
                                <Col span={6}>{intlMessage("package-detail.package-with-limited-access-count")}:</Col>
                                <Col span={18}>{(aPackage.limitAccessCount) ? (intlMessage("common.yes") + " (" + (intlMessage("package-detail.access-limit-count-usage", {usedCount: aPackage.accessCount, limitCount: aPackage.limitAccessCount})) + ")") : intlMessage("common.no")}</Col>
                            </Row>
                        }

                        {!compactView &&
                            <Row>
                                {pkg.workflowState === PackageWorkflowState.REQUESTED ?

                                    <>
                                        <Col span={6}>{intlMessage("upload-link")}:</Col>
                                        <Col span={18}>
                                            {uploadLink}
                                            <CopyToClipboard text={uploadLink}
                                                             onCopy={() => message.info(intlMessage("link-copied"))}>
                                                <a>
                                                    <LinkOutlined
                                                        title={intlMessage("copy-link-title")}
                                                        style={{fontSize: "16px", marginLeft: "6px"}}/>
                                                </a>
                                            </CopyToClipboard>
                                        </Col>
                                    </> :
                                    <>
                                        <Col span={6}>{intlMessage("download-link")}:</Col>
                                        < Col span={18}>
                                            {downloadLink}
                                            <CopyToClipboard text={downloadLink}
                                                             onCopy={() => message.info(intlMessage("link-copied"))}>
                                                <a>
                                                    <LinkOutlined
                                                        title={intlMessage("copy-link-title")}
                                                        style={{fontSize: "16px", marginLeft: "6px"}}/>
                                                </a>
                                            </CopyToClipboard>
                                        </Col>
                                    </>
                                }
                            </Row>
                        }

                        {!compactView && !pkg.persistent && <p style={{marginTop: 8}}>{createNextWorkflowStepMessage()}</p>}
                    </Card>

                    {appContext.user && aPackage.approveRequired &&
                        <Card title={intlMessage("package-send-approve")} type="inner"
                              className="card-sparse-rows package-detail">
                            <>
                                {renderApproveState()}
                            </>
                        </Card>
                    }
                </Col>

            </Row>

            {pkg.noteFrom &&
                <Card className={"package-detail-note"}>
                    <div
                        className={"package-detail-note-label"}>{intlMessage('note' + (pkg.noteTo ? "-from" : ""))}:
                    </div>
                    {pkg.noteFrom && <div className={"package-detail-note-text"}>{pkg.noteFrom}</div>}
                </Card>
            }

            {pkg.noteTo &&
                <Card className={"package-detail-note"}>
                    <div className={"package-detail-note-label"}>{intlMessage("note-to")}:
                    </div>
                    {pkg.noteTo && <div className={"package-detail-note-text"}>{pkg.noteTo}</div>}
                </Card>
            }

            {aPackage.workflowState !== PackageWorkflowState.REQUESTED &&
                <Skeleton loading={folderIndex === undefined}>
                    <Spin spinning={folderLoading} indicator={<LoadingOutlined/>} fullscreen={false}>
                        <Tabs
                            size={"large"}
                            items={[
                                {
                                    key: 'content',
                                    label: intlMessage("tab-package-content"),
                                    children: renderFolderContent()
                                },
                                {
                                    key: 'deleted',
                                    label: intlMessage("tab-deleted"),
                                    children: renderDeletedContent()
                                },
                                {
                                    key: 'quarantined',
                                    label: intlMessage("tab-quarantined"),
                                    children: renderQuarantineContent()
                                }
                            ]}
                            onChange={activeKey => {
                                switch (activeKey) {
                                    case 'deleted':
                                        if (allDeletedFiles === undefined) {
                                            loadAllDeletedFiles();
                                        }

                                        break;

                                    case 'quarantined':
                                        if (allQuarantinedFiles === undefined) {
                                            loadAllQuarantinedFiles();
                                        }

                                        break;
                                }
                            }}
                        />
                    </Spin>
                </Skeleton>
            }

            <Drawer
                title={intlMessage("package-detail.reports-title")}
                width={800}
                onClose={onClose}
                open={reportsVisible}
                destroyOnClose={true}>
                {renderFileCheckReports()}
            </Drawer>

            <Drawer
                title={intlMessage("package-detail.all-mime-types.title")}
                width={800}
                onClose={() => setAllMimeTypesVisible(false)}
                open={allMimeTypesVisible}
                destroyOnClose={true}>
                {renderAllMimeTypes()}
            </Drawer>

            {
                downloadFileModal.visible &&

                <DownloadFileLoginModal
                    visible={downloadFileModal.visible}
                    onOk={onDownloadFileModalOk}
                    onCancel={onDownloadFileModalCancel}
                    url={downloadFileModal.url}
                    objectId={downloadFileModal.objectId}
                    objectType={downloadFileModal.objectType}/>
            }

            {
                packagePasswordModal.visible &&

                <DownloadPackagePasswordModal
                    visible={packagePasswordModal.visible}
                    packageId={packageId}
                    onDownloadTokenAcquired={packagePasswordModal.onDownloadTokenAcquired}
                    onOk={() => setPackagePasswordModal(prevState => ({...prevState, visible: false}))}
                    onCancel={() => setPackagePasswordModal(prevState => ({...prevState, visible: false}))}/>
            }

            {
                folderIndex?.folder && canAddFilesToPackage() &&

                <AddFolderModal
                    visible={addFolderModalVisible}
                    folderId={folderIndex.folder.id!}
                    onOk={() => {
                        setAddFolderModalVisible(false);

                        loadFolder(folderIndex?.folder.id);
                    }}
                    onCancel={() => setAddFolderModalVisible(false)}
                />
            }

            {
                aPackage.canDeleteOrRenameFilesInPackage &&

                <>
                    <RenameFolderModal
                        visible={renameFolderModalVisible}
                        folder={selectedFolder}
                        onOk={() => {
                            setRenameFolderModalVisible(false);

                            loadFolder(folderIndex?.folder.id);
                        }}
                        onCancel={() => setRenameFolderModalVisible(false)}
                    />

                    <MoveModal
                        packageId={packageId}
                        initialFolderId={aPackage.rootFolderId}
                        visible={moveModalVisible}
                        inProgress={moveInProgress}
                        items={itemsToMove}
                        onOk={(folder, conflictResolution) => {
                            const folderIds = itemsToMove.filter(value => value instanceof Folder).map(value => value.id);
                            const fileIds = itemsToMove.filter(value => value instanceof File).map(value => value.id);

                            setMoveInProgress(true);

                            folderService.moveItems(folder.id, folderIds, fileIds, conflictResolution)
                                .then(value => {
                                    var folderName = (folder.name === '/') ? intlMessage("root-folder") : folder.name;

                                    message.info(intlMessage("items-moved", {folder: folderName, items: folderIds.length + fileIds.length}));

                                    setMoveModalVisible(false);
                                    setItemsToMove([]);

                                    moveDonePromiseRef.current?.();

                                    loadFolder(folderIndex?.folder.id);
                                })
                                .finally(() => setMoveInProgress(false));

                        }}
                        onCancel={() => {
                            setMoveModalVisible(false);
                            setItemsToMove([]);
                        }}
                    />
                </>
            }

        </>;
    }

    function renderFolderContent() {
        return <>
            <Breadcrumb className={styles['folder-breadcrumb']}>
                {folderIndex?.breadcrumbs.map(path => <Breadcrumb.Item key={path.id}>{renderFolderName(path, true)}</Breadcrumb.Item>)}
            </Breadcrumb>

            <Button title={intlMessage("package-content-config.title")} type={"text"} icon={<SettingOutlined/>} onClick={() => setPackageContentConfigModalVisible(true)} style={{float: "right"}}/>

            {packageContentConfig.showAs === ShowAs.UNIFIED &&
                <>
                    {
                        canAddFilesToPackage() && <>
                            <div className={styles['unified-package-actions']}>
                                <Button type="primary" icon={<PlusOutlined/>} onClick={addFiles}>{intlMessage("add-files")}</Button>

                                <Button
                                    type="primary"
                                    icon={<FolderAddOutlined/>}
                                    onClick={() => setAddFolderModalVisible(true)}>
                                    {intlMessage("create-folder")}
                                </Button>
                            </div>
                        </>
                    }

                    {allFiles.length > 0 &&
                        <FolderContent
                            {...commonFolderContentProperties}
                            items={allFiles}
                            visualizeState={true}
                            visibleColumns={[
                                ColumnName.SELECT,
                                ColumnName.NAME,
                                ColumnName.STATE,
                                ColumnName.HASH,
                                ColumnName.MIME_TYPE,
                                ColumnName.UPLOADED_BY,
                                ColumnName.UPLOADED,
                                ColumnName.DELETED,
                                ColumnName.SIZE,
                                ...((appContext.user) ? [ColumnName.DOWNLOAD_COUNTER] : []),
                                ...((aPackage.canDeleteOrRenameFilesInPackage) ? [ColumnName.ACTION] : [])
                            ]}
                            integrityCheckAllowed={integrityCheckAllowed()}
                            isFileDownloadAllowed={isFileDownloadAllowed}
                            onClickDownload={checkPackageAndLoginPasswordForDownload}
                            onEditFolder={onEditFolder}
                            onDeleteFolderConfirm={onDeleteFolderConfirm}
                            onEditFile={onEditFile}
                            onDeleteFileConfirm={onDeleteFileConfirm}
                            onRunIntegrityCheckOnFile={(file) => checkPackageAndLoginPassword(() => onRunFileIntegrityCheckConfirm(file))}
                            onClickFileCheck={(file) => showReports(file)}
                            onClickExtraFileCheckInfo={(extraInfo) => {
                                setAllMimeTypesVisible(true);
                                setAllMimeTypes(extraInfo?.mimeTypes);
                            }}
                            massActions={[MassActions.MOVE, MassActions.DOWNLOAD_AS_ZIP, MassActions.DELETE]}
                        />
                    }
                </>
            }

            {packageContentConfig.showAs === ShowAs.SEPARATE &&
                <>
                    {packageContentConfig.visibleStates.includes(FileState.ACTIVE) && <>
                        <h2 style={{paddingTop: 16}}>
                            {intlMessage("package-detail.active-files")}

                            {
                                canAddFilesToPackage() && <>
                                    <span className={"package-actions"}>
                                        <Button type="primary" icon={<PlusOutlined/>} onClick={addFiles}>{intlMessage("common.add")}</Button>

                                        <Button
                                            type="primary"
                                            icon={<FolderAddOutlined/>}
                                            onClick={() => setAddFolderModalVisible(true)}>
                                            {intlMessage("create-folder")}
                                        </Button>
                                    </span>

                                </>
                            }
                        </h2>

                        {activeFiles.length > 0 &&
                            <FolderContent
                                {...commonFolderContentProperties}
                                items={activeFiles}
                                visibleColumns={[
                                    ColumnName.SELECT,
                                    ColumnName.NAME,
                                    ColumnName.STATE,
                                    ColumnName.HASH,
                                    ColumnName.MIME_TYPE,
                                    ColumnName.UPLOADED_BY,
                                    ColumnName.UPLOADED,
                                    ColumnName.SIZE,
                                    ...((appContext.user) ? [ColumnName.DOWNLOAD_COUNTER] : []),
                                    ...((aPackage.canDeleteOrRenameFilesInPackage) ? [ColumnName.ACTION] : [])
                                ]}
                                integrityCheckAllowed={integrityCheckAllowed()}
                                isFileDownloadAllowed={isFileDownloadAllowed}
                                onClickDownload={checkPackageAndLoginPasswordForDownload}
                                onEditFolder={onEditFolder}
                                onDeleteFolderConfirm={onDeleteFolderConfirm}
                                onEditFile={onEditFile}
                                onDeleteFileConfirm={onDeleteFileConfirm}
                                onRunIntegrityCheckOnFile={(file) => checkPackageAndLoginPassword(() => onRunFileIntegrityCheckConfirm(file))}
                                onClickFileCheck={(file) => showReports(file)}
                                onClickExtraFileCheckInfo={(extraInfo) => {
                                    setAllMimeTypesVisible(true);
                                    setAllMimeTypes(extraInfo?.mimeTypes);
                                }}
                                massActions={[MassActions.MOVE, MassActions.DOWNLOAD_AS_ZIP, MassActions.DELETE]}
                            />
                        }
                    </>}

                    {packageContentConfig.visibleStates.includes(FileState.UNCLEAN) && uncleanFiles.length > 0 &&
                        <>
                            <h2>{intlMessage("package-detail.unclean-files")}</h2>

                            <FolderContent
                                {...commonFolderContentProperties}
                                folders={[]}
                                items={uncleanFiles}
                                visibleColumns={[
                                    ColumnName.SELECT,
                                    ColumnName.NAME,
                                    ColumnName.STATE,
                                    ColumnName.HASH,
                                    ColumnName.MIME_TYPE,
                                    ColumnName.UPLOADED,
                                    ColumnName.SIZE,
                                    ...((appContext.user) ? [ColumnName.DOWNLOAD_COUNTER] : [])
                                ]}
                                integrityCheckAllowed={false}
                                isFileDownloadAllowed={() => false}
                                onClickFileCheck={(file) => showReports(file)}
                                onClickExtraFileCheckInfo={(extraInfo) => {
                                    setAllMimeTypesVisible(true);
                                    setAllMimeTypes(extraInfo?.mimeTypes);
                                }}
                                massActions={[MassActions.MOVE, MassActions.DOWNLOAD_AS_ZIP, MassActions.DELETE]}
                            />
                        </>
                    }

                    {appContext.user && packageContentConfig.visibleStates.includes(FileState.DELETED) && deletedFiles.length > 0 &&
                        <>
                            <h2 style={{paddingTop: 16}}>{intlMessage("package-detail.deleted-files")}</h2>

                            <FolderContent
                                {...commonFolderContentProperties}
                                items={deletedFiles}
                                visibleColumns={[
                                    ColumnName.NAME,
                                    ColumnName.STATE,
                                    ColumnName.HASH,
                                    ColumnName.MIME_TYPE,
                                    ColumnName.UPLOADED_BY,
                                    ColumnName.UPLOADED,
                                    ColumnName.DELETED,
                                    ColumnName.SIZE,
                                    ...((appContext.user) ? [ColumnName.DOWNLOAD_COUNTER] : [])
                                ]}
                                integrityCheckAllowed={false}
                                isFileDownloadAllowed={() => false}
                                onClickFileCheck={(file) => showReports(file)}
                                onClickExtraFileCheckInfo={(extraInfo) => {
                                    setAllMimeTypesVisible(true);
                                    setAllMimeTypes(extraInfo?.mimeTypes);
                                }}
                            />
                        </>
                    }
                </>
            }

            {activeFiles.length === 0 && uncleanFiles.length === 0 && deletedFiles.length === 0 && <>
                <Empty style={{margin: 48}} description={<>
                    {intlMessage((canAddFilesToPackage() ? "folder-is-empty" : "folder-is-empty-plain"), {
                        folder: (chunk: any) => <a onClick={() => setAddFolderModalVisible(true)}>{chunk}</a>,
                        files: (chunk: any) => <a onClick={addFiles}>{chunk}</a>
                    })}
                </>}/>
            </>}
        </>;
    }

    function renderDeletedContent() {
        return <>
            <Skeleton loading={allDeletedFiles === undefined} style={{paddingTop: 16}}>

                <FolderContent
                    {...commonFolderContentProperties}
                    folders={[]}
                    items={allDeletedFiles}
                    disableFolderNameLinks={true}
                    visibleColumns={[
                        ColumnName.NAME,
                        ColumnName.PATH,
                        ColumnName.STATE,
                        ColumnName.UPLOADED,
                        ColumnName.SIZE
                    ]}
                    loading={allDeletedLoading}
                />
            </Skeleton>
        </>;
    }

    function renderQuarantineContent() {
        return <>
            <Skeleton loading={allQuarantinedFiles === undefined} style={{paddingTop: 32}}>

                <FolderContent
                    {...commonFolderContentProperties}
                    folders={[]}
                    items={allQuarantinedFiles}
                    visibleColumns={[
                        ColumnName.NAME,
                        ColumnName.PATH,
                        ColumnName.UPLOADED,
                        ColumnName.SIZE
                    ]}
                    loading={allQuarantinedLoading}
                />
            </Skeleton>
        </>;
    }

    function renderFileCheckReports() {
        if (fileCheck?.fileCheckReports || fileCheck?.extraInfo) {

            // fileCheck.
            const extraInfo = fileCheck.extraInfo ? renderFileCheckExtraInfo(fileCheck.id, fileCheck.extraInfo) : null;

            return (<>
                {extraInfo &&
                    <div style={{paddingTop: "16px"}}>
                        <h3>{intlMessage("additional-information")}</h3>
                        {extraInfo}
                    </div>
                }
            </>);
        }
    }

    function renderFileCheckExtraInfo(id: any, extraInfo: FileCheck) {
        return (
            <Table dataSource={DataUtils.convertObjectToKeyValueArray(extraInfo)} rowKey={"key"} pagination={false}
                   showSorterTooltip={false}
                   size="small" showHeader={true}>
                <Column dataIndex="key" title={intlMessage("extra-info-key")}/>
                <Column dataIndex="value" title={intlMessage("extra-info-value")} className={"text-overflow max-width-500px"}/>
            </Table>
        )
    }

    function onFileCheckRow(record: FileCheck) {
        if (record && record.fileCheckReports.length > 0) {
            return {onDoubleClick: () => showReports(record)};
        } else if (record && record.extraInfo?.mimeTypes) {
            return {
                onDoubleClick: () => {
                    setAllMimeTypesVisible(true);
                    setAllMimeTypes(record.extraInfo?.mimeTypes);
                    setActiveFileCheck(record);
                }
            };
        } else {
            return {};
        }
    }

    function showReports(record: FileCheck) {
        setReportsVisible(true);
        setFileCheck(record);
    }

    function onClose() {
        setReportsVisible(false);
    }

    function renderPreferredApprovers(approvers: Approver[]) {
        return approvers.map((approver, index) => {
            return <span key={index}>{renderApproverName(approver)}{index < approvers.length - 1 ? ", " : ""}</span>
        });
    }

    function renderApproveState() {
        if (appContext.user && aPackage.approveRequired) {

            const approver = (aPackage.approved || aPackage.disapproved) ? renderApproverName(aPackage.approverAdmin ? aPackage.approverAdmin : aPackage.approverUser) : ""

            let approveState = <><QuestionCircleFilled className={"color-orange"}/><b>{intlMessage("waiting-for-approve")}</b></>;
            if (aPackage.approved) {
                approveState = <><CheckCircleFilled className={"color-green"}/><b>{intlMessage("approved")}</b>-{approver}</>
            }
            if (aPackage.disapproved) {
                approveState = <><CloseCircleFilled className={"color-red"}/><b>{intlMessage("disapproved")}</b>-{approver}</>
            }

            return (
                <>
                    <Row>
                        <Col span={6}>{intlMessage("approve-state")}:</Col>
                        <Col span={18}><Space>{approveState}</Space></Col>
                    </Row>

                    {aPackage.approvers &&
                        <Row>
                            <Col span={6}>{intlMessage("preferred-approvers")}:</Col>
                            <Col span={18}>{renderPreferredApprovers(aPackage.approvers)}</Col>
                        </Row>
                    }

                    {aPackage.approved &&
                        <Row>
                            <Col span={6}>{intlMessage("approved")}:</Col>
                            <Col span={18}>{FormatUtils.formatOnDateAtTime(intl, aPackage.approved)} - {renderApproverName(aPackage.approverAdmin ? aPackage.approverAdmin : aPackage.approverUser)}</Col>
                        </Row>
                    }
                    {aPackage.disapproved &&
                        <Row>
                            <Col span={6}>{intlMessage("disapproved")}:</Col>
                            <Col span={18}>{FormatUtils.formatOnDateAtTime(intl, aPackage.disapproved)} - {renderApproverName(aPackage.approverAdmin ? aPackage.approverAdmin : aPackage.approverUser)}</Col>
                        </Row>
                    }
                </>
            );
        }
    }

    function integrityCheckAllowed(): boolean {
        if (aPackage.encryptionStatus === EncryptionStatus.ENCRYPTION_IN_PROGRESS || aPackage.encryptionStatus === EncryptionStatus.DECRYPTION_IN_PROGRESS) {
            return false;
        }

        if (appContext.applicationConfig?.integrityCheckForUsers && aPackage.workflowState === PackageWorkflowState.ACTIVE) {
            return true;
        } else {
            return false;
        }
    }

    function onRunFileIntegrityCheckConfirm(file: File) {
        packageService.checkFileIntegrity(packageId, file, downloadTokenInfo.current?.passwordToken).then(() => {
            message.success(intlMessage("packages-detail.file-integrity-check-started", {fileName: file.name}));

            loadPackage();
        });
    }

    function onRunPackageIntegrityCheckConfirm(aPackage: Package) {
        packageService.checkPackageIntegrity(aPackage, downloadTokenInfo.current?.passwordToken).then(() => {
            message.success(intlMessage("packages-detail.integrity-check-on-package-started", {packageName: aPackage.name ? aPackage.name : aPackage.id}));

            loadPackage();
        });
    }

    function onDownloadFileModalCancel() {
        resetDownloadFileModalState();
    }

    function onDownloadFileModalOk() {
        resetDownloadFileModalState();
    }

    function resetDownloadFileModalState() {
        setDownloadFileModal({
            visible: false,
            url: "",
            objectId: "",
            objectType: DownloadObjectType.FILE,
            packageDownloadToken: ""
        });
    }

    function renderPackageState() {
        const workflowState = intlMessage("PackageWorkflowState." + aPackage.workflowState);
        const checkState = intlMessage("PackageCheckState." + aPackage.checkState);

        let resultState;

        if (aPackage.workflowState === PackageWorkflowState.REQUESTED || aPackage.workflowState === PackageWorkflowState.UPLOADING) {
            resultState = workflowState;
        } else {
            resultState = workflowState + " - " + checkState;
        }

        return (
            `${resultState}`
        )
    }

    function renderPackageIntegrity() {

        let packageIntegrityCheckRunning = false;

        // TODO
        // for (const file of aPackage.files!) {
        //     if (!file.contentDeleted) {
        //         if (file.integrityCheckStarted) {
        //             packageIntegrityCheckRunning = true;
        //         }
        //     }
        // }

        let result;
        switch (aPackage.dataIntegrity) {
            case PackageDataIntegrity.VALID:
                result = (<>
                    <CheckCircleFilled className={"color-green"}/>
                    {intlMessage("package-detail.package-integrity.VALID")}
                </>);
                break;
            case PackageDataIntegrity.INVALID:
                result = (<>
                    <ExclamationCircleFilled className={"color-red"}/>
                    {intlMessage("package-detail.package-integrity.INVALID")}
                </>);
                break;
            case PackageDataIntegrity.INACCESSIBLE:
                result = (<>
                    <ExclamationCircleFilled className={"color-orange"}/>
                    {intlMessage("package-detail.package-integrity.INACCESSIBLE")}
                </>);
                break;
            case PackageDataIntegrity.UNKNOWN:
            default:
                result = (<>
                    <QuestionCircleFilled className={"color-gray"}/>
                    {intlMessage("package-detail.package-integrity.UNKNOWN")}
                </>);
                break;
        }

        let runIntegrityCheck = !packageIntegrityCheckRunning ? <OkLeftPopconfirm
            title={intlMessage("packages-detail.confirm-run-integrity-check-on-package", {packageName: aPackage.name ? aPackage.name : aPackage.id})}
            onConfirm={() => checkPackageAndLoginPassword(() => onRunPackageIntegrityCheckConfirm(aPackage))}
            okText={intlMessage("common.yes")}
            cancelText={intlMessage("common.no")}>
            <a className={"button"} title={intlMessage("packages-detail.run-integrity-check-on-package")}>
                <SyncOutlined/>
            </a>
        </OkLeftPopconfirm> : <a className={"button"}><SyncOutlined spin={true}/></a>;


        return (
            <Space>
                {result}
                {integrityCheckAllowed() && runIntegrityCheck}
            </Space>
        )
    }

    function canAddFilesToPackage() {
        // u smazané složky nedovolujeme vytvářet složky a přidávat soubory
        if (folderIndex?.folder === undefined || folderIndex.folder.deleted) {
            return false;
        }

        return aPackage.canAddFilesToPackage;
    }

    function renderPackageAccess() {
        return intlMessage("package.access-type." + aPackage.accessType);
    }

    function renderArchive() {
        // stahovani souboru z odeslanych zasilek
        if (ownPackage && aPackage.accessType !== PackageAccessType.BRIEFCASE) {
            if (applicationConfig?.allowDownloadSentFiles === "NO") {
                return;
            }
            if (applicationConfig?.allowDownloadSentFiles === "PERMISSION_BASED") {
                if (!appContext.user?.hasPermission(UserPermission.ALLOW_DOWNLOAD_SENT_FILES)) {
                    return;
                }
            }
        }

        // omezeni stahovani z backendu
        if (aPackage.downloadFilesAllowed === false) {
            return;
        }

        const archiveDownloadTitle = <>{intlMessage("package-detail.download-archive")} {compactView && <span style={{float: "right"}}>{aPackage.someFileIsSmallerThanMax && renderArchiveDownloadButton()}</span>}</>

        const cleanOrQuarantinedAndAllowed = aPackage.checkState === PackageCheckState.CLEAN || (aPackage.checkState === PackageCheckState.QUARANTINED && applicationConfig!.allowDownloadCleanFilesFromQuaratine);

        if (aPackage.workflowState === PackageWorkflowState.ACTIVE && aPackage.anyCleanFile && cleanOrQuarantinedAndAllowed) {
            return (

                <Card title={archiveDownloadTitle} type="inner" className="card-sparse-rows package-detail archive-download">
                    {!compactView &&
                        <Row>
                            <Col span={24}>

                                <div style={{textAlign: "center"}}>
                                    <p>
                                        {intlMessage("package-detail.archive.title-text", {maxSize: FormatUtils.formatBytes(applicationConfig!.maxFileSizeInArchive)})}
                                    </p>
                                    {(aPackage.anyFileUnchecked || aPackage.anyFileRechecked) &&
                                        <p>
                                            <Alert type={"warning"} showIcon={false} message={intlMessage("package-detail.archive.some-files-rechecked-text")} style={{marginBottom: 12}}/>
                                        </p>
                                    }
                                    <p>
                                        {intlMessage("package-detail.archive.files-text")}
                                    </p>

                                    {
                                        !aPackage.someFileIsSmallerThanMax &&

                                        <p>
                                            {intlMessage("package-detail.archive.title-text-all-files-large", {maxSize: FormatUtils.formatBytes(applicationConfig!.maxFileSizeInArchive)})}
                                        </p>
                                    }

                                    {aPackage.someFileIsSmallerThanMax && renderArchiveDownload()}

                                    {
                                        appContext.user &&

                                        <p>
                                            {intlMessage("package-detail.download-count")}: {renderDownloadCounter(aPackage.archiveDownloadCounter)}
                                        </p>
                                    }
                                </div>
                            </Col>
                        </Row>
                    }
                </Card>

            )
        }
    }

    function renderArchiveDownloadButton() {
        const isPublicPackage = aPackage.accessType === PackageAccessType.PUBLIC

        const fileArchivePath = `/download-archive/${aPackage.id}`;

        let downloadArchiveButton =
            <Button type={"primary"} size={"large"}
                    href={fileArchivePath}
                    onClick={e => {
                        e.preventDefault();

                        checkPackageAndLoginPasswordForDownload(fileArchivePath, packageId, DownloadObjectType.ACHIVE, {});
                    }}>
                {intlMessage("package-detail.archive.download-action")}
            </Button>;

        if (!isPublicPackage && !appContext?.user?.hasPermission(UserPermission.ALLOW_DOWNLOAD_WITHOUT_LOGIN)) {
            downloadArchiveButton = <Button type={"primary"} size={"large"}
                                            onClick={() => promptForLoginPassword(fileArchivePath, aPackage.id!, DownloadObjectType.ACHIVE)}>{intlMessage("package-detail.archive.download-action")}</Button>;
        }

        return downloadArchiveButton;
    }

    function renderArchiveDownload() {

        return (
            <div style={{textAlign: "center"}}>
                <p>
                    {renderArchiveDownloadButton()}
                </p>

                <p>
                    {intlMessage('package-detail.archive.size_description', {size: FormatUtils.formatBytes(aPackage.archiveSize)})}
                </p>
            </div>
        );
    }

    function isFileDownloadAllowed(file: File) {
        let downloadAllowed = false;

        if (aPackage.workflowState === PackageWorkflowState.ACTIVE) {
            if (aPackage.checkState === PackageCheckState.CLEAN) {
                if (file.state === "CLEAN") {
                    downloadAllowed = true;
                }
            } else {
                if (file.state === "CLEAN" && applicationConfig!.allowDownloadCleanFilesFromQuaratine) {
                    downloadAllowed = true;
                }
            }
        }

        // smazane soubory
        if (file.deleted) {
            downloadAllowed = false;
        }

        // omezeni stahovani z backendu
        if (aPackage.downloadFilesAllowed === false) {
            downloadAllowed = false;
        }

        return downloadAllowed;
    }

    function renderFolderName(folder: Folder, includeStatus?: boolean) {
        if (folder.name === '/') {
            return <Link to={`/packages/${packageId}`}>{intlMessage("root-folder")}</Link>;
        }

        if (!includeStatus) {
            return <Link to={`/packages/${packageId}/${folder.id}`}>{folder.name}</Link>;
        }

        let suffix = <></>;

        if (folder.deleted) {
            suffix = <> <i>({intlMessage("package-detail.folder-status-deleted")})</i></>;
        }

        return <Link to={`/packages/${packageId}/${folder.id}`}>{folder.name}{suffix}</Link>;
    }

    /**
     * Zobrazí dialog s výzvou pro zadání hesla zásilky (pro heslem šifrované zásilky).
     */
    function checkPackageAndLoginPassword(callback: () => void) {
        const packagePasswordRequired = aPackage.dataEncryptedWithPassword && (downloadTokenInfo.current === undefined || !isDownloadTokenInfoValid(downloadTokenInfo.current));

        if (!packagePasswordRequired) {
            callback();

            return;
        }

        promptForPackagePassword((tokenInfo, rememberPassword) => {
            downloadTokenInfo.current = tokenInfo;

            setPackagePasswordModal(prevState => ({...prevState, visible: false}));

            if (rememberPassword) {
                localStorageService.setIndexedItem(LocalStorageNamespace.PackagePassword, packageId, JSON.stringify(tokenInfo));
            }

            callback();
        });
    }

    /**
     * Zobrazí dialog s výzvou pro zadání hesla zásilky (pro heslem šifrované zásilky) anebo hesla uživatele (pokud jde o neveřejnou zásilku a uživatel nemá právo stahovat bez ověření heslem).

     * @param e událost kliknutí na odkaz s URL souboru
     * @param objectPath cesta k objektu ke stažení
     * @param objectId ID objektu
     * @param objectType typ objektu
     */
    function checkPackageAndLoginPasswordForDownload(objectPath: string, objectId: string, objectType: DownloadObjectType, payload?: any) {
        if (packageRequiresPassword && (downloadTokenInfo.current === undefined || !isDownloadTokenInfoValid(downloadTokenInfo.current))) {
            setModelState(ModelState.PasswordRequired);

            downloadTokenInfo.current = undefined;

            return;
        }

        const packagePasswordRequired = aPackage.dataEncryptedWithPassword && (downloadTokenInfo.current === undefined || !isDownloadTokenInfoValid(downloadTokenInfo.current));

        const loginPasswordRequired = aPackage?.accessType !== PackageAccessType.PUBLIC && !appContext?.user?.hasPermission(UserPermission.ALLOW_DOWNLOAD_WITHOUT_LOGIN)

        if (!packagePasswordRequired && !loginPasswordRequired) {
            downloadFileViaAnchorOrForm(objectPath, {token: downloadTokenInfo.current?.passwordToken, limitAccessToken: limitAccessToken.current}, payload);

            return;
        }

        if (packagePasswordRequired) {
            promptForPackagePassword((tokenInfo, rememberPassword) => {
                downloadTokenInfo.current = tokenInfo;

                setPackagePasswordModal(prevState => ({...prevState, visible: false}));

                if (rememberPassword) {
                    localStorageService.setIndexedItem(LocalStorageNamespace.PackagePassword, packageId, JSON.stringify(tokenInfo));
                }

                if (loginPasswordRequired) {
                    promptForLoginPassword(objectPath, objectId, objectType, downloadTokenInfo.current.passwordToken);
                } else {
                    downloadFileViaAnchorOrForm(objectPath, {token: downloadTokenInfo.current.passwordToken, limitAccessToken: limitAccessToken.current}, payload);
                }
            });
        } else {
            if (loginPasswordRequired) {
                promptForLoginPassword(objectPath, objectId, objectType, downloadTokenInfo.current?.passwordToken);
            } else {
                downloadFileViaAnchorOrForm(objectPath, {token: downloadTokenInfo.current?.passwordToken, limitAccessToken: limitAccessToken.current}, payload);
            }
        }
    }

    function promptForPackagePassword(onDownloadTokenAcquired: (token: PasswordTokenInfo, rememberPassword: boolean) => void) {
        setPackagePasswordModal(prevState => ({
            ...prevState,
            visible: true,
            onDownloadTokenAcquired
        }));
    }

    function promptForLoginPassword(url: string, objectId: string, objectType: DownloadObjectType, packageDownloadToken?: string) {
        setDownloadFileModal({
            visible: true,
            url,
            objectId,
            objectType,
            packageDownloadToken
        });
    }

    function renderAccessDenied() {
        // presmerujeme na package jen kdyz je uzivatel neprihlaseny
        if (!appContext.user) {
            Cookies.set("Load-package", window.location.pathname, {path: "/", expires: new Date(Date.now() + (60 * 1000))});
        } else {
            Cookies.remove("Load-package", {path: "/"});
        }

        if (window.location.search && window.location.search === "?autologin-adfs") {
            const adfsConfigs = applicationConfig!.adfsConfig;
            const protocol = process.env.NODE_ENV === 'development' ? "http:" : "https:";

            if (adfsConfigs && adfsConfigs[0]) {
                const adalConfig: AdalConfig = {
                    tenant: 'adfs',
                    clientId: adfsConfigs[0].clientId,
                    redirectUri: protocol + "//" + window.location.host + adfsConfigs[0].redirectUri,
                    instance: adfsConfigs[0].instance,
                    cacheLocation: 'localStorage',
                    extraQueryParameter: 'scope=openid&response_mode=form_post',
                }

                const authContext = new AuthenticationContext(adalConfig);

                authContext.login();
            }
        }

        return (
            <Row justify={"space-around"} align={"middle"} className={"login-form"}>
                <Col id={"login-form"} style={{textAlign: "center"}}>
                    {appContext.user ?
                        <>
                            <h2 className={"package-access-problem"}>{intlMessage("access-denied")}</h2>
                            {serverErrorMessage && <h4 className={"package-access-problem"}>{intlMessage(serverErrorMessage)}</h4>}
                            <CloseCircleFilled className={"bigIcon"}/>
                        </>
                        :
                        <>
                            {serverErrorMessage === "package_access_limit_exhausted" ?
                                <>
                                    <h2 className={"package-access-problem"}>{intlMessage("access-denied")}</h2>
                                    {serverErrorMessage && <h4 className={"package-access-problem"}>{intlMessage(serverErrorMessage)}</h4>}
                                    <CloseCircleFilled className={"bigIcon"}/>
                                </> :
                                <>
                                    <h2 className={"package-access-problem"}>{intlMessage("access-denied-login-required")}</h2>
                                    {serverErrorMessage && <h4 className={"package-access-problem"}>{intlMessage(serverErrorMessage)}</h4>}

                                    <Link to={anonymousRoutesMap.Login.path}
                                          id={"loginLink"}>{intlMessage("header.login")}</Link>
                                </>
                            }
                        </>

                    }

                </Col>
            </Row>
        );
    }

    function renderNotFound() {
        return (
            <Row justify={"space-around"} align={"middle"} className={"login-form"}>
                <Col id={"login-form"} style={{textAlign: "center"}}>
                    <h1 className={"package-access-problem"}>{intlMessage("not-found")}</h1>
                    <CloseCircleFilled className={"bigIcon"}/>
                </Col>
            </Row>
        );
    }

    function renderTooManyRequests() {
        return (
            <Row justify={"space-around"} align={"middle"} className={"login-form"}>
                <Col id={"login-form"} style={{textAlign: "center"}}>
                    <h2 className={"package-access-problem"}>{serverErrorMessage}</h2>
                    <CloseCircleFilled className={"bigIcon"}/>
                </Col>
            </Row>
        );
    }

    function renderApproverName(approver: User | Approver | undefined) {
        let fullName;

        let formatedApproverName = "";

        if (!approver) return;

        fullName = approver.firstName || approver.lastName ? approver.lastName + " " + approver.firstName : undefined;

        if (fullName) {
            formatedApproverName = fullName + (approver.email ? " (" + approver.email + ")" : "");
        } else {
            formatedApproverName = approver.email!;
        }

        return (<Space>{formatedApproverName}{approver.deleted ? <Tooltip title={intlMessage("deleted-approver")}><InfoCircleFilled style={{opacity: 0.6}}/></Tooltip> : ""}</Space>);

    }

    function renderSender() {
        let senderName;
        let senderEmail;

        if (!aPackage) {
            return;
        }

        if (aPackage.fromRegisteredUser && aPackage.workflowState !== PackageWorkflowState.REQUESTED) {
            if (aPackage.sender) {
                senderName = aPackage.sender.firstName || aPackage.sender.lastName ? aPackage.sender.lastName + " " + aPackage.sender.firstName : undefined;
                senderEmail = aPackage.sender.email;
            }
        } else if (aPackage.anonymousSenderName || aPackage.anonymousSenderEmail) {
            if (aPackage.anonymousSenderEmail) {
                senderEmail = aPackage.anonymousSenderEmail;

                if (aPackage.anonymousSenderName) {
                    senderName = aPackage.anonymousSenderName;
                }
            } else {
                senderEmail = aPackage.anonymousSenderName;
            }

            if (aPackage.anonymousSenderName) {
                senderName = aPackage.anonymousSenderName;
            }
        } else {
            senderEmail = intlMessage("packages-inbox-outbox.unknown-sender");
        }

        const unverifiedEmail = aPackage.fromRegisteredUser ? "" : <Tooltip title={intlMessage("unverified-email")}><WarningFilled/></Tooltip>;

        if (senderName) {
            return (<Tooltip title={senderName}><Tag>{senderEmail}{unverifiedEmail}</Tag></Tooltip>);
        } else {
            return (<Tag>{senderEmail}{unverifiedEmail}</Tag>);
        }
    }

    function renderRecipients(contributors: boolean) {
        if (!aPackage) {
            return;
        }

        if (!aPackage.internalRecipients && !aPackage.externalRecipients) {
            return;
        }

        return (
            <span className="package-recipients">
                {(aPackage.workflowState === PackageWorkflowState.ACTIVE && [PackageCheckState.CLEAN, PackageCheckState.UNKNOWN].includes(aPackage.checkState!)) && aPackage.sender && appContext.user && appContext.user.email === aPackage.sender.email && aPackage.internalRecipients
                    ? aPackage.internalRecipients.filter(recipient => recipient.contributor === contributors).map((value) => (
                        <span key={value.email}>
                            <Tooltip title={value.name}>
                                <span>
                                <SmartTag value={value.email}
                                          title={intlMessage("package.recipient.delete-confirm", {email: value.email})}
                                          onClose={() => handleDeleteInternalRecipient(value)}
                                          okText={intlMessage("common.yes")}
                                          cancelText={intlMessage("common.no")}
                                          closable={true}
                                          confirm={true} style={{marginRight: "4px"}}/>
                                </span>
                            </Tooltip>
                        </span>
                    ))
                    : aPackage.internalRecipients!.filter(recipient => recipient.contributor === contributors).map((value) => (
                        <span key={value.email}>
                            <SmartTag value={value.email} closable={false} style={{marginRight: "4px"}}/>
                        </span>
                    ))
                }
                {aPackage.externalRecipients && !contributors && aPackage.externalRecipients.map((value) => (
                    <span key={value.email}>
                        <Tooltip title={value.name}>
                            <Tag style={{marginRight: "4px"}}>{value.email}</Tag>
                        </Tooltip>
                    </span>
                ))}
            </span>
        )
    }

    function handleDeleteInternalRecipient(recipientToDelete: InternalRecipient) {

        const recipients = aPackage.internalRecipients;

        const index = recipients!.indexOf(recipientToDelete, 0);

        if (index > -1) {
            recipients!.splice(index, 1)
        }

        setAPackage(prevState => {
            return ({
                ...prevState,
                internalRecipients: recipients
            });
        })

        packageService.deleteInternalRecipient(aPackage, recipientToDelete).then(() => {
            message.success(intlMessage("recipient-was-deleted", {recipient: recipientToDelete.email}))
        });
    }

    function renderDownloadCounter(counter?: DownloadCounter) {
        if (counter === undefined) {
            return;
        }

        return (
            <>
                <Tooltip
                    overlayInnerStyle={{textAlign: "center"}}
                    title={intlMessage("package-detail.download-count-tooltip", {
                        anonymousTotal: counter.anonymousUsersTotal,
                        registeredUsersTotal: counter.registeredUsersTotal,
                        adminsTotal: counter.adminsTotal
                    })}>

                    {counter.anonymousUsersTotal} / {counter.registeredUsersTotal}

                    {counter.adminsTotal > 0 && <span> / {counter.adminsTotal}</span>}

                </Tooltip>
            </>
        );
    }

    function renderSftpLink() {
        var username = encodeURIComponent(`${appContext.user?.username}#${packageId}`);

        var link = `sftp://${username}@${appContext.applicationConfig?.fqdn}${appContext.applicationConfig?.sftpServerPort != 22 ? `:${appContext.applicationConfig?.sftpServerPort}` : ''}`;

        return <>
            <a href={link}>{link}</a>

            <CopyToClipboard text={link} onCopy={() => message.info(intlMessage("copied-to-clipboard"))}>
                <a>
                    <LinkOutlined
                        title={intlMessage("copy-link-title")}
                        style={{fontSize: "16px", marginLeft: "6px"}}/>
                </a>
            </CopyToClipboard>
        </>;
    }

    function onPasswordSubmit(password: string, rememberPassword: boolean) {
        return loadPackage({password, rememberPassword});
    }

    function onAccessConfirm() {
        return loadPackage();
    }

    function loadPackage(options?: { password?: string, rememberPassword?: boolean }): Promise<any> {
        return packageService.get(packageId, options?.password, downloadTokenInfo.current?.passwordToken, limitAccessToken.current).then(
            (aPkg: Package) => {

                if (!aPkg || !aPkg.id) return;

                if (aPkg.downloadTokenInfo) {
                    downloadTokenInfo.current = aPkg.downloadTokenInfo;

                    if (options?.rememberPassword) {
                        localStorageService.setIndexedItem(LocalStorageNamespace.PackagePassword, packageId, JSON.stringify(downloadTokenInfo.current));
                    }
                }

                if (aPkg.note) {
                    const tmpNote = aPkg.note.split("------");

                    aPkg.noteFrom = tmpNote[0];
                    aPkg.noteTo = tmpNote[1];
                }

                setAPackage(aPkg);
                setModelState(ModelState.Loaded);

                const ownPkg = !!appContext.user && !!aPkg.sender && (appContext.user.email === aPkg.sender.email);

                setOwnPackage(ownPkg);

                // jsem interni prijemce?
                aPkg.internalRecipients && aPkg.internalRecipients?.forEach(recipient => {
                    if (recipient.email === appContext.user?.email) {
                        setRecipientPackage(true);
                    }
                });

                if (appContext.user && (!aPkg.userFlags || !aPkg.userFlags!.viewed)) {
                    packageService.setViewed(aPkg);
                }

                appContext.disableLangSelector = !ownPkg && aPkg.limitAccessCount! > 0;

                return aPkg;
            },
            reason => {
                if (reason.response) {
                    switch (reason.response.status) {
                        case 400:
                            setPackageRequiresPassword(true);
                            setModelState(ModelState.PasswordRequired);

                            break;

                        case 402:
                            limitAccessToken.current = reason.response.headers['x-sofie-limit-access-token'];

                            setModelState(ModelState.LimitAccessConfirmRequired);

                            break;

                        case 404:
                            setModelState(ModelState.NotFound);

                            break;

                        case 429:
                            setModelState(ModelState.TooManyRequests);
                            setServerErrorMessage(reason.response.data);

                            break;

                        case 403:
                            if (reason.response.headers['x-sofie-response-reason']) {
                                setModelState(ModelState.AccessDenied);
                                setServerErrorMessage(reason.response.headers['x-sofie-response-reason']);
                            } else {
                                if (modelState === ModelState.PasswordRequired) {
                                    setEnteredInvalidPassword(true);
                                    setServerErrorMessage(reason.response.data);

                                } else {
                                    setModelState(ModelState.AccessDenied);
                                    setServerErrorMessage(reason.response.data);

                                }
                            }

                            break;
                    }
                }
            });
    }

    function onMove(items: (File | Folder)[]) {
        setItemsToMove(items);

        setMoveModalVisible(true);

        return new Promise(resolve => {
            moveDonePromiseRef.current = resolve;
        });
    }

    function onDownloadAsZip(items: (File | Folder)[]) {
        const fileArchivePath = `/download-archive/${aPackage.id}`;

        const folderIds = items.filter(value => value instanceof Folder).map(value => value.id);
        const fileIds = items.filter(value => value instanceof File).map(value => value.id);

        checkPackageAndLoginPasswordForDownload(fileArchivePath, packageId, DownloadObjectType.ACHIVE, {folderIds, fileIds});

        return Promise.resolve();
    }

    function onMassDeleteConfirm(files: (File | Folder)[], deleteSafeCopy: boolean | undefined) {
        return packageFileService.massDelete(packageId, files, deleteSafeCopy)
            .then(() => {
                message.success(intlMessage("package.files-deleted"));

                reloadAllData();
            });

    }

    function reloadAllData() {
        loadPackage();

        loadFolder(folderIndex?.folder.id);

        if (allDeletedFiles !== undefined) {
            loadAllDeletedFiles();
        }

        if (allQuarantinedFiles !== undefined) {
            loadAllQuarantinedFiles();
        }
    }

    function loadAllDeletedFiles() {
        setAllDeletedLoading(true);

        packageService.getDeletedFiles(packageId)
            .then(response => {
                setAllDeletedFiles([
                    ...response.folders,
                    ...response.files
                ]);
            })
            .finally(() => setAllDeletedLoading(false));
    }

    function loadAllQuarantinedFiles() {
        setAllQuarantinedLoading(true);

        packageService.getQuarantinedFiles(packageId)
            .then(setAllQuarantinedFiles)
            .finally(() => setAllQuarantinedLoading(false));
    }

    function loadFolder(folderId?: string) {
        if (folderId === undefined) {
            return;
        }

        setFolderLoading(true);

        folderService.getIndex(folderId, downloadTokenInfo.current?.passwordToken, limitAccessToken.current)
            .then(index => {
                setFolderIndex(index);

                const activeFolders = index.folders.filter(folder => folder.deleted === undefined);
                const deletedFolders = index.folders.filter(folder => folder.deleted !== undefined);

                const deletedFileList: File[] = [];
                const uncleanFileList: File[] = [];
                const fileList: File[] = [];

                const cdrCount: any = {};
                const cdrFiles: any = {};

                index.files.forEach(file => file.cdrFiles = []);

                // Kolik maji soubory bezpecnych kopii (neskartovanych)
                for (const file of index.files) {
                    file.cdrCount = 0;

                    if (file.flags?.includes(FileFlag.CDR)) {
                        if (!cdrCount[file.originalFile!]) {
                            cdrCount[file.originalFile!] = 1;
                            cdrFiles[file.originalFile!] = [];
                        } else {
                            cdrCount[file.originalFile!]++;
                        }
                        cdrFiles[file.originalFile!].push(file);
                    }
                }

                for (const file of index.files) {
                    if (cdrCount[file.id!]) {
                        file.cdrCount = cdrCount[file.id!];
                        file.cdrFiles = cdrFiles[file.id!];
                    }

                    if (file.deleted) {
                        deletedFileList.push(file);
                    } else if (file.state === "UNCLEAN") {
                        uncleanFileList.push(file);
                    } else {
                        fileList.push(file);
                    }
                }

                const activeFiles = fileList.filter(file =>
                    !file.flags?.includes(FileFlag.CDR) ||
                    (file.flags?.includes(FileFlag.CDR) && !fileList.find(packageFile => packageFile.id === file.originalFile))
                );

                const uncleanFiles = uncleanFileList.filter(file =>
                    !file.flags?.includes(FileFlag.CDR) ||
                    (file.flags?.includes(FileFlag.CDR) && !uncleanFileList?.find(packageFile => packageFile.id === file.originalFile))
                );

                const deletedFiles = deletedFileList.filter(file =>
                    !file.flags?.includes(FileFlag.CDR) ||
                    (file.flags?.includes(FileFlag.CDR) && !deletedFileList?.find(packageFile => packageFile.id === file.originalFile))
                );

                setActiveFiles([...activeFolders, ...activeFiles]);
                setUncleanFiles(uncleanFiles);
                setDeletedFiles([...deletedFolders, ...deletedFiles]);

                setAllFiles([
                    ...((packageContentConfig.visibleStates.includes(FileState.ACTIVE)) ? activeFolders : []),
                    ...((packageContentConfig.visibleStates.includes(FileState.ACTIVE)) ? activeFiles : []),
                    ...((packageContentConfig.visibleStates.includes(FileState.UNCLEAN)) ? uncleanFiles : []),
                    ...((packageContentConfig.visibleStates.includes(FileState.DELETED)) ? deletedFolders : []),
                    ...((packageContentConfig.visibleStates.includes(FileState.DELETED)) ? deletedFiles : []),
                ]);
            })
            .finally(() => setFolderLoading(false));
    }

    function createNextWorkflowStepMessage(): string {
        if (!aPackage) {
            return '';
        }

        if (aPackage.workflowState === PackageWorkflowState.ACTIVE) {
            if (aPackage.checkState === PackageCheckState.CLEAN) {
                return intlMessage("workflow.step.active", {datetime: FormatUtils.formatOnDateAtTime(intl, aPackage.cleanExpiration!)});
            } else if (aPackage.checkState === PackageCheckState.QUARANTINED) {
                return intlMessage("workflow.step.active", {datetime: FormatUtils.formatOnDateAtTime(intl, aPackage.quarantinedExpiration!)});
            }
        }

        return '';
    }

}

export default PackageDownload;
