import {DeleteFilled, EditFilled, PlusOutlined, ReloadOutlined} from '@ant-design/icons';
import {Button, message, Switch, Table} from "antd";
import Search from "antd/lib/input/Search";
import Column from "antd/lib/table/Column";
import * as _ from "lodash";
import {useContext, useEffect, useState} from "react";
import {AppContextContext, ContactServiceContext, UserServiceContext} from "../Contexts";
import {Contact, ContactType} from "../domain/Contact";
import {ContactGroup} from "../domain/ContactGroup";
import {PersonalSettings, PersonalSettingsKey} from "../domain/User";
import {useIntlMessage} from "../sal-ui/createIntlMessage";
import {useTableHandler} from "../sal-ui/TableHandler";
import ModalOperation from "./common/ModalOperation";
import ContactModal from "./ContactModal";
import ContactSearchForm from "./ContactSearchForm";
import {DocumentTitle} from "./DocumentTitle";
import OkLeftPopconfirm from "./OkLeftPopconfirm";

function ContactList() {
    const appContext = useContext(AppContextContext);
    const contactService = useContext(ContactServiceContext);
    const userService = useContext(UserServiceContext);
    const applicationConfig = appContext.applicationConfig;
    const intlMessage = useIntlMessage("contact-list");
    const tableHandler = useTableHandler("email asc", {reloadFunction: reload, persistentIdent: "ContactList"});

    const [personalSettings, setPersonalSettings] = useState<PersonalSettings>({collectContacts: false});
    const [quickSearch, setQuickSearch] = useState<string>();
    const [data, setData] = useState<any>();
    const [modal, setModal] = useState<any>({visible: false, title: "", operation: ModalOperation.Update, model: null});

    useEffect(() => {
        userService?.getPersonalSetting().then(settings => {
            setPersonalSettings(settings);
        });

    }, []);

    const debounceDelay = 250;
    const onSearchDebounced = _.debounce(term => setQuickSearch(term), debounceDelay);

    const rowClassName = (record: Contact, index: number): string => {
        if (record.type === ContactType.AUTOMATIC) {
            return "green"
        }
        return "info";
    };

    return (
        <DocumentTitle title={`${applicationConfig!.title}: ${intlMessage('title')}`}>
            <div className={"table"}>
                <h1>{intlMessage('title')}</h1>

                <div className={"actions"}>
                    <Button type="text" icon={<ReloadOutlined/>} onClick={reload}/>
                    <Button type="primary" icon={<PlusOutlined/>} onClick={addItem}>{intlMessage("common.add")}</Button>
                    <span style={{marginLeft: "16px"}}>{intlMessage("collect-contacts")} <Switch checked={personalSettings?.collectContacts} onChange={setCollectContacts}/></span>

                    <Search className={"quick-search"} placeholder={intlMessage("common.quick-search")}
                            allowClear={true} onChange={value => onSearchDebounced(value.target.value)}/>
                </div>

                <ContactSearchForm onSearch={tableHandler.onSearchSubmit} quickSearch={quickSearch}/>

                <Table dataSource={data} size="middle" showSorterTooltip={false} onChange={tableHandler.onTableChange} pagination={tableHandler.pagination} rowKey="id" onRow={onRow} rowClassName={(record: any, index: number) => rowClassName(record, index)}>
                    <Column dataIndex="email" key="email" title={intlMessage("email")} sorter={true} defaultSortOrder={"ascend"} sortDirections={["ascend", "descend", "ascend"]}/>
                    <Column dataIndex="name" key="name" title={intlMessage("name")} sorter={true} sortDirections={["ascend", "descend", "ascend"]}/>
                    <Column dataIndex="contactGroups" title={intlMessage("contact-groups")} sorter={false} render={renderContactGroups}/>
                    <Column title={intlMessage("common.action")} width='15%' render={renderAction}/>
                </Table>

                <div className={"clue"}>
                    <div className={"clue-item info"}/>
                    {intlMessage("manual-contact")}
                    <div className={"clue-item green"}/>
                    {intlMessage("automatic-contact")}</div>

                {modal.visible &&
                    <ContactModal visible={modal.visible} title={modal.title}
                                  editMode={modal.operation === ModalOperation.Update} contact={modal.model}
                                  onOk={onModalSave} onCancel={() => setModal({visible: false})}/>
                }
            </div>
        </DocumentTitle>
    );

    function renderContactGroups(contactGroups: ContactGroup[]) {
        const tmpContactGroups: string[] = [];
        contactGroups.forEach(group => {
            tmpContactGroups.push(group.name!);
        });
        return tmpContactGroups.join(", ");
    }

    function renderAction(text: any, record: any) {
        return (
            <>
                <Button type="text" onClick={(e: any) => onEdit(record, e)} title={intlMessage("common.edit")} className={"ant-btn-icon-only"}>
                    <EditFilled/>
                </Button>
                <OkLeftPopconfirm
                    title={intlMessage("confirm-delete", {email: record.email})}
                    onConfirm={() => onDeleteConfirm(record)}
                    okText={intlMessage("common.yes")}
                    cancelText={intlMessage("common.no")}>
                    <Button title={intlMessage("common.delete")} type="text" className={"ant-btn-icon-only"}>
                        <DeleteFilled/>
                    </Button>
                </OkLeftPopconfirm>

            </>
        );
    }

    function setCollectContacts(active: any) {
        userService.setPersonalSetting(PersonalSettingsKey.COLLECT_CONTACTS, active);

        setPersonalSettings({collectContacts: active});
    }

    function onDeleteConfirm(record: Contact) {
        contactService.delete(record)
            .then(() => {
                message.success(intlMessage('deleted', {contactName: record.email}))

                reload();
            });
    }

    function onRow(record: Contact, index: any) {
        return {
            onDoubleClick: () => {
                onEdit(record);

            },
        };
    }

    function onModalSave() {
        setModal({visible: false});
        reload();
    }

    function onEdit(record: Contact, e?: React.MouseEvent) {
        if (e) {
            e.preventDefault();
        }

        setModal((prevState: any) => {
            return (
                {
                    ...prevState,
                    visible: true,
                    title: intlMessage("contact-edit.title", {contactName: record.email}),
                    operation: ModalOperation.Update,
                    model: record
                }
            );
        });
    }

    function addItem() {
        setModal((prevState: any) => {
            return (
                {
                    ...prevState.modal,
                    visible: true,
                    title: intlMessage("contact-add.title"),
                    operation: ModalOperation.Add
                }
            )
        });
    }

    function reload() {
        return contactService.getList(tableHandler.queryOptions)
            .then(value => {
                tableHandler.updateTotal(value.total);
                setData(value.data);
            });
    }

}

export default ContactList;
