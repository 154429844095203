import {Folder} from "./Folder";
import {File} from "./File";

export class FolderIndex {

    constructor(
        public folder: Folder,
        public breadcrumbs: Folder[],
        public folders: Folder[],
        public files: File[]) {
        //
    }

    public static fromPOJO(data: any) {
        return new FolderIndex(
            Folder.fromPOJO(data.folder),
            data.breadcrumbs.map((value: any) => Folder.fromPOJO(value)),
            data.folders.map((value: any) => Folder.fromPOJO(value)),
            data.files.map((value: any) => File.fromPOJO(value)),
        );
    }

}