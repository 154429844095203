import {DownloadCounter, EncryptionStatus, FileCheck, FileFlag, FileIntegrityResult} from "./Package";
import {User} from "./User";
import {Folder} from "./Folder";
import {FileState} from "./FileState";
import {FileOrFolderAction} from "./FileOrFolderAction";

export class File {

    constructor(
        public id: string,
        public uid: string,
        public name: string,
        public hash: string,
        public mimeType: string,
        public size: number,
        public state: FileState,
        public flags: FileFlag[],
        public fileChecks: FileCheck[],
        public uploaded: string,
        public uploadedBy?: User,
        public deleted?: string,
        public contentDeleted?: string,
        public integrityCheckStarted?: string,
        public integrityCheckDone?: string,
        public integrityCheckResult?: FileIntegrityResult,
        public encryptionStatus?: EncryptionStatus,
        public recheckInProgress?: boolean,
        public originalFile?: string,
        public cdrCount?: number,
        public cdrFiles?: File[],
        public downloadCounter?: DownloadCounter,
        public folder?: Folder,
        public possibleActions?: FileOrFolderAction[]) {
        //
    }

    public static fromPOJO(data: any) {
        return new File(
            data.id,
            data.uid,
            data.name,
            data.hash,
            data.mimeType,
            data.size,
            data.state,
            data.flags,
            data.fileChecks,
            data.uploaded,
            data.uploadedBy,
            data.deleted,
            data.contentDeleted,
            data.integrityCheckStarted,
            data.integrityCheckDone,
            data.integrityCheckResult,
            data.encryptionStatus,
            data.recheckInProgress,
            data.originalFile,
            data.cdrCount,
            data.cdrFiles,
            data.downloadCounter,
            (data.folder) ? Folder.fromPOJO(data.folder) : undefined,
            data.possibleActions
        );
    }

}
