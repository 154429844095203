import {DeleteFilled, EditFilled, PlusOutlined, ReloadOutlined} from '@ant-design/icons';
import {Button, message, Table} from "antd";
import Search from "antd/lib/input/Search";
import Column from "antd/lib/table/Column";
import * as _ from "lodash";
import {useContext, useEffect, useState} from "react";
import {AppContextContext, ContactGroupServiceContext} from "../Contexts";
import {Contact} from "../domain/Contact";
import {ContactGroup} from "../domain/ContactGroup";
import {useIntlMessage} from "../sal-ui/createIntlMessage";
import {useTableHandler} from "../sal-ui/TableHandler";
import ModalOperation from "./common/ModalOperation";
import ContactGroupModal from "./ContactGroupModal";
import ContactGroupSearchForm from "./ContactGroupSearchForm";
import {DocumentTitle} from "./DocumentTitle";
import OkLeftPopconfirm from "./OkLeftPopconfirm";

function ContactGroupList() {
    const appContext = useContext(AppContextContext);
    const contactGroupService = useContext(ContactGroupServiceContext);
    const applicationConfig = appContext.applicationConfig;
    const intlMessage = useIntlMessage("contact-list");
    const tableHandler = useTableHandler("name asc", {reloadFunction: reload, persistentIdent: "ContactGroupList"});

    const [quickSearch, setQuickSearch] = useState<string>();
    const [data, setData] = useState<any>();
    const [modal, setModal] = useState<any>({visible: false, title: "", operation: ModalOperation.Update, model: null});

    useEffect(() => {
    }, [])

    const debounceDelay = 250;
    const onSearchDebounced = _.debounce(term => setQuickSearch(term), debounceDelay);

    return (
        <DocumentTitle title={`${applicationConfig!.title}: ${intlMessage('contact-group-list.title')}`}>
            <div className={"table"}>
                <h1>{intlMessage('contact-group-list.title')}</h1>

                <div className={"actions"}>
                    <Button type="text" icon={<ReloadOutlined/>} onClick={reload}/>
                    <Button type="primary" icon={<PlusOutlined/>} onClick={addItem}>{intlMessage("common.add")}</Button>

                    <Search className={"quick-search"} placeholder={intlMessage("common.quick-search")}
                            allowClear={true} onChange={value => onSearchDebounced(value.target.value)}/>
                </div>

                <ContactGroupSearchForm onSearch={tableHandler.onSearchSubmit} quickSearch={quickSearch}/>

                <Table dataSource={data} size="middle" showSorterTooltip={false} onChange={tableHandler.onTableChange} pagination={tableHandler.pagination} rowKey="id" onRow={onRow} rowClassName={(record: any, index: number) => "info"}>
                    <Column dataIndex="name" key="name" title={intlMessage("contact-group-list.name")} sorter={true} defaultSortOrder={"ascend"} sortDirections={["ascend", "descend", "ascend"]}/>
                    <Column dataIndex="contacts" title={intlMessage("contact-group-list.contacts")} sorter={false} className={"space-break"} render={renderContacts}/>
                    <Column title={intlMessage("common.action")} width='15%' render={renderAction}/>
                </Table>

                {modal.visible &&
                <ContactGroupModal visible={modal.visible} title={modal.title}
                                   editMode={modal.operation === ModalOperation.Update} contactGroup={modal.model}
                                   onOk={onModalSave} onCancel={() => setModal({visible: false})}/>
                }
            </div>
        </DocumentTitle>
    );

    function onModalSave() {
        setModal({visible: false});
        reload();
    }


    function renderContacts(contacts: Contact[]) {
        const tmpContacts: string[] = [];
        contacts.forEach(contact => {
            tmpContacts.push(contact.email);
        });

        return tmpContacts.join(", ");
    }

    function renderAction(text: any, record: any) {
        return (
            <>
                <Button type="text" onClick={(e: any) => onEdit(record, e)} title={intlMessage("common.edit")} className={"ant-btn-icon-only"}>
                    <EditFilled/>
                </Button>
                <OkLeftPopconfirm
                    title={intlMessage("contact-group-list.confirm-delete", {name: record.name})}
                    onConfirm={() => onDeleteConfirm(record)}
                    okText={intlMessage("common.yes")}
                    cancelText={intlMessage("common.no")}>
                    <Button title={intlMessage("common.delete")} type="text" className={"ant-btn-icon-only"}>
                        <DeleteFilled/>
                    </Button>
                </OkLeftPopconfirm>

            </>
        );
    }

    function onDeleteConfirm(record: ContactGroup) {
        contactGroupService.delete(record)
            .then(() => {
                message.success(intlMessage('contact-group-list.deleted', {contactGroupName: record.name}))

                reload();
            });
    }

    function onRow(record: ContactGroup, index: any) {
        return {
            onDoubleClick: () => {
                onEdit(record);
            },
        };
    }

    function onEdit(record: ContactGroup, e?: React.MouseEvent) {
        if (e) {
            e.preventDefault();
        }

        setModal((prevState: any) => {
                return (
                    {
                        ...prevState.modal,
                        visible: true,
                        title: intlMessage("contact-group-edit.title", {contactGroupName: record.name}),
                        operation: ModalOperation.Update,
                        model: record
                    }
                )
            }
        );
    }

    function addItem() {
        setModal((prevState: any) => {
            return (
                {
                    ...prevState.modal,
                    visible: true,
                    title: intlMessage("contact-group-add.title"),
                    operation: ModalOperation.Add,
                }
            )
        });
    }

    function reload() {
        return contactGroupService.getList(tableHandler.queryOptions)
            .then(value => {
                tableHandler.updateTotal(value.total);
                setData(value.data);
            });
    }
}

export default ContactGroupList;
